import { Table, Spin } from 'antd';
import { columns } from './columns';
import { services } from '../../api/services';
import { useFetch } from '../../api/provider';
import { useHistory } from 'react-router-dom';
import { CreateButton, Heading } from '../../constants/styles';
import { getImageSrcFromHtml, TableInput } from '../../utils';
import TablePageHeader from '../../components/tablePageHeader';
import {
  getBlogImageStorage,
  deleteBlogImageStorage,
} from '../../helpers/BlogStorageManagement';
import { colors } from '../../constants';

const Blogs = () => {
  const history = useHistory();

  const [response, loading, fetchAllBlogsCall] = useFetch({
    service: services.getAllBlogs,
    callOnMount: true,
    method: 'GET',
  });

  const [, deleteLoading, deleteBlogCall] = useFetch({
    service: services.deleteBlog,
  });

  const onDeleteHandle = async (html: string, blogId: string) => {
    const srcList = getImageSrcFromHtml(html);
    const linksList: string[] = getBlogImageStorage();
    await deleteBlogCall({
      blogId: blogId,
      mediaLinks: [...srcList, ...linksList],
    });
    deleteBlogImageStorage();
    fetchAllBlogsCall();
  };

  const onEditHandle = (id: number, html: string) => {
    const srcList = getImageSrcFromHtml(html);
    history.push({ pathname: `write-blog/${id}`, state: srcList });
  };

  return (
    <Spin spinning={loading || deleteLoading}>
      <Heading
        margin='20px'
        fontSize='28px'
        fontWeight='800'
        lineHeight='110%'
        fontFamily='Nexa XBold'
        color={colors.textColor}
      >
        {'Blogs'}
      </Heading>
      <CreateButton
        onClick={() => {
          history.push('/write-blog');
        }}
      >
        Write new blog
      </CreateButton>
      <TablePageHeader>
        <Table
          dataSource={response?.data?.blogs}
          rowKey='id'
          columns={columns({
            onDeleteHandle,
            onEditHandle,
          })}
          style={{ flex: 1, paddingRight: 10 }}
          scroll={{ x: 1000 }}
          pagination={false}
          onRow={(record: TableInput) => {
            return {
              onClick: () => {},
            };
          }}
        />
      </TablePageHeader>
    </Spin>
  );
};

export default Blogs;
