import TablePageHeader from '../../components/tablePageHeader';
import CustomPagination from '../../components/customPagination';
import { MESSAGE, colors } from '../../constants';
import { columns } from './columns';
import { services } from '../../api/services';
import { useFetch } from '../../api/provider';
import { Heading, RootWrapper } from '../../constants/styles';
import { getFeedbackImageUrl } from '../../utils';
import { message, Spin, Table } from 'antd';
import { useEffect, useState } from 'react';

const Feedback = () => {
  const [feedbackList, loading, getPaginatedFeedbackCall] = useFetch({
    service: services.getAllFeedbacks,
    method: 'GET',
  });

  const [, deleteFeedbackLoading, deleteFeedbackCall] = useFetch({
    service: services.deleteFeedback,
  });

  const [data, setData] = useState<any>([]);
  const [page, setPage] = useState<{ pageSize: number; pageNo: number }>({
    pageNo: 1,
    pageSize: 6,
  });
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (feedbackList?.data?.meta?.count) setTotal(feedbackList.data.meta.count);

    if (feedbackList?.data?.feedback?.length > 0) {
      setData(feedbackList?.data?.feedback);
    } else {
      setData([]);
    }
  }, [feedbackList]);

  useEffect(() => {
    getPaginatedFeedbackCall({
      pageNo: page.pageNo,
      pageSize: page.pageSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const onDeleteHandle = async (id: number) => {
    const isDeleted = await deleteFeedbackCall({ id });
    if (!isDeleted.data) {
      message.error(MESSAGE.REQUEST_FAILED);
      return;
    }
    setPage({ pageNo: 1, pageSize: 6 });
    message.success(MESSAGE.DELETE_SUCCESS);
  };

  return (
    <Spin spinning={loading || deleteFeedbackLoading}>
      <RootWrapper>
        <Heading
          margin='20px'
          fontSize='28px'
          fontWeight='800'
          lineHeight='110%'
          fontFamily='Nexa XBold'
          color={colors.textColor}
        >
          {'Feedback'}
        </Heading>
        <TablePageHeader>
          <Table
            rowKey={({ id }) => id}
            dataSource={data || []}
            columns={columns({ onDeleteHandle })}
            scroll={{ x: 1000 }}
            style={{ paddingRight: 10, flex: 1 }}
            pagination={false}
            expandable={{
              rowExpandable: (record) => record.feedbackMedia?.length !== 0,
              expandedRowRender: (record) => {
                const screenshots =
                  record?.feedbackMedia &&
                  record?.feedbackMedia.map((feedbackMedia) =>
                    getFeedbackImageUrl(
                      feedbackMedia.media.file,
                      record?.user?.id || ''
                    )
                  );
                return screenshots?.map((screenshot) => (
                  <img
                    src={screenshot}
                    alt='screenshot'
                    style={{
                      width: '200px',
                      height: '200px',
                      objectFit: 'cover',
                    }}
                  />
                ));
              },
            }}
          />
          <CustomPagination page={page} setPage={setPage} total={total} />
        </TablePageHeader>
      </RootWrapper>
    </Spin>
  );
};

export default Feedback;
