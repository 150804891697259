import Graphs from '../pages/graphs';
import Messages from '../pages/messages';
import Users from '../pages/users';
import Feedback from '../pages/feedback';
import Settings from '../pages/settings/';
import Login from '../pages/login';
import Authentication from '../pages/authentication';
import Questions from '../pages/questions';
import CreateBlog from '../pages/writeBlog';
import Blogs from '../pages/blogs';
import Jobs from '../pages/jobs';
import JobPost from '../pages/jobPost';
import FAQs from '../pages/faqs';
import FaqPost from '../pages/faqPost';
import Donations from '../pages/donations';
import Testimonials from '../pages/testimonials';
import ReportedUsers from '../pages/reportedUser';
import ReportedPosts from '../pages/reportedPost';

const PublicRoutes = [
  {
    path: '/',
    key: 'login',
    exact: true,
    component: Login,
    restricted: true,
  },
  {
    path: '/authentication',
    key: 'authentication',
    exact: true,
    component: Authentication,
    restricted: true,
  },
];

const PrivateRoutes = [
  {
    path: '/dashboard',
    key: 'dashboard',
    exact: true,
    component: Graphs,
  },
  {
    path: '/messages',
    key: 'messages',
    exact: true,
    component: Messages,
  },
  {
    path: '/users',
    key: 'users',
    exact: true,
    component: Users,
  },
  {
    path: '/questions',
    key: 'questions',
    exact: true,
    component: Questions,
  },
  {
    path: '/write-blog/:id?',
    key: 'write-blog',
    exact: true,
    component: CreateBlog,
  },
  {
    path: '/blogs',
    key: 'blogs',
    exact: true,
    component: Blogs,
  },
  {
    path: '/faqs',
    key: 'faqs',
    exact: true,
    component: FAQs,
  },
  {
    path: '/donations',
    key: 'donations',
    exact: true,
    component: Donations,
  },
  {
    path: '/testimonials',
    key: 'testimonials',
    exact: true,
    component: Testimonials,
  },
  {
    path: '/create-faq',
    key: 'create-faq',
    exact: true,
    component: FaqPost,
  },
  {
    path: '/edit-faq/:id',
    key: 'edit-faq',
    exact: true,
    component: FaqPost,
  },
  {
    path: '/jobs',
    key: 'jobs',
    exact: true,
    component: Jobs,
  },
  {
    path: '/create-job',
    key: 'create-job',
    exact: true,
    component: JobPost,
  },
  {
    path: '/edit-job/:id?',
    key: 'edit-job',
    exact: true,
    component: JobPost,
  },
  {
    path: '/feedback',
    key: 'feedback',
    exact: true,
    component: Feedback,
  },
  {
    path: '/reported-user',
    key: 'reported-user',
    exact: true,
    component: ReportedUsers,
  },
  {
    path: '/reported-post',
    key: 'reported-post',
    exact: true,
    component: ReportedPosts,
  },
  {
    path: '/settings',
    key: 'settings',
    exact: true,
    component: Settings,
  },
];

export { PublicRoutes, PrivateRoutes };
