import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const DeleteSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18'
    height='19'
    viewBox='0 0 18 19'
    fill='none'
  >
    <path
      d='M7.5 11.345L7.5 9.09497'
      stroke='#B9C1C7'
      strokeWidth='2'
      stroke-linecap='round'
    />
    <path
      d='M10.5 11.345L10.5 9.09497'
      stroke='#B9C1C7'
      strokeWidth='2'
      stroke-linecap='round'
    />
    <path
      d='M2.25 5.34497H15.75V5.34497C15.5178 5.34497 15.4017 5.34497 15.304 5.3546C14.3544 5.44812 13.6032 6.19938 13.5096 7.14894C13.5 7.24672 13.5 7.3628 13.5 7.59497V11.095C13.5 12.9806 13.5 13.9234 12.9142 14.5092C12.3284 15.095 11.3856 15.095 9.5 15.095H8.5C6.61438 15.095 5.67157 15.095 5.08579 14.5092C4.5 13.9234 4.5 12.9806 4.5 11.095V7.59497C4.5 7.3628 4.5 7.24672 4.49037 7.14894C4.39685 6.19938 3.64559 5.44812 2.69603 5.3546C2.59825 5.34497 2.48217 5.34497 2.25 5.34497V5.34497Z'
      stroke='#B9C1C7'
      strokeWidth='2'
      stroke-linecap='round'
    />
    <path
      d='M7.55111 2.62291C7.63657 2.54318 7.82489 2.47272 8.08686 2.42246C8.34882 2.37221 8.6698 2.34497 9 2.34497C9.3302 2.34497 9.65118 2.37221 9.91314 2.42246C10.1751 2.47272 10.3634 2.54318 10.4489 2.62291'
      stroke='#B9C1C7'
      strokeWidth='2'
      stroke-linecap='round'
    />
  </svg>
);

export const DeleteIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={DeleteSvg} {...props} />
);
