import styled from 'styled-components';
import { colors } from '../../constants';
import { FlexContainer } from '../../constants/styles';
import { TableInput, DataRowInput } from '../../utils';

type ActionProps = {
  color: string;
};

const TableRowItem1 = styled.div<DataRowInput>`
  display: flex;
  flex-direction: column;
  margin-left: ${(props: DataRowInput) => (props.active ? '10px' : '0px')};
`;

const Title = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.black};
  padding: 0px;
  margin: 0px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
`;

const ActionButton = styled.p<ActionProps>`
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.color};
  padding: 0px;
  margin: 0px;
`;

type BlogColumnsProps = {
  onEditHandle: (id: number) => void;
  onDeleteHandle: (id: number) => void;
};

const columns = ({ onDeleteHandle, onEditHandle }: BlogColumnsProps) => {
  return [
    {
      title: 'Question',
      dataIndex: 'question',
      key: 'question',
      render: (text: string, record: TableInput) => {
        return (
          <FlexContainer>
            <Title>{record.question || ' '}</Title>
          </FlexContainer>
        );
      },
    },
    {
      title: 'Answer',
      dataIndex: 'answer',
      key: 'answer',
      render: (text: string, record: TableInput) => {
        return (
          <FlexContainer>
            <Title>{record.answer}</Title>
          </FlexContainer>
        );
      },
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      render: (text: string, record: TableInput) => {
        return (
          <TableRowItem1 active={0}>
            <Title>{record.priority}</Title>
          </TableRowItem1>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      render: (text: string, record: TableInput) => {
        return (
          <ButtonWrapper>
            <ActionButton
              style={{ marginRight: '10px' }}
              color={colors.appColor}
              onClick={() => onEditHandle(record.id)}
            >
              Edit
            </ActionButton>
            <ActionButton
              color={colors.red}
              onClick={() => onDeleteHandle(record.id)}
            >
              Delete
            </ActionButton>
          </ButtonWrapper>
        );
      },
    },
  ];
};

export { columns };
