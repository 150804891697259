import { Category } from '../../../utils';
import { colors } from '../../../constants';
import { useEffect, useState } from 'react';
import { ConfigProvider, Modal, Select } from 'antd';
import { Label, ModalContentWrapper } from '../../../constants/styles';

export interface ModalWrapperProps {
  open: boolean;
  filters: string[];
  onClose: () => void;
  categories: Category[];
  child?: React.ReactElement;
  setFilters: React.Dispatch<React.SetStateAction<string[]>>;
}

const FilterModal = ({
  open,
  onClose,
  filters,
  categories,
  setFilters,
}: ModalWrapperProps) => {
  const [tempFilter, setTempFilter] = useState<string[]>(filters);
  const handleChange = (value: string[]) => setTempFilter([...value]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setTempFilter(filters), [filters.length]);
  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            titleFontSize: 28,
            titleLineHeight: 1.108,
            fontFamily: 'Nexa XBold',
            fontWeightStrong: 800,
            titleColor: '#342A53',
          },
        },
      }}
    >
      <Modal
        centered
        open={open}
        onOk={() => {
          setFilters(tempFilter);
          onClose();
        }}
        onCancel={() => {
          if (filters.length === 0) setTempFilter([]);
          onClose();
        }}
        title='Filter'
        className='newStyle'
        okText={'Apply Filters'}
        style={{
          fontSize: '',
        }}
        okButtonProps={{
          disabled: false,
          style: {
            backgroundColor: colors.purple,
          },
        }}
        cancelButtonProps={{
          className: 'cancelBtn',
        }}
      >
        <ModalContentWrapper flexDirection='column' gap='10px'>
          <Label
            textcolor='#342A53'
            size='20px'
            weight={800}
            margin='0'
            lineHeight='100%'
          >
            Category
          </Label>
          <Select
            allowClear
            mode='multiple'
            value={tempFilter}
            options={categories?.map((category) => {
              return {
                label: category?.name,
                value: category.name,
              };
            })}
            onChange={handleChange}
            style={{ width: '100%' }}
            className='categorySelect'
            placeholder='Select Filters'
          />
        </ModalContentWrapper>
      </Modal>
    </ConfigProvider>
  );
};

export default FilterModal;
