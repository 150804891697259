import styled from 'styled-components';
import { Label } from '../../../constants/styles';

const Wrapper = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: 680px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
`;

const Tab = styled.div`
  width: 92px;
  padding: 2px 5px;
  border: 1px solid #b9c1c7;
`;

const ImportInfoBar = () => {
  return (
    <Wrapper>
      <Label fontfamily='Nexa Book' lineHeight='130%' size='14px'>
        Your XLSX's header row needs to be:
      </Label>
      <Tabs>
        <Tab>
          <Label fontfamily='Roboto' lineHeight='140%' size='16px'>
            Question
          </Label>
        </Tab>
        <Tab style={{ borderLeft: 0 }}>
          <Label fontfamily='Roboto' lineHeight='140%' size='16px'>
            Category
          </Label>
        </Tab>
      </Tabs>
    </Wrapper>
  );
};

export default ImportInfoBar;
