import { MESSAGE } from '../constants';
import { message } from 'antd';
import { services } from './services';
import { getToken, saveToken } from '../helpers/SessionManagement';
import { useState, useEffect, useRef } from 'react';

type UseFetchProps = {
  service: string;
  values?: any;
  callOnMount?: boolean;
  method?: 'POST' | 'GET';
};
export const useFetch = ({
  service,
  values,
  callOnMount = false,
  method = 'POST',
}: UseFetchProps) => {
  const isCurrent = useRef(true);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<any>();
  let tokenVal = {
    accessToken: {
      token: '',
    },
    refreshToken: {
      token: '',
    },
  };

  if (getToken()) {
    tokenVal = JSON.parse(getToken());
  }

  const headers = {
    'Content-Type': 'application/json',
    authorization: `Bearer ${tokenVal?.accessToken?.token}`,
  };
  useEffect(() => {
    return () => {
      isCurrent.current = false;
    };
  }, []);

  useEffect(() => {
    if (callOnMount) fetchCall(values || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service, values]);

  const refreshCall = () => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}${services.updateToken}`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        refreshToken: tokenVal?.refreshToken?.token,
      }),
    })
      .then(async (res) => {
        message.info(MESSAGE.TRY_AGAIN);
        const resp = await res.json();
        if (resp.data) saveToken(JSON.stringify(resp.data));
      })
      .catch((e) => {
        throw new Error(e);
      });
  };

  const fetchCall = async (
    dataValues: any,
    _headers?: any,
    isFile?: boolean
  ) => {
    let params = '';
    if (method === 'GET') params = new URLSearchParams(dataValues).toString();
    setLoading(true);

    return fetch(`${process.env.REACT_APP_API_ENDPOINT}${service}?${params}`, {
      method: method,
      headers: _headers ? _headers : headers,
      body:
        method === 'POST'
          ? isFile
            ? dataValues
            : JSON.stringify(dataValues)
          : null,
    })
      .then(async (res) => {
        const resp = await res.json();
        setResponse(resp);

        if (resp?.message === MESSAGE.USER_NOT_AUTHORIZED) {
          refreshCall();
        }
        return resp;
      })
      .catch((err) => {
        isCurrent.current && setResponse(err);
        return err;
      })
      .finally(() => setLoading(false));
  };

  const refetch = async (dataValues: any, headers?: any, isFile?: boolean) =>
    await fetchCall(dataValues, headers, isFile);

  return [response, loading, refetch];
};
