import styled from 'styled-components';
import { colors } from '../../constants';

const Label = styled.h1`
  color: ${colors.appColor05};
  font-size: 20px;
  margin-bottom: 0;
`;
const Value = styled.h1`
  color: ${colors.appColor};
  font-size: 24px;
  margin-left: 10px;
  margin-bottom: 0;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const Container = styled.div`
  margin: auto 20px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  border-radius: 4px;
  flex-direction: row;
  justify-content: space-around;
  background-color: ${colors.backgroundColor};

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

type DonationInfoProps = {
  fail: string;
  success: string;
  pending: string;
};

const DonationInfo = ({ success, fail, pending }: DonationInfoProps) => {
  if (fail === undefined || success === undefined || pending === undefined)
    return null;

  return (
    <Container>
      <Row>
        <Label>Total Donation:</Label>
        <Value>{`$${Number(success) + Number(pending) + Number(fail)}`}</Value>
      </Row>
      <Row>
        <Label>Successful Payments:</Label>
        <Value>{`$${success}`}</Value>
      </Row>
      <Row>
        <Label>Pending Payments:</Label>
        <Value>{`$${pending}`}</Value>
      </Row>
      <Row>
        <Label>Failed Payments:</Label>
        <Value>{`$${fail}`}</Value>
      </Row>
    </Container>
  );
};

export default DonationInfo;
