import { Row, Tag } from 'antd';
import { CrossIcon } from '../../assets';
import { Label } from '../../constants/styles';

interface FiltersProps {
  filters: string[];
  onClose: () => void;
  setFilters: React.Dispatch<React.SetStateAction<string[]>>;
}

const FiltersRow = ({ onClose, filters, setFilters }: FiltersProps) => {
  const handleRemove = (category: string) => {
    setFilters((pre) => pre.filter((item) => item !== category));
  };
  return (
    <Row
      style={{
        gap: 17,
        margin: '0 0 17px',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <Label
        margin='0'
        size='14px'
        weight={400}
        lineHeight='140%'
        textcolor='#342A53'
        fontfamily='Poppins'
      >
        Filters:
      </Label>
      <div>
        {filters.map((filter) => (
          <Tag
            key={filter}
            bordered={false}
            onClose={onClose}
            closeIcon={
              <span onClick={() => handleRemove(filter)}>
                <CrossIcon />
              </span>
            }
          >
            {filter}
          </Tag>
        ))}
      </div>
    </Row>
  );
};

export default FiltersRow;
