const BLOG_IMAGES = 'BLOG_IMAGES';

export const getBlogImageStorage = () => {
  const result = localStorage.getItem(BLOG_IMAGES);
  if (result) return JSON.parse(result);
  return [];
};

export const setBlogImageStorage = (images: string[]) => {
  let newList: string[] = images;

  const oldImages: string[] = getBlogImageStorage();
  if (oldImages && oldImages.length > 0) {
    newList = [...oldImages, ...newList];

    const filteredList = newList.filter((v, i, a) => a.indexOf(v) === i);
    localStorage.setItem(BLOG_IMAGES, JSON.stringify(filteredList));
    return true;
  }
  localStorage.setItem(BLOG_IMAGES, JSON.stringify(images));
  return true;
};

export const deleteBlogImageStorage = () => {
  localStorage.removeItem(BLOG_IMAGES);
  return true;
};
