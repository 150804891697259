import { useState } from 'react';
import { Input, Row, Spin, message } from 'antd';
import { MESSAGE, colors } from '../../constants';
import { useFetch } from '../../api/provider';
import { services } from '../../api/services';
import { CategoryProps } from '../../utils';
import { Label, TableHeaderButton } from '../../constants/styles';

const { TextArea } = Input;

const AddCategory = ({
  setCategoryClick,
  setRefetchCategories,
}: CategoryProps) => {
  const [name, setName] = useState('');

  const [, addCategoryLoading, addCategoryCall] = useFetch({
    service: services.addCategory,
  });

  const onAddCategory = async () => {
    try {
      await addCategoryCall({ name });
      setName('');
      setRefetchCategories && setRefetchCategories(true);
      setCategoryClick(false);
    } catch (error) {
      message.error(error?.toString() || MESSAGE.REQUEST_FAILED);
    }
  };

  return (
    <Row
      style={{
        gap: 0,
        margin: '0',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      }}
    >
      <Spin spinning={addCategoryLoading} />
      <Label
        size='13px'
        weight={400}
        margin='0 0 5px'
        lineHeight='140%'
        textcolor='#79818E'
        fontfamily='Poppins'
      >
        Add Category
      </Label>
      <Row style={{ columnGap: 10 }}>
        <TextArea
          showCount
          maxLength={140}
          value={name}
          className='catInput'
          placeholder='Keep it short and clear'
          onChange={(e) => setName(e.target.value)}
          style={{
            height: 40,
            maxWidth: 234,
            resize: 'none',
            margin: '0 9px 0 0',
            textOverflow: 'ellipsis !important',
          }}
        />
        <TableHeaderButton
          disabled={!name?.length}
          padding='12px 18px'
          bgColor={colors.purple}
          onClick={onAddCategory}
          textColor={colors.white}
        >
          Save
        </TableHeaderButton>
        <TableHeaderButton
          disabled={false}
          padding='12px 18px'
          onClick={() => setCategoryClick(false)}
        >
          Cancel
        </TableHeaderButton>
      </Row>
    </Row>
  );
};

export default AddCategory;
