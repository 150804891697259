import { Popconfirm } from 'antd';
import styled from 'styled-components';
import { colors } from '../../constants';
import { FlexContainer } from '../../constants/styles';
import { TableInput, DataRowInput, getImageUrl } from '../../utils';

interface WrapperProps {
  active?: number;
  isRow?: boolean;
  marginRight?: string;
}
interface GetReportedByProps {
  userId: string;
  preview: string;
  fullName: string;
  email: string;
  comment: string;
  reportType?: string;
}
interface ActionProps {
  color: string;
}
interface ColumnsProps {
  onBlockHandle: (id: number) => void;
  onIgnoreHandle: (id: number) => void;
}

const TableRowItem1 = styled.div<DataRowInput>`
  display: flex;
  flex-direction: column;
  margin-left: ${(props: DataRowInput) => (props.active ? '10px' : '0px')};
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
`;

const ActionButton = styled.p<ActionProps>`
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.color};
  padding: 0px;
  margin: 0px;
`;
const Wrapper = styled.div`
  padding: 20px;
  margin-right: 50px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

const Description = styled.p`
  font-weight: 600;
  color: ${colors.lightGrey};
  padding: 0px;
`;

const Image = styled.img`
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 25px;
`;

const Title = styled.p`
  color: ${colors.black};
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  font-weight: 500;
`;

const WrapperBox = styled.div<WrapperProps>`
  display: flex;
  flex-direction: ${(props: WrapperProps) => (props.isRow ? 'row' : 'column')};
  max-width: 450px;
  margin-right: ${(props: WrapperProps) =>
    props.marginRight ? props.marginRight : '0px'};
  margin-left: ${(props: WrapperProps) => (props.active ? '10px' : '0px')};
`;

const GetReportedBy = ({
  email,
  fullName,
  preview,
  userId,
  comment,
  reportType,
}: GetReportedByProps) => (
  <Wrapper>
    <WrapperBox>
      <WrapperBox isRow={true}>
        <Image src={getImageUrl(userId, preview)} />
        <WrapperBox active={1}>
          <Title>{fullName}</Title>
          <Description>{email}</Description>
        </WrapperBox>
      </WrapperBox>
      {reportType && (
        <WrapperBox isRow>
          <Title>Category: </Title>
          <Title>{reportType}</Title>
        </WrapperBox>
      )}
      {comment && (
        <WrapperBox isRow>
          <Title>Reason: </Title>
          <Title>{comment}</Title>
        </WrapperBox>
      )}
    </WrapperBox>
  </Wrapper>
);

const Columns = ({ onBlockHandle, onIgnoreHandle }: ColumnsProps) => {
  return [
    {
      title: 'Reported User',
      dataIndex: 'reported',
      key: 'reported',
      render: (_text: string, record: TableInput[]) => {
        const fullName =
          record[0].toUser?.firstName + ' ' + record[0].toUser?.lastName || ' ';
        const userId = record[0]?.toUser?.id || '';

        return (
          <FlexContainer>
            <Image
              src={getImageUrl(
                userId,
                record[0]?.toUser?.avatarMedia?.preview || ''
              )}
            />
            <TableRowItem1 active={1}>
              <Title>{fullName}</Title>
              <Description>{record[0]?.toUser?.email}</Description>
            </TableRowItem1>
          </FlexContainer>
        );
      },
    },

    {
      title: 'Report Count',
      dataIndex: 'reportCount',
      key: 'reportCount',
      sorter: (a: any, b: any) => {
        return a[0].toUser?.reportCount - b[0].toUser?.reportCount;
      },
      render: (_text: string, record: TableInput[]) => {
        return (
          <TableRowItem1 active={0}>
            <Title>{record[0]?.toUser?.reportCount}</Title>
          </TableRowItem1>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      render: (_text: string, record: TableInput[]) => {
        return (
          <ButtonWrapper>
            <ActionButton
              color={colors.appColor}
              style={{ marginRight: '10px' }}
            >
              <Popconfirm
                style={{ padding: '20px' }}
                placement='left'
                title='Block user from making public posts for 1 week?'
                onConfirm={() => onBlockHandle(record[0].id)}
              >
                Soft Block User
              </Popconfirm>
            </ActionButton>
            <ActionButton color={colors.red}>
              <Popconfirm
                style={{ padding: '20px' }}
                placement='left'
                title='Resolve and remove this complaint?'
                onConfirm={() => onIgnoreHandle(record[0].id)}
              >
                Resolve
              </Popconfirm>
            </ActionButton>
          </ButtonWrapper>
        );
      },
    },
  ];
};

export { Columns, GetReportedBy };
