import styled from 'styled-components';
import { colors } from '../../constants';

const StyledButton = styled.div<ContentContainerInput>`
  gap: 10px;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  align-items: center;
  padding: 6px 20px 6px 10px;
  background-color: ${(props: ContentContainerInput) =>
    props.active ? 'rgba(255, 255, 255, 0.15)' : 'transparent'};
`;

const Text = styled.p<ContentContainerInput>`
  height: 11px;
  font-size: 16px;
  font-weight: 400;
  line-height: 100%;
  margin: 0 !important;
  color: ${colors.white};
  text-overflow: ellipsis;
  font-family: 'Nexa Regular';
`;
interface SiderItemInput {
  icon: string;
  index: number;
  title: string;
  active: boolean;
  setActiveIndex: Function;
}
interface ContentContainerInput {
  active?: boolean;
}
const SiderItem = (props: SiderItemInput) => {
  const { active, setActiveIndex, index, icon, title } = props;
  return (
    <StyledButton active={active} onClick={() => setActiveIndex(index)}>
      <img src={icon} alt='icon' />
      <Text>{title}</Text>
    </StyledButton>
  );
};
export default SiderItem;
