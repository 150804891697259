import { FAQType } from '../../utils';
import { services } from '../../api/services';
import { useFetch } from '../../api/provider';
import { defaultRules } from '../../utils/fieldsRules';
import { EDIT_FAQ, MESSAGE, colors } from '../../constants';
import { useEffect, useRef } from 'react';
import { CardContainer, Heading } from '../../constants/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Form, FormInstance, Input, message, Spin } from 'antd';

const itemStyle = { marginBottom: 14 };

const FaqPost = () => {
  const formRef = useRef<FormInstance>(null);
  const location = useLocation();
  const history = useHistory();

  const [, createLoading, createFaqCall] = useFetch({
    service: services.createFaq,
  });

  const [, updateLoading, updateFaqCall] = useFetch({
    service: services.updateFaq,
  });

  const [singleFaq, faqLoading, getFaqByIdCall] = useFetch({
    service: services.getFaqById,
    method: 'GET',
  });

  useEffect(() => {
    if (location.pathname.split('/')[1] === EDIT_FAQ) {
      getFaqByIdCall({ id: location.pathname.split('/')[2] });
    } else {
      formRef?.current?.setFieldsValue({ priority: '0' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (singleFaq?.data) {
      const faq: FAQType = singleFaq.data;

      formRef?.current?.setFieldsValue({
        answer: faq.answer,
        question: faq.question,
        priority: faq.priority.toString(),
      });
    }
  }, [singleFaq]);

  const handleSubmit = async (values: any) => {
    const { priority, ...remaining } = values;

    const finalObj = {
      priority: parseInt(priority),
      ...remaining,
    };

    let resp;
    if (location.pathname.split('/')[1] === EDIT_FAQ)
      resp = await updateFaqCall({ id: singleFaq?.data?.id, ...finalObj });
    else resp = await createFaqCall(finalObj);

    if (resp.status === MESSAGE.SUCCESS_RESPONSE) {
      if (location.pathname.split('/')[1] === EDIT_FAQ)
        message.success(MESSAGE.FAQ_UPDATED);
      else message.success(MESSAGE.FAQ_CREATED);
      history.push('/faqs');
    } else message.error(resp?.message || MESSAGE.REQUEST_FAILED);
  };

  return (
    <div>
      <Spin spinning={faqLoading || createLoading || updateLoading}>
        <CardContainer>
          <Heading
            margin='20px'
            fontSize='28px'
            fontWeight='800'
            lineHeight='110%'
            fontFamily='Nexa XBold'
            color={colors.textColor}
          >
            {location.pathname.split('/')[1] === EDIT_FAQ
              ? 'Update FAQ'
              : 'Create FAQ'}
          </Heading>
          <Form ref={formRef} name='faq-form' onFinish={handleSubmit}>
            <Heading>Add Question</Heading>
            <Form.Item name='question' style={itemStyle} rules={defaultRules}>
              <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
            </Form.Item>
            <Heading>Add Answer</Heading>
            <Form.Item name='answer' style={itemStyle} rules={defaultRules}>
              <Input.TextArea autoSize={{ minRows: 7 }} />
            </Form.Item>

            <Heading>Priority</Heading>
            <Form.Item name='priority' style={itemStyle} rules={defaultRules}>
              <Input
                style={{ width: '100px' }}
                type='number'
                defaultValue={0}
              />
            </Form.Item>

            <Button htmlType='submit'>Add FAQ</Button>
          </Form>
        </CardContainer>
      </Spin>
    </div>
  );
};
export default FaqPost;
