import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const CrossSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='9'
    height='9'
    viewBox='0 0 9 9'
    fill='none'
  >
    <path
      d='M1 1.09375L8 8.09375'
      stroke='#1D1D1D'
      strokeWidth='1.5'
      stroke-linecap='round'
    />
    <path
      d='M8 1.09375L1 8.09375'
      stroke='#1D1D1D'
      strokeWidth='1.5'
      stroke-linecap='round'
    />
  </svg>
);

export const CrossIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={CrossSvg} {...props} />
);
