import { Rule } from 'antd/lib/form';

const emailRules: Rule[] = [
  {
    type: 'email',
    message: 'This field must contain a valid email.',
  },
];

const defaultRules = [
  {
    whitespace: true,
    required: true,
    message: 'This field is required',
  },
];

export { defaultRules, emailRules };
