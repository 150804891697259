import Uploader from "../../components/uploader";
import PhoneInput from "react-phone-number-input";
import { services } from "../../api/services";
import { useFetch } from "../../api/provider";
import { v4 as uuidv4 } from "uuid";
import { uploadImageOnS3 } from "../../pages/writeBlog/methods";
import { useEffect, useState } from "react";
import { SettingsFormContainer } from "../../constants/styles";
import { getBase64, S3Response } from "../../utils";
import { getSession, saveSession } from "../../helpers/SessionManagement";
import { emailRules, defaultRules } from "../../utils/fieldsRules";
import { colors, MESSAGE, nationalities } from "../../constants";
import { Form, Input, Button, Select, Spin, message } from "antd";

const fieldStyle = {
  fontWeight: 600,
  margin: "18px 0px 10px 0px",
};

type UserInfo = {
  email: string;
  phone: string;
  country: string;
  lastName: string;
  firstName: string;
};

const ProfileSettings = () => {
  const [form] = Form.useForm();
  const [phone, setPhone] = useState("");
  const [file, setFile] = useState<any>([]);
  const [imageLoading, setImageLoading] = useState(false);
  const user = getSession()?.user;

  const [, updateLoading, updateUserCall] = useFetch({
    service: services.updateUserInfo,
  });

  const [, mediaLoading, addMediaCall] = useFetch({
    service: services.addMedia,
  });

  useEffect(() => {
    form.setFieldsValue({
      email: user?.email,
      phone: user?.phone,
      country: user?.country,
      lastName: user?.lastName,
      firstName: user?.firstName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    let mediaId;
    const uuid = uuidv4();
    let extension;
    if (file[0]) {
      const based64 = await getBase64(file[0].originFileObj);
      extension = file[0].name.split(".");
      extension = extension[extension.length - 1];

      const fileObj = {
        data: based64 as string,
        name: file[0].name,
        extension: extension,
        loading: false,
      };
      const s3res = await uploadImageOnS3(
        fileObj,
        `${user?.id}/uploads/media/images/${uuid}.${extension}`,
        "image",
        setImageLoading
      );
      const resp = s3res as S3Response;

      if (resp.url) {
        const mediaAdded = await addMediaCall({
          medias: [
            {
              file: `${uuid}.${extension}`,
              fileType: "image",
              preview: `${uuid}.${extension}`,
              sizeKb: Math.floor((resp.videoSize as number) / 1000),
            },
          ],
        });
        if (mediaAdded.status === MESSAGE.SUCCESS_RESPONSE) {
          mediaId = mediaAdded.data.medias[0].id;
        }
      }
    }

    const formFields: UserInfo = form.getFieldsValue();
    const response = await updateUserCall({
      id: user?.id,
      ...formFields,
      avatarMediaId: mediaId ? mediaId : user?.avatarMediaId,
    });
    if (response.status === MESSAGE.SUCCESS_RESPONSE) {
      let userObj;
      message.success(MESSAGE.INFO_UPDATED);
      if (extension) {
        userObj = {
          ...response.data,
          avatarMediaId: mediaId ? mediaId : user?.avatarMediaId,
          avatarMedia: {
            ...user.avatarMedia,
            preview: extension
              ? `${uuid}.${extension}`
              : user.avatarMedia.preview || undefined,
            file: extension
              ? `${uuid}.${extension}`
              : user.avatarMedia.preview || undefined,
          },
        };
      } else {
        userObj = {
          ...response.data,
          avatarMediaId: mediaId ? mediaId : user?.avatarMediaId,
        };
      }

      saveSession({
        ...getSession(),
        user: userObj,
      });
    }
  };

  return (
    <Spin spinning={updateLoading || imageLoading || mediaLoading}>
      <SettingsFormContainer>
        <Uploader accept="image/*" file={file} setFile={setFile} />
        <Form.Provider onFormFinish={handleSubmit}>
          <Form form={form} layout="vertical">
            <Form.Item
              label="First Name"
              name="firstName"
              style={fieldStyle}
              rules={defaultRules}
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastName"
              style={fieldStyle}
              rules={defaultRules}
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              style={fieldStyle}
              rules={[...defaultRules, ...emailRules]}
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
              label="Phone"
              name="phone"
              style={fieldStyle}
              rules={defaultRules}
            >
              <PhoneInput value={phone} onChange={(value) => setPhone(value)} />
            </Form.Item>
            <Form.Item
              label="Nationality"
              name="country"
              style={{ ...fieldStyle, marginBottom: 40 }}
              rules={defaultRules}
            >
              <Select>
                {nationalities.map((item) => (
                  <Select.Option
                    key={item.name}
                    style={{
                      fontSize: "12px",
                      color: colors.darkGray,
                      background: "none",
                    }}
                    value={item.name}
                  >
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
          <Button onClick={form.submit}>Save Changes</Button>
        </Form.Provider>
      </SettingsFormContainer>
    </Spin>
  );
};

export default ProfileSettings;
