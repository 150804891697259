import styled from 'styled-components';
import AddTags from '../../components/addTags';
import { Photo } from '../../assets';
import TextEditor from '../../components/TextEditor';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { services } from '../../api/services';
import { useFetch } from '../../api/provider';
import { getSession } from '../../helpers/SessionManagement';
import { Heading, ImageInputBox } from '../../constants/styles';
import AddBlogUpdateNote from '../../components/addBlogUpdateNote';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { message, Input, Button, Spin } from 'antd';
import { uploadImageOnS3, handleImageChange } from './methods';
import {
  getBlogImageStorage,
  deleteBlogImageStorage,
} from '../../helpers/BlogStorageManagement';
import {
  FileInfo,
  ImageState,
  getBlogImageUrl,
  getImageSrcFromHtml,
} from '../../utils';
import {
  colors,
  MESSAGE,
  BLOG_DATA,
  INITIAL_IMAGE_STATE,
  SAVE_TEXT_EDITOR_STATE,
  INITIAL_FILE_INFO_STATE,
} from '../../constants';
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromRaw,
} from 'draft-js';

const Container = styled.div`
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 200px;

  svg path {
    fill: #ffffff;
  }

  @media (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 80px;
  }

  .ant-btn {
    color: ${colors.white};
    width: 200px;
    height: 50px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 14px;
    :hover {
      border: none;
    }
  }
  .postButton {
    background-color: ${colors.purple};
    :hover {
      background-color: ${colors.textColor};
    }
  }
  .saveButton {
    background-color: ${colors.grayDark};
    :hover {
      background-color: ${colors.textColor};
    }
  }
`;

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  display: flex;
  padding-top: 20px;
  flex-direction: row;
  padding-bottom: 20px;
  justify-content: space-between;
`;

const HeadingMain = styled.div`
  font-size: 30px;
  margin: 20px 0 30px;
`;

const TitleField = styled(Input)`
  font-size: 30px;
  margin-bottom: 50px;
`;

const WriteBlog = () => {
  const ref = useRef<HTMLInputElement>(null);
  const history = useHistory();
  const location = useLocation();
  const titleRef = useRef<Input>(null);

  const [note, setNote] = useState<string>('');
  const [editBlogId] = useState<string>(location.pathname.split('/')[2]);
  const [image, setImage] = useState<ImageState>(INITIAL_IMAGE_STATE);
  const [title, setTitle] = useState<string>('');
  const [tagsList, setTagsList] = useState<string[]>([]);
  const [oldImages, setOldImages] = useState<string[]>([]);
  const [imageS3Uploading, setImageS3Uploading] = useState(false);
  const [imagesInfoList, setImagesInfoList] = useState<FileInfo>(
    INITIAL_FILE_INFO_STATE
  );
  const [editorState, setEditorState] = useState<EditorState>(() =>
    EditorState.createEmpty()
  );

  const [, blogLoading, addBlogCall] = useFetch({
    service: services.postBlog,
  });
  const [, editedLoading, editBlogCall] = useFetch({
    service: services.editBlog,
  });
  const [, addMediaLoading, addMediaCall] = useFetch({
    service: services.addMedia,
  });

  const [, deleteImagesLoading, deleteHtmlImagesCall] = useFetch({
    service: services.deleteHtmlImages,
  });

  const [singleBlog, getBlogLoading, getEditBlog] = useFetch({
    service: `${services.getBlogById}/${editBlogId}`,
    method: 'GET',
  });

  const resetBlog = () => {
    setEditorState(EditorState.createEmpty());
    setImagesInfoList(INITIAL_FILE_INFO_STATE);
    setTitle('');
    setImage(INITIAL_IMAGE_STATE);
    setTagsList([]);
    titleRef.current?.setValue('');
  };

  const handleSaveBlog = () => {
    const textEditorStateData = JSON.stringify(
      convertToRaw(editorState.getCurrentContent())
    );
    const blogData = JSON.stringify({
      title: title,
      image: image.data,
      tags: tagsList,
    });
    localStorage.setItem(SAVE_TEXT_EDITOR_STATE, textEditorStateData);
    localStorage.setItem(BLOG_DATA, blogData);
    message.success(MESSAGE.BLOG_SAVED);
  };

  useEffect(() => {
    const linksList: string[] = getBlogImageStorage();
    if (linksList?.length > 0) {
      deleteHtmlImagesCall({ mediaLinks: linksList });
      deleteBlogImageStorage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!editBlogId) {
      const savedDraft = localStorage.getItem(SAVE_TEXT_EDITOR_STATE);
      const savedBlogData = localStorage.getItem(BLOG_DATA);
      if (savedDraft) {
        setEditorState(
          EditorState.createWithContent(convertFromRaw(JSON.parse(savedDraft)))
        );
      }
      if (savedBlogData) {
        const parseData = JSON.parse(savedBlogData);
        setTitle(parseData.title);
        setTagsList(parseData.tags);
        setImage({
          data: parseData.image,
          extension: '',
          loading: false,
          name: '',
        });
      }
    } else {
      getEditBlog();
    }

    if (titleRef.current) titleRef.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    //Edit ==> Pre-fill blog values
    if (singleBlog?.status === MESSAGE.SUCCESS_RESPONSE) {
      const htmlImagesList = getImageSrcFromHtml(singleBlog?.data?.blog?.blog);
      setOldImages(htmlImagesList);
      const contentBlock = htmlToDraft(singleBlog?.data?.blog?.blog);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }
      if (singleBlog?.data?.blog?.title) setTitle(singleBlog.data.blog.title);
      if (singleBlog?.data?.blog?.tags)
        setTagsList(JSON.parse(singleBlog.data.blog.tags));
      if (singleBlog?.data?.blog?.blogMedia[0]?.media?.file) {
        const url = getBlogImageUrl(
          singleBlog?.data?.blog?.blogMedia[0]?.media?.file
        );
        const img = url.replace(`${process.env.REACT_APP_AWS_BASE_URL}/`, '');
        setOldImages((prev) => [img, ...prev]);
        setImage({ data: url, extension: '', loading: false, name: '' });
      }
    }
  }, [singleBlog]);

  const handleNewBlog = async (html: string) => {
    await uploadImageOnS3(
      image,
      'blog/media/' + imagesInfoList.file,
      `image`,
      setImageS3Uploading
    );
    const addedMedia = await addMediaCall({ medias: [imagesInfoList] });

    if (addedMedia.status === MESSAGE.SUCCESS_RESPONSE) {
      const mediaIdList = addedMedia.data.medias.map((item: any) => item.id);
      const blogAdded = await addBlogCall({
        blog: html,
        author_id: parseInt(getSession()?.user?.id),
        title: title,
        tags: JSON.stringify(tagsList),
        media_ids: mediaIdList,
      });
      let newImagesList = getImageSrcFromHtml(html);
      const filter = oldImages.filter((old) => !newImagesList.includes(old));

      if (filter.length > 0) {
        deleteHtmlImagesCall({ mediaLinks: filter });
      }
      if (blogAdded.status === MESSAGE.SUCCESS_RESPONSE) {
        message.success(MESSAGE.BLOG_ADDED);
        resetBlog();
        deleteBlogImageStorage();
        history.push('/blogs');
      } else message.error(blogAdded.message || MESSAGE.BLOG_FAILED);
    } else {
      message.error(addedMedia.message || MESSAGE.MEDIA_FAILED);
    }
  };

  const handleEditBlog = async (html: string) => {
    if (imagesInfoList.preview) {
      await uploadImageOnS3(
        image,
        'blog/media/' + imagesInfoList.file,
        `image`,
        setImageS3Uploading
      );
    }
    let newImagesList = getImageSrcFromHtml(html);
    newImagesList = [
      imagesInfoList.preview
        ? `blog/media/${imagesInfoList.file}`
        : oldImages[0],
      ...newImagesList,
    ];

    const filter = oldImages.filter((old) => !newImagesList.includes(old));
    const blogEdited = await editBlogCall({
      blog_id: editBlogId,
      blog: html,
      author_id: parseInt(getSession()?.user?.id),
      title: title,
      tags: JSON.stringify(tagsList),
      updateNote: note,
      mediaFileInfo: imagesInfoList.preview ? imagesInfoList : null,
      imagesToDelete: filter,
    });

    if (blogEdited.status === MESSAGE.SUCCESS_RESPONSE) {
      message.success(MESSAGE.BLOG_EDITED);
      deleteBlogImageStorage();
      history.push('/blogs');
    } else {
      message.error(blogEdited.message || MESSAGE.BLOG_FAILED);
    }
  };

  const submitBlog = async () => {
    if (!title) {
      message.error(MESSAGE.BLOG_TITLE_REQUIRED);
      return;
    }
    if (!image?.data) {
      message.error(MESSAGE.BLOG_IMAGE_REQUIRED);
      return;
    }
    if (tagsList.length === 0) {
      message.error(MESSAGE.TAG_REQUIRED);
      return;
    }

    if (!note && editBlogId) {
      message.error(MESSAGE.UPDATE_NOTE_REQUIRED);
      return;
    }

    const raw = convertToRaw(editorState.getCurrentContent());
    const html = draftToHtml(raw);

    if (editBlogId) handleEditBlog(html);
    else handleNewBlog(html);
  };

  const ImageArea = () => (
    <>
      {!image?.data ? (
        <ImageInputBox onClick={() => ref && ref.current?.click()}>
          <img src={Photo} alt='icon' />
          {image?.loading ? 'Loading...' : 'App Banner Photo'}
        </ImageInputBox>
      ) : (
        <ImageInputBox style={{ border: 'none' }}>
          <img
            src={image?.data}
            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
            onClick={() => ref && ref.current?.click()}
            alt='Hero'
          />
        </ImageInputBox>
      )}
    </>
  );

  return (
    <Container>
      <Heading
        margin='20px'
        fontSize='28px'
        fontWeight='800'
        lineHeight='110%'
        fontFamily='Nexa XBold'
        color={colors.textColor}
      >
        {'Create Blog'}
      </Heading>
      <Spin
        spinning={
          blogLoading ||
          addMediaLoading ||
          editedLoading ||
          getBlogLoading ||
          deleteImagesLoading ||
          imageS3Uploading
        }
        style={{ position: 'fixed', top: '30%' }}
      >
        <HeadingWrapper>
          <TitleField
            bordered={false}
            ref={titleRef}
            placeholder='Your title goes here...'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <Button onClick={() => resetBlog()} className='saveButton'>
            Reset Blog
          </Button>
        </HeadingWrapper>
        <input
          accept='image/png, image/jpeg'
          ref={ref}
          type={'file'}
          onChange={(e) =>
            handleImageChange(e, setImage, setImagesInfoList, image)
          }
          style={{ display: 'none' }}
        />

        <ImageArea />

        <HeadingMain>Body</HeadingMain>
        <TextEditor
          setImageS3Uploading={setImageS3Uploading}
          setOldImages={setOldImages}
          editorState={editorState}
          setEditorState={setEditorState}
        />
        <AddTags tagsList={tagsList} setTagsList={setTagsList} />
        {editBlogId && <AddBlogUpdateNote setNote={setNote} note={note} />}
        <ButtonWrapper>
          <Button onClick={() => handleSaveBlog()} className='saveButton'>
            Save
          </Button>
          <Button onClick={() => submitBlog()} className='postButton'>
            Post
          </Button>
        </ButtonWrapper>
      </Spin>
    </Container>
  );
};

export default WriteBlog;
