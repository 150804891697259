import styled from 'styled-components';
import { colors } from '../../constants';
import { columns } from './columns';
import { services } from '../../api/services';
import { useFetch } from '../../api/provider';
import TablePageHeader from '../../components/tablePageHeader';
import CustomPagination from '../../components/customPagination';
import { useState, useEffect } from 'react';
import { Table, Spin, message } from 'antd';
import { Heading } from '../../constants/styles';

const RootContainer = styled.div`
  .ant-table-cell {
    font-weight: 700;
    font-size: 14px;
    color: ${colors.gray};
  }
  .ant-table-row-level-0:hover {
    button {
      background-image: linear-gradient(#fd747e, #281ac8 150%);
    }
  }
`;

const PAGE = { pageNo: 1, pageSize: 6 };

const Messages = () => {
  const [page, setPage] = useState(PAGE);
  const [total, setTotal] = useState(0);
  const [reportsData, loading] = useFetch({
    service: services.getReports,
    values: page,
  });

  useEffect(() => {
    if (reportsData && !reportsData?.success)
      message.error(reportsData.message);
    setTotal(reportsData?.meta?.count);
  }, [reportsData]);

  return (
    <RootContainer>
      <Heading
          margin='20px'
          fontSize='28px'
          fontWeight='800'
          lineHeight='110%'
          fontFamily='Nexa XBold'
          color={colors.textColor}
        >
          {'Notifications'}
        </Heading>
      <TablePageHeader>
        <Spin spinning={loading}>
          <Table
            dataSource={reportsData?.data}
            rowKey='id'
            columns={columns()}
            scroll={{ x: 1000 }}
            style={{ flex: 1, paddingRight: 10 }}
            pagination={false}
          />
          <CustomPagination page={page} setPage={setPage} total={total} />
        </Spin>
      </TablePageHeader>
    </RootContainer>
  );
};

export default Messages;
