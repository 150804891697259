import { services } from '../../../api/services';
import { useFetch } from '../../../api/provider';
import { Label } from '../../../constants/styles';
import { MESSAGE, colors } from '../../../constants';
import { ConfigProvider, Modal, Spin, message } from 'antd';

export interface DltQuestionProps {
  open: boolean;
  onClose: () => void;
  selectedQuestions: React.Key[];
  setRefetchQuestions?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>;
}

const DeleteQuestionModal = ({
  open,
  onClose,
  selectedQuestions,
  setSelectedRowKeys,
  setRefetchQuestions,
}: DltQuestionProps) => {
  const [, deleteQuestionLoading, deleteQuestionCall] = useFetch({
    service: services.deleteQuestion,
  });

  const handleDelete = async () => {
    const response = await deleteQuestionCall(selectedQuestions);
    if (response.data) {
      setRefetchQuestions && setRefetchQuestions(true);
      message.success(MESSAGE.DELETE_SUCCESS);
      setSelectedRowKeys([]);
    } else {
      message.error(response.message || MESSAGE.REQUEST_FAILED);
    }
    onClose();
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            titleFontSize: 28,
            titleColor: 'white',
            fontWeightStrong: 800,
            titleLineHeight: 1.108,
            fontFamily: 'Nexa XBold',
          },
        },
      }}
    >
      <Modal
        centered
        title='Delete'
        open={open}
        onCancel={onClose}
        onOk={handleDelete}
        className='newStyle'
        okText={'Yes, Delete'}
        style={{
          fontSize: '',
        }}
        okButtonProps={{
          disabled: false,
          style: {
            backgroundColor: colors.purple,
          },
        }}
        cancelButtonProps={{
          className: 'cancelBtn',
        }}
      >
        <Spin spinning={deleteQuestionLoading}>
          <Label
            size='20px'
            weight={800}
            margin='10px 0'
            lineHeight='110%'
            textcolor='#1D1D1D'
            fontfamily='Nexa XBold'
          >
            {`Are you sure you want to delete these question${
              selectedQuestions.length > 1 ? 's?' : '?'
            }`}
          </Label>
        </Spin>
      </Modal>
    </ConfigProvider>
  );
};

export default DeleteQuestionModal;
