import moment from 'moment';
import styled from 'styled-components';
import { colors } from '../../constants';
import { useState } from 'react';
import { DatePicker } from 'antd';
import { FlexContainer } from '../../constants/styles';
import CustomFilterDropDown from '../../components/customFilterDropDown';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import {
  TableInput,
  DataRowInput,
  PageValProps,
  onFilterChange,
} from '../../utils';

const TableRowItem1 = styled.div<DataRowInput>`
  display: flex;
  flex-direction: column;
  margin-left: ${(props: DataRowInput) => (props.active ? '10px' : '0px')};
`;

const Title = styled.p`
  color: ${colors.black};
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  font-weight: 500;
`;

const Columns = (
  setDonationStatus: (filter: string) => void,
  setPage: (page: PageValProps) => void
) => {
  const [dateRange, setDateRange] = useState<{
    startDate: number;
    endDate: number;
  }>({ startDate: 0, endDate: 1 });

  const onChange = (_e: any, dateString: string[]) => {
    setDateRange({
      startDate: new Date(dateString[0]).getTime() / 1000,
      endDate: new Date(dateString[1]).getTime() / 1000,
    });
  };

  const onDateFilter = (createdDate: string) => {
    if (createdDate) {
      const date = new Date(createdDate);
      const recordSeconds = date.getTime() / 1000;
      if (
        recordSeconds >= dateRange.startDate &&
        recordSeconds <= dateRange.endDate
      )
        return true;
    }
    return false;
  };

  return [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: TableInput) => {
        const fullName =
          record.user?.firstName + ' ' + record.user?.lastName || ' ';
        return (
          <FlexContainer>
            <Title>{fullName}</Title>
          </FlexContainer>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'userEmail',
      key: 'userEmail',
      render: (text: string, record: TableInput) => {
        return (
          <FlexContainer>
            <Title>{record?.user?.email || ' '}</Title>
          </FlexContainer>
        );
      },
    },
    {
      title: 'Donated Amount ($)',
      dataIndex: 'amount',
      key: 'amount',
      sorter: (a: any, b: any) => a.amount - b.amount,
      render: (text: string, record: TableInput) => {
        return (
          <FlexContainer>
            <Title>{record.amount || ' '}</Title>
          </FlexContainer>
        );
      },
    },
    {
      title: 'Payment Status',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: 'Success', value: 'success' },
        { text: 'Fail', value: 'failed' },
        { text: 'Pending', value: 'pending' },
      ],

      filterDropdown: (props: FilterDropdownProps) => {
        return (
          <CustomFilterDropDown
            filters={props.filters || []}
            onOkay={(filterVal) =>
              onFilterChange({
                filterValue: filterVal,
                setFilteredValue: setDonationStatus,
                setPage,
              })
            }
            onReset={(filterVal) =>
              onFilterChange({
                filterValue: filterVal,
                setFilteredValue: setDonationStatus,
                setPage,
              })
            }
            props={props}
          />
        );
      },
      filterMultiple: false,

      render: (text: string, record: TableInput) => {
        return (
          <TableRowItem1 active={0}>
            {record.status === 'pending' && <Title>🚧 Pending</Title>}
            {record.status === 'success' && <Title>✅ Success</Title>}
            {record.status === 'failed' && <Title>❌ Failed</Title>}
          </TableRowItem1>
        );
      },
    },
    {
      title: 'Donated On',
      dataIndex: 'date',
      key: 'date',
      filters: [
        {
          text: <DatePicker.RangePicker onChange={onChange} />,
          value: '0',
        },
      ],
      onFilter: (value: any, record: TableInput) =>
        onDateFilter(record.createdAt),
      filterMultiple: false,
      render: (text: string, record: TableInput) => {
        return (
          <TableRowItem1 active={0}>
            <Title>{moment(record.createdAt).format('MMMM DD, YYYY')}</Title>
          </TableRowItem1>
        );
      },
    },
  ];
};

export { Columns };
