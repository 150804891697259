import SettingsTab from '../../components/settingsTab';
import { Divider } from 'antd';
import ResetPassword from '../../components/resetPassword';
import { getSession } from '../../helpers/SessionManagement';
import ProfileSettings from '../../components/profileSettings';
import { Heading } from '../../constants/styles';
import { colors } from '../../constants';

const Settings = () => {
  return (
    <>
      <Heading
        margin='20px'
        fontSize='28px'
        fontWeight='800'
        lineHeight='110%'
        fontFamily='Nexa XBold'
        color={colors.textColor}
      >
        {`Hello, ${getSession().user.firstName} 👋`}
      </Heading>
      <SettingsTab title='Profile'>
        <ProfileSettings />
      </SettingsTab>
      <SettingsTab title='Password'>
        <ResetPassword />
        <Divider />
      </SettingsTab>
    </>
  );
};

export default Settings;
