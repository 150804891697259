import React, { useState, useEffect } from 'react';
import { Form, Input, Button } from 'antd';
import { colors } from '../../constants';
import { RootContainer, Wrapper, Label } from '../../constants/styles';
import { RouterProps } from 'react-router-dom';
import Logo from '../../components/logo';
import { defaultRules } from '../../utils/fieldsRules';

const Authentication = (props: RouterProps) => {
  const [form] = Form.useForm();
  const [time, setTime] = useState(0);
  useEffect(() => {
    if (time > 0) setTimeout(() => setTime(time - 1), 1000);
  });
  const reset = () => {
    if (time === 0) setTime(30);
  };
  const handleSubmit = () => {};
  return (
    <RootContainer>
      <Logo />
      <Form.Provider onFormFinish={() => handleSubmit()}>
        <Form form={form} layout='vertical'>
          <Form.Item
            label='Authentication'
            name='authentication'
            style={{ margin: '18px 0px 10px 0px', fontWeight: 600 }}
            rules={defaultRules}
          >
            <Input type='text' />
          </Form.Item>
          <Wrapper justifycontent='space-between'>
            <Label
              size='15px'
              textcolor={colors.field}
              onClick={reset}
              cursor='pointer'
            >
              Resend
            </Label>
            <Label size='16px' textcolor={colors.counter}>
              {`0:${time > 9 ? time : '0' + time}`}
            </Label>
          </Wrapper>
        </Form>
        <Button>Log In</Button>
      </Form.Provider>
    </RootContainer>
  );
};

export default Authentication;
