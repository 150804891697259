const USER_SESSION_KEY = 'ADMIN_USER_SESSION_KEY';
const TOKEN = 'TOKEN';

const saveSession = (cred: string) => {
  localStorage.setItem(USER_SESSION_KEY, JSON.stringify(cred));
  return true;
};

const getSession = () => {
  let session = localStorage.getItem(USER_SESSION_KEY);
  return session ? JSON.parse(session) : undefined;
};

const deleteSession = () => {
  localStorage.removeItem(USER_SESSION_KEY);
  return true;
};

const isSession = () => {
  let session = localStorage.getItem(USER_SESSION_KEY);
  return !!session;
};

const saveToken = (token: string) => {
  localStorage.setItem(TOKEN, token);
  return true;
};

const getToken = () => {
  let token = localStorage.getItem(TOKEN);
  return token ? token : '';
};

const deleteToken = () => {
  localStorage.removeItem(TOKEN);
  return true;
};

export {
  saveSession,
  getSession,
  deleteSession,
  isSession,
  saveToken,
  getToken,
  deleteToken,
};
