export const services = {
  login: "auth/login",
  addMedia: "media/add_medias",
  editBlog: "blog/edit",
  getUsers: "get_all_users",
  postBlog: "blog/create_blog",
  createFaq: "faqs/create_faq",
  createJob: "jobs/create_job",
  deleteFaq: "faqs/delete_faq",
  deleteJob: "jobs/delete_job",
  updateFaq: "faqs/update_faq",
  updateJob: "jobs/update_job",
  deleteBlog: "blog/delete",
  getAllFaqs: "faqs/get_all_faqs",
  getAllJobs: "jobs/get_all_jobs",
  getFaqById: "faqs/get_faq_by_id",
  getJobById: "jobs/get_job_by_id",
  getReports: "get_all_reported_user",
  addCategory: "category/add_category",
  addQuestion: "questions/create_question",
  getAllBlogs: "blog/get_all_blogs",
  getAllUsers: "user/get_all_users",
  getBlogById: "blog/get_blog_by_id",
  updateMedia: "media/update_medias",
  updateToken: "auth/update_token",
  addQuestionByExcel: "category/add_by_excel",
  getQuestions: "get_questions_by_category_id",
  createQuestion: "questions/create_question",
  deleteCategory: "category/delete_category",
  updateCategory: "category/update_category",
  deleteFeedback: "feedback/delete_feedback",
  deleteQuestion: "questions/delete_questions",
  updateQuestion: "questions/update_question",
  updateUserInfo: "user/update_user",
  getAllDonations: "donations/get_all_donations",
  getAllFeedbacks: "feedback/get_all_feedbacks",
  getAllQuestions: "question/all_questions",
  deleteHtmlImages: "blog/delete_html_images",
  getAllCategories: "category/all_categories",
  getReportedPosts: "report_post/get_reported_posts",
  getReportedUsers: "report_user/get_reported_users",
  deleteTestimonial: "testimonial/delete_testimonial",
  updateTestimonial: "testimonial/update_testimonial",
  getAllTestimonials: "testimonial/get_all_testimonials",
  getDonationsAmount: "donations/get_dontions_total",
  updateUserPassword: "user/update_user_password",
  getAllUsersAndPlans: "user/get_all_users_and_plans",
  getPremiumUserCount: "user/get_premium_user_count",
  getDonationsGraphInfo: "donations/get_donations_graph_info",
  getQuestionStatsById: "questions_stats/get_questions_stat_by_id",
  getUserGraphByDuration: "user/get_registered_users_by_time_period",
  getCategoryWithQuestions: "category/export_category_with_questions",
};
