import React from 'react';
import styled from 'styled-components';
import { TableCell, Text, FlexContainer } from '../../constants/styles';
import { colors } from '../../constants';
import { DataRowInput, getDate } from '../../utils';

const TypeTag = styled.div<DataRowInput>`
  background-color: ${(props: DataRowInput) =>
    props.active === 0
      ? `${colors.lightGrey}`
      : props.active === 1
      ? `${colors.green}`
      : `${colors.purple}`};
  padding: 5px 15px 5px 15px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 11px;
  width: auto;
  height: 24px;
`;

const ViewButton = styled.button`
  background: ${colors.lightGrey};
  width: 90px;
  height: 44px;
  border-radius: 10px;
  border: 1px;
  cursor: pointer;
  color: ${colors.white};
  font-size: 13px;
  outline: none;
`;

const columns = () => {
  return [
    {
      title: 'Ticket #',
      dataIndex: 'code',
      key: 'code',
      render: (text: string) => <TableCell>{`#${text}`}</TableCell>,
    },
    {
      title: 'Topic',
      dataIndex: 'type',
      key: 'type',
      render: (text: string) => <TableCell>{text}</TableCell>,
    },
    {
      title: 'Request Status',
      dataIndex: 'reqStatus',
      key: 'reqStatus',
      render: (text = 'Pending') => (
        <FlexContainer>
          <TypeTag
            active={text === 'Pending' ? 0 : text === 'Need your reply' ? 1 : 2}
          >
            <Text
              color={text === 'Pending' ? colors.blueGray : colors.white}
            >{`${text}`}</Text>
          </TypeTag>
        </FlexContainer>
      ),
    },
    {
      title: 'Last Update',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (text: string) => {
        let date = new Date(text);
        return <TableCell>{getDate(date)}</TableCell>;
      },
    },
    {
      key: 'view',
      render: () => <ViewButton>View</ViewButton>,
    },
  ];
};

export { columns };
