import styled from 'styled-components';
import { Spin } from 'antd';
import { colors } from '../../constants';
import { NameType } from 'aws-sdk/clients/secretsmanager';
import { ValueType } from 'aws-sdk/clients/textract';
import { GraphDataProps } from '../../utils';
import { AccountsChart, TooltipContainer } from '../../constants/styles';
import {
  Area,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  AreaChart,
  TooltipProps,
  ResponsiveContainer,
} from 'recharts';

type DonationGraphProps = {
  isLoading: boolean;
  GraphData: GraphDataProps[];
};

const Title = styled.p`
  color: ${colors.greyBack};
  margin: 0;
  font-size: 12px;
  font-weight: 600;
`;

const Value = styled.h1`
  color: ${colors.appColor};
  font-size: 20px;
`;

const MonthName = styled.p`
  color: ${colors.greyBack};
  font-size: 14px;
  font-weight: 700;
`;

const CustomToolTip = ({
  label,
  payload,
}: TooltipProps<ValueType, NameType>) => {
  return (
    <TooltipContainer>
      <Title>{label}</Title>
      {payload && (
        <div>
          <Value>
            {payload[0]?.payload?.success}, {payload[0]?.payload?.fail}
          </Value>
          <MonthName>
            {payload[0]?.name}, {payload[1]?.name}
          </MonthName>
        </div>
      )}
    </TooltipContainer>
  );
};

const DonationGraph = ({ GraphData, isLoading }: DonationGraphProps) => {
  return (
    <Spin spinning={isLoading}>
      <AccountsChart>
        <ResponsiveContainer width='100%' height='100%' debounce={1}>
          <AreaChart
            data={GraphData}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <defs>
              <linearGradient id='colorNewAccounts' x1='1' y1='0' x2='0' y2='0'>
                <stop offset='5%' stopColor={colors.pink} stopOpacity={0.8} />
                <stop offset='95%' stopColor={colors.blue} stopOpacity={1} />
              </linearGradient>
            </defs>
            <XAxis dataKey='name' />
            <YAxis />
            <Tooltip
              cursor={{ stroke: colors.red, strokeWidth: 2 }}
              content={CustomToolTip}
            />

            <Area
              type='monotone'
              dataKey='success'
              stroke='url(#colorNewAccounts)'
              fillOpacity={0.1}
              strokeWidth={5}
              fill={colors.appColor}
              stackId='1'
            />
            <Legend verticalAlign='top' height={36} align='right' />
            <Area
              type='monotone'
              dataKey='fail'
              strokeWidth={5}
              stroke={colors.appColor10}
              fill='transparent'
            />
          </AreaChart>
        </ResponsiveContainer>
      </AccountsChart>
    </Spin>
  );
};

export default DonationGraph;
