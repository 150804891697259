import { colors } from '../../../constants';
import { ConfigProvider, Modal, Spin } from 'antd';
import { Label, ModalContentWrapper } from '../../../constants/styles';
import { CategoryWithQuestions } from '../../../utils';

export interface ModalWrapperProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  deleteLoad: boolean;
  child?: React.ReactElement;
  category: Partial<CategoryWithQuestions>;
}

const DeleteCategoryModal = ({
  open,
  onClose,
  category,
  onDelete,
  deleteLoad,
}: ModalWrapperProps) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            titleFontSize: 28,
            titleColor: '#342A53',
            fontWeightStrong: 800,
            titleLineHeight: 1.108,
            fontFamily: 'Nexa XBold',
          },
        },
      }}
    >
      <Modal
        centered
        open={open}
        onOk={onDelete}
        okText={'Remove'}
        onCancel={onClose}
        cancelText={'Current'}
        title='Delete Category'
        className='dltCategoryModal'
        style={{
          fontSize: '',
        }}
        okButtonProps={{
          disabled: false,
          style: {
            backgroundColor: colors.purple,
          },
        }}
        cancelButtonProps={{
          className:'cancelBtn'
        }}
      >
        <Spin spinning={deleteLoad}>
          <ModalContentWrapper flexDirection='column' gap='19px'>
            <Label
              margin='0'
              size='18px'
              weight={400}
              lineHeight='140%'
              textcolor='#1D1D1D'
              fontfamily='Nexa Book'
            >
              Are you sure you want to remove this category?
            </Label>
            <Label
              margin='0'
              size='20px'
              weight={800}
              lineHeight='110%'
              textcolor='#1D1D1D'
              fontfamily='Nexa XBold'
              alignSelf='center'
              style={{
                textAlign: category?.questions?.length ? 'left' : 'center',
              }}
            >
              ‘{category?.name}’
            </Label>
            <Label
              margin='0'
              size='18px'
              weight={400}
              lineHeight='140%'
              textcolor='#1D1D1D'
              fontfamily='Nexa Book'
            >
              {category?.questions?.length
                ? `It is applied to ${category?.questions?.length} questions. If you delete it, the questions associated with this category will be reclassified under the "general category."`
                : ''}
            </Label>
          </ModalContentWrapper>
        </Spin>
      </Modal>
    </ConfigProvider>
  );
};

export default DeleteCategoryModal;
