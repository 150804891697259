import styled from 'styled-components';
import { useState } from 'react';
import { colors, MESSAGE } from '../../constants';
import { Input, Button, Tag, message } from 'antd';

const Wrpper = styled.div`
  .anticon {
    font-size: 12px;
    font-weight: bold;
    transition: all 300ms ease-in-out;
    :hover {
      transform: rotate(180deg);
    }
  }
`;

const AddButton = styled(Button)`
  color: ${colors.white};
  width: 50px !important;
  height: 40px !important;
  padding: 0 !important;
  font-size: 11px !important;
  background-color: ${colors.purple} !important;

  :hover {
    border: none;
    background-color: ${colors.textColor} !important;
  }
  :disabled {
    background-color: ${colors.grayscale} !important;
  }
`;

const CustomTag = styled(Tag)`
  font-size: 16px;
  border-radius: 14px;
  padding: 5px 10px 5px 10px;
  font-weight: 600;
`;

const TitleField = styled(Input)`
  font-size: 14px;
  max-width: 300px;
  width: 100%;
  margin: 50px 0 50px;
`;

const Heading = styled.div`
  font-size: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

interface AddTagsProps {
  tagsList: string[];
  setTagsList: (tagsList: any) => void;
}
const AddTags = ({ tagsList, setTagsList }: AddTagsProps) => {
  const [tag, setTag] = useState<string>('');

  const handleAdd = () => {
    const newTag = tag.trim().toLowerCase();
    if (
      tagsList.filter((oldTag) => newTag === oldTag.toLowerCase()).length > 0
    ) {
      message.warning(MESSAGE.DUPLICATE_ENTRY);
      return;
    }

    setTagsList((prev: string[]) => [...prev, tag]);
    setTag('');
  };

  const handleClose = (tagName: string) => {
    const newTagsList = tagsList.filter((tag) => tag !== tagName);
    setTagsList(newTagsList);
  };

  return (
    <Wrpper>
      <Heading>Tags</Heading>
      <div>
        {tagsList.map((tag) => (
          <CustomTag
            key={tag}
            onClose={(e) => {
              e.preventDefault();
              handleClose(tag);
            }}
            color={colors.purple}
            closable
          >
            {tag}
          </CustomTag>
        ))}
      </div>
      <AddButton disabled={!tag} onClick={handleAdd}>
        Add
      </AddButton>
      <TitleField
        bordered={false}
        placeholder='Add your tags related to your article here...'
        value={tag}
        onChange={(e) => setTag(e.target.value)}
      />
    </Wrpper>
  );
};
export default AddTags;
