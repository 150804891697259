import { Input } from 'antd';
import styled from 'styled-components';

interface AddTagsProps {
  note: string;
  setNote: (note: string) => void;
}

const { TextArea } = Input;

const Wrpper = styled.div`
  .ant-input {
    height: 80px;
    width: 250px;
  }
`;

const TitleField = styled(TextArea)`
  font-size: 13px;
  margin: 0px 0 50px;
`;

const Sup = styled.sup`
  font-size: 10px;
`;

const Heading = styled.div`
  font-size: 16px;
  margin-top: 20px;
`;

const MAX_LENGHT = 70;

const AddBlogUpdateNote = ({ note, setNote }: AddTagsProps) => {
  return (
    <Wrpper>
      <Heading>
        Update Note <Sup>{` limit: ${note.length}/${MAX_LENGHT}`}</Sup>
      </Heading>

      <TitleField
        maxLength={MAX_LENGHT}
        typeof='textarea'
        bordered={false}
        placeholder='Please add a brief update note here...'
        value={note}
        onChange={(e) => setNote(e.target.value)}
      />
    </Wrpper>
  );
};
export default AddBlogUpdateNote;
