import styled from 'styled-components';
import { Editor } from 'react-draft-wysiwyg';
import { colors } from '../../constants';
import { uploadCallback } from '../../pages/writeBlog/methods';
import { Dispatch, SetStateAction, useRef } from 'react';
import { ContentBlock, EditorState, RichUtils } from 'draft-js';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

interface TextEditorProps {
  editorState: EditorState;
  setOldImages: Dispatch<SetStateAction<string[]>>;
  setEditorState: (EditorState: EditorState) => void;
  setImageS3Uploading: (loading: boolean) => void;
}

const ContainerClass = styled.div`
  .wrapper {
    padding: 0;
    min-height: 300px;
    border: 1px solid ${colors.purple};
    border-radius: 4px 4px 10px 10px;
  }
  .editor {
    padding: 0 20px;
  }
  .toolbar {
    padding-right: 100px;
    padding-bottom: 10px;
    background-color: ${colors.whiteQuartz};
    border-bottom: 1px solid ${colors.purple_16};
    .rdw-option-wrapper {
      padding: 14px;
    }
    .rdw-dropdown-selectedtext {
      padding: 3px;
    }
    .rdw-option-wrapper,
    .rdw-dropdown-selectedtext {
      border-radius: 4px;
      color: ${colors.purple};
      background-color: ${colors.lightPurple};
      border: 1px solid ${colors.purple};
      :hover {
        background-color: ${colors.whiteQuartz};
      }
    }
  }
  .editor {
    ::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
`;

const ImageWrapper = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ImageCaption = styled.p`
  color: ${colors.greySlate_50};
  margin: 5px 0;
  font-size: 11px;
`;

const TextEditor = ({
  editorState,
  setOldImages,
  setEditorState,
  setImageS3Uploading,
}: TextEditorProps) => {
  const ref = useRef<Editor>(null);
  const onEditorStateChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
  };

  const Media = (props: any) => {
    const key = props?.block?.getEntityAt(0);
    if (key) {
      const entity = props?.contentState?.getEntity(key);
      if (!entity) return null;
      const { src } = entity.getData();
      return (
        <ImageWrapper>
          <img src={src} role={'presentation'} alt={src} />
          <ImageCaption>{src}</ImageCaption>
        </ImageWrapper>
      );
    }

    return null;
  };

  const mediaBlockRenderer = (block: ContentBlock) => {
    if (block.getType() === 'atomic') {
      return {
        component: Media,
        editable: true,
      };
    }
    return null;
  };

  return (
    <ContainerClass>
      <Editor
        ref={ref}
        customBlockRenderFunc={mediaBlockRenderer}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        wrapperClassName={'wrapper'}
        editorClassName={'editor'}
        toolbarClassName={'toolbar'}
        wrapperStyle={{}}
        editorStyle={{}}
        toolbarStyle={{}}
        handleKeyCommand={(command) => {
          const newState = RichUtils.handleKeyCommand(editorState, command);
          if (newState) {
            setEditorState(newState);
            return 'handled';
          }
          return 'not-handled';
        }}
        spellCheck={true}
        toolbar={{
          image: {
            uploadEnabled: true,
            imageLink: false,
            uploadCallback: (file: any) =>
              uploadCallback(file, setOldImages, setImageS3Uploading),
            previewImage: true,
            alignmentEnabled: true,
            alt: {
              present: true,
              mandatory: false,
            },
          },
        }}
        placeholder='Share your story here...'
      />
    </ContainerClass>
  );
};

export default TextEditor;
