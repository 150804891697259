import "./App.css";
import { useState } from "react";
import "leaflet/dist/leaflet.css";
import PrimaryLayout from "./layout";
import { Router, Switch } from "react-router";
import { createBrowserHistory } from "history";
import { PrivateRoute, PublicRoute } from "./Routes";
import { isSession } from "./helpers/SessionManagement";
import { PublicRoutes, PrivateRoutes } from "../src/routes/routes";

let history = createBrowserHistory();
const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(isSession());
  return (
    <Router history={history}>
      <PrimaryLayout history={history} setIsLoggedIn={setIsLoggedIn}>
        <Switch>
          {PrivateRoutes.map(({ key, ...rest }) => {
            return <PrivateRoute {...rest} key={key} />;
          })}
          {PublicRoutes.map(({ key, restricted, ...rest }) => {
            return (
              <PublicRoute
                {...rest}
                key={key}
                restricted={restricted ? isLoggedIn : false}
              />
            );
          })}
        </Switch>
      </PrimaryLayout>
    </Router>
  );
};

export default App;
