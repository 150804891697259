import { MESSAGE, colors } from '../../constants';
import { columns } from './columns';
import { services } from '../../api/services';
import { useFetch } from '../../api/provider';
import { TableInput } from '../../utils';
import TablePageHeader from '../../components/tablePageHeader';
import { Table, Spin } from 'antd';
import CustomPagination from '../../components/customPagination';
import { useState, useEffect } from 'react';
import { Heading } from '../../constants/styles';

const Users = () => {
  const [users, setUsers] = useState<TableInput[]>([]);
  const [total, setTotal] = useState(0);
  const [subscriptionPackage, setSubscriptionPackage] = useState('');
  const [page, setPage] = useState({
    pageNo: 1,
    pageSize: 6,
  });

  const [usersData, loading, getAllUsersCall] = useFetch({
    service: services.getAllUsersAndPlans,
    values: { pageNo: page.pageNo, pageSize: page.pageSize },
    method: 'GET',
  });

  useEffect(() => {
    getAllUsersCall({
      pageNo: page.pageNo,
      pageSize: page.pageSize,
      subPackage: subscriptionPackage,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, subscriptionPackage]);

  useEffect(() => {
    if (usersData?.status === MESSAGE.SUCCESS_RESPONSE) {
      setUsers(usersData?.data?.users);
      setTotal(usersData?.data?.meta?.count);
    }
  }, [usersData]);

  const sortUsers = () => {
    setUsers(
      [...usersData?.data?.users].sort((a, b) =>
        a.firstName.toUpperCase().trim() > b.firstName.toUpperCase().trim()
          ? 1
          : -1
      )
    );
  };

  return (
    <>
      <Heading
        margin='20px'
        fontSize='28px'
        fontWeight='800'
        lineHeight='110%'
        fontFamily='Nexa XBold'
        color={colors.textColor}
      >
        {'Users'}
      </Heading>
      <TablePageHeader onSort={sortUsers}>
        <Spin spinning={loading}>
          <Table
            dataSource={users || []}
            rowKey='id'
            columns={columns(setSubscriptionPackage, setPage)}
            style={{ flex: 1, paddingRight: 10 }}
            scroll={{ x: 1000 }}
            pagination={false}
            onRow={(record: TableInput) => {
              return {
                onClick: () => {},
              };
            }}
          />
          <CustomPagination page={page} setPage={setPage} total={total} />
        </Spin>
      </TablePageHeader>
    </>
  );
};

export default Users;
