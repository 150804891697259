const colors = {
  darkGreyBlue: 'rgba(55,48,82,1)',
  darkGreyBlue0_2: 'rgba(55,48,82,0.2)',
  darkGreyBlue0_3: 'rgba(55,48,82,0.3)',
  darkGreyBlue0_4: 'rgba(55,48,82,0.4)',
  darkGreyBlue0_6: 'rgba(55,48,82,0.6)',
  darkGreyBlue0_7: 'rgba(55,48,82,0.7)',
  darkGreyBlue0_8: 'rgba(55,48,82,0.8)',
  darkGreyBlue0_9: 'rgba(55,48,82,0.9)',
  appColor: '#6D2077',
  appColor05: 'rgba(109, 32, 119, 0.5)',
  appColor10: 'rgba(109, 32, 119, 0.1)',
  buttonText: '#FFFFFF',
  lightPurple: '#F7F6FD',
  textColor: '#342A53',
  textgray: '#697077',
  lightPurple2: '#E6E6F3',
  greyBorder: '#C9C9D6',
  black: '#1D1D1D',
  transparent: 'transparent',
  light1: '#E6E6F3',
  light1_5: 'rgba(230, 230, 243,0.5)',
  light_grey: '#DCDCDC',
  greySlate_50: 'rgba(121, 129, 142, 0.5)',
  backgroundColor: '#EDF1F3',
  headerBackground: '#E6E6F3',
  blueGray: '#79818E',
  lightGrey: '#B9C1C7',
  green: '#29CC97',
  violet: 'purple',
  white: 'white',
  purple: '#6C2078',
  whiteQuartz: '#FCFCFE',
  purple_16: 'rgba(109, 31, 124, 0.16)',
  gray: 'gray',
  grayDark: '#4B506D',
  lightGray: '#C5C7CD',
  darkGray: '#858585',
  grey: '#FAFAFA',
  red: 'red',
  yellow: '#FFBF00',
  field: '#6D1F7C',
  counter: '#878787',
  border: '#D9D9D9',
  option: '#F5F5F5',
  greyBack: 'grey',
  magnolia: '#ECE9F1',
  pink: '#FD749B',
  blue: '#281AC8',
  grayscale: '#9FA2B4',
  borderColor: '#C9C9D6',
  buttontext: '#F7F6FD',
  tabGray: '#EAEAEA',
  lightGreen: '#1CD1A1',
  thistle: '#D8BFD8',
  lightViolet: '#FAA9C6',
  errorText: '#D82949',
  errorlabel: '#FF3B30',
};

const options = ['6', '8', '10', '20'];

const USER_INTERVAL_OPTIONS = ['Daily', 'Weekly', 'Monthly', 'Yearly'];

const EDIT_JOB = 'edit-job';
const EDIT_FAQ = 'edit-faq';
const ADD_FAQ = 'create-faq';

const userTabs = ['Users', 'Accounts'];

const settingsTabs = ['All', 'Administrator', 'Editor', 'Author', 'Subscriber'];

const detailsTabs = ['Complaints', 'Feedback'];

const SAVE_TEXT_EDITOR_STATE = 'SAVE_TEXT_EDITOR_STATE';
const BLOG_DATA = 'BLOG_DATA';

const INITIAL_IMAGE_STATE = {
  data: '',
  name: ' ',
  extension: '',
  loading: false,
};
const INITIAL_FILE_INFO_STATE = {
  preview: '',
  fileType: '',
  sizeKb: 0,
  file: '',
};

enum MESSAGE {
  LOGGEDIN = 'Succesfully logged in',
  TRY_AGAIN = 'Please try again',
  BLOG_ADDED = 'Blog Successfully Added',
  BLOG_SAVED = 'Blog saved successfully',
  JOB_CREATE = 'Job Created Successfully',
  JOB_UPDATE = 'Job Updated Successfully',
  BLOG_EDITED = 'Blog Successfully Edited',
  BLOG_FAILED = 'Failed to Upload Blog',
  FAQ_CREATED = 'FAQ created successfully',
  FAQ_UPDATED = 'FAQ updated successfully',
  MEDIA_FAILED = 'Failed to Upload Medias',
  TAG_REQUIRED = 'At least one tag is required',
  DELETE_SUCCESS = 'Data deleted successfully',
  REQUEST_FAILED = 'Request Failed!',
  DUPLICATE_ENTRY = 'Duplicate Entry',
  LOGGEDIN_FAILED = 'Login Failed',
  SUCCESS_RESPONSE = 'success',
  UNVERIFIED_ACCOUNT = 'Account is not verified',
  BLOG_IMAGE_REQUIRED = 'Blog Image is required',
  BLOG_TITLE_REQUIRED = 'Blog Title is required',
  TESTIMONIAL_UPDATED = 'Testimonial updated successfully',
  USER_NOT_AUTHORIZED = 'Not Authorized',
  UPDATE_NOTE_REQUIRED = 'Update note is required',
  INFO_UPDATED = 'Your info has been updated',
  CATEGORY_NAME = 'Please Enter Category Name',
  NOT_DELETABLE_CATEGORY = "You can't delete default Category",
  NOT_EDITABLE_CATEGORY = "You can't change default category name",
  GENERAL_CATEGORY = 'General Category',
}

enum STATUS {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
}

export enum SUBSCRIPTION_PACKAGE {
  FREE = 'free',
  MONTHLY = '20',
  YEARLY = '16',
}

const nationalities = [
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Åland Islands', code: 'AX' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'AndorrA', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: "Cote D'Ivoire", code: 'CI' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
  { name: 'Holy See (Vatican City State)', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran, Islamic Republic Of', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: "Korea, Democratic People'S Republic of", code: 'KP' },
  { name: 'Korea, Republic of', code: 'KR' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: "Lao People'S Democratic Republic", code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia, Federated States of', code: 'FM' },
  { name: 'Moldova, Republic of', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Netherlands Antilles', code: 'AN' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestinian Territory, Occupied', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Reunion', code: 'RE' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russian Federation', code: 'RU' },
  { name: 'RWANDA', code: 'RW' },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia and Montenegro', code: 'CS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syrian Arab Republic', code: 'SY' },
  { name: 'Taiwan, Province of China', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'United States', code: 'US' },
  { name: 'United States Minor Outlying Islands', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Viet Nam', code: 'VN' },
  { name: 'Virgin Islands, British', code: 'VG' },
  { name: 'Virgin Islands, U.S.', code: 'VI' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' },
];

export {
  STATUS,
  colors,
  MESSAGE,
  options,
  ADD_FAQ,
  userTabs,
  EDIT_JOB,
  EDIT_FAQ,
  BLOG_DATA,
  detailsTabs,
  settingsTabs,
  nationalities,
  INITIAL_IMAGE_STATE,
  USER_INTERVAL_OPTIONS,
  SAVE_TEXT_EDITOR_STATE,
  INITIAL_FILE_INFO_STATE,
};
