import { useContext } from 'react';
import { Logo } from '../../assets';
import styled from 'styled-components';
import { colors } from '../../constants';
import AppContext from '../../appContext';
import { MenuOutlined } from '@ant-design/icons';
import { FlexContainer } from '../../constants/styles';
import { getImageUrl, useWindowSize } from '../../utils';
import { getSession } from '../../helpers/SessionManagement';

const ContentInnerContainerHeader = styled.div`
  display: flex;
  background-color: ${colors.white};
  position: fixed;
  z-index: 10;
  width: 100%;
  padding: 10px 50px;
  max-height: 66px;
  box-shadow: 0px 4px 4px 0px rgba(109, 31, 124, 0.16);
  justify-content: space-between;
  .circle {
    background-image: linear-gradient(#fd749b, #281ac8 150%);
    width: 46px;
    height: 46px;
    border-radius: 25px;
  }
  .border {
    background: ${colors.white};
    border-radius: 25px;
    width: 44px;
    height: 44px;
    margin-top: 1px;
    margin-left: 1px;
  }
  .user {
    border-radius: 25px;
    margin: 2px;
    object-fit: cover;
    width: 40px;
    height: 40px;
  }
  .ant-picker {
    box-shadow: 0 0 0 rgb(255, 255, 255);
  }
  .ant-picker-focused,
  .ant-picker:hover {
    border-color: ${colors.field};
  }
`;

const ContentInnerContainerHeaderRow = styled.div<ContentInnerContainerHeaderRowProps>`
  display: flex;
  flex-direction: column;
  margin-left: ${(props) => props.marginleft};
`;

const HeaderMiddleRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const Container = styled.div`
  display: flex;
`;

const SideContainer = styled.div`
  gap: 20px;
  display: flex;
  cursor: pointer;
  align-items: center;
`;

const Name = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  margin: 0 20px 0 0;
  align-self: center;
  letter-spacing: 0.5px;
  font-family: 'Nexa Bold';
  color: ${colors.textColor};
`;

type ContentInnerContainerHeaderRowProps = {
  marginleft?: string;
  alignitems?: string;
};

const Header = () => {
  const [width] = useWindowSize();
  const setIsCollapsed = useContext(AppContext)?.setIsCollapsed;
  const user = getSession()?.user;

  const TitleContainer = () => (
    <ContentInnerContainerHeaderRow style={{ margin: '0 30px 0 0' }}>
      <FlexContainer>
        <MenuOutlined
          style={{ fontSize: '19px', margin: '12px 12px 12px 0px' }}
          onClick={() => setIsCollapsed && setIsCollapsed(false)}
        />
      </FlexContainer>
    </ContentInnerContainerHeaderRow>
  );
  const Icons = () => (
    <ContentInnerContainerHeaderRow marginleft='auto'>
      <HeaderMiddleRow>
        {width > 711 && (
          <>
            <Name>
              {(user?.firstName || ' ') + ' ' + (user?.lastName || ' ')}
            </Name>
            <Container>
              <div className='circle'>
                <div className='border'>
                  <img
                    src={getImageUrl(user?.id, user?.avatarMedia?.preview)}
                    className='user'
                    alt='user'
                  />
                </div>
              </div>
            </Container>
          </>
        )}
      </HeaderMiddleRow>
    </ContentInnerContainerHeaderRow>
  );
  return (
    <ContentInnerContainerHeader>
      {width && width < 862 && <TitleContainer />}
      <SideContainer>
        <img src={Logo} height={25} width={150} alt='logo' />
      </SideContainer>
      <Icons />
    </ContentInnerContainerHeader>
  );
};

export default Header;
