import React from 'react';
import styled from 'styled-components';
import { LeftArrow, RightArrow } from '../../assets';
import { Pagination, Rows } from '../../constants/styles';
import { options } from '../../constants';
import { showPageInfo } from '../../utils';
import { colors } from '../../constants';

const FlexContainer = styled.div`
  display: flex;
  @media (max-width: 396px) {
    justify-content: space-between;
    margin-left: 10px;
    img {
      margin-top: 5px;
    }
  }
`;

const ArrowsContainer = styled.div`
  display: flex;
  @media (max-width: 396px) {
    margin-right: 20px;
  }
`;

const PageInfo = styled.div`
  margin: 40px 10px 20px 0px;
  color: ${colors.grayscale};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  @media (max-width: 396px) {
    margin-top: 5px;
  }
`;

type pageData = {
  pageSize: number;
  pageNo: number;
};

type CustomPaginationProps = {
  page: pageData;
  setPage: Function;
  total: number;
};

const CustomPagination = ({ page, setPage, total }: CustomPaginationProps) => {
  return (
    <Pagination>
      <FlexContainer>
        <Rows>Rows per page: </Rows>
        <select
          className='select'
          onChange={(event) => {
            let value = Number(event.target.value);
            if (value * page.pageNo - value + 1 > total)
              setPage({ pageSize: value, pageNo: 1 });
            else setPage({ ...page, pageSize: value });
          }}
        >
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </FlexContainer>
      <FlexContainer>
        <PageInfo>{showPageInfo(page.pageSize, page.pageNo, total)}</PageInfo>
        <ArrowsContainer>
          <img
            src={LeftArrow}
            alt='left'
            className='arrow'
            onClick={() =>
              page.pageNo > 1 && setPage({ ...page, pageNo: page.pageNo - 1 })
            }
          />
          <img
            src={RightArrow}
            alt='right'
            className='arrow'
            onClick={() => {
              let count = page.pageNo * page.pageSize;
              count < total && setPage({ ...page, pageNo: page.pageNo + 1 });
            }}
          />
        </ArrowsContainer>
      </FlexContainer>
    </Pagination>
  );
};

export default CustomPagination;
