import React from 'react';
import styled from 'styled-components';
import { colors } from '../../constants';
import { TableCell, FlexContainer, BlogImage } from '../../constants/styles';
import {
  TableInput,
  DataRowInput,
  getBlogImageUrl,
  getFormattedDate,
} from '../../utils';

type ActionProps = {
  color: string;
};

const TableRowItem1 = styled.div<DataRowInput>`
  display: flex;
  flex-direction: column;
  margin-left: ${(props: DataRowInput) => (props.active ? '10px' : '0px')};
`;

const Title = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.black};
  padding: 0px;
  margin: 0px;
`;

const BlogTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: ${colors.black};
  padding: 0px;
  margin: 0px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
`;

const ActionButton = styled.p<ActionProps>`
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.color};
  padding: 0px;
  margin: 0px;
`;

type BlogColumnsProps = {
  onEditHandle: (id: number, html: string) => void;
  onDeleteHandle: (html: string, blogId: string) => void;
};

const columns = ({ onDeleteHandle, onEditHandle }: BlogColumnsProps) => {
  return [
    {
      title: 'Author Name',
      dataIndex: 'authorName',
      key: 'authorName',
      render: (text: string, record: TableInput) => {
        const authorName =
          record.userBlog.firstName + ' ' + record.userBlog.lastName;
        return (
          <TableRowItem1 active={0}>
            <Title>{authorName || ' '}</Title>
          </TableRowItem1>
        );
      },
    },
    {
      title: 'Blog Name',
      dataIndex: 'blogName',
      key: 'blogName',
      render: (text: string, record: TableInput) => {
        const blogName = record.title;

        return (
          <FlexContainer>
            <TableRowItem1 active={1}>
              <BlogTitle>{blogName}</BlogTitle>
            </TableRowItem1>
          </FlexContainer>
        );
      },
    },
    {
      title: 'Hero Image',
      dataIndex: 'image',
      key: 'image',
      render: (text: string, record: TableInput) => {
        const mediaUrl = record?.blogMedia[0]?.media?.file;

        return (
          <TableCell textcolor={colors.purple}>
            <BlogImage alt='Blog' src={getBlogImageUrl(mediaUrl)} />
          </TableCell>
        );
      },
    },

    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string, record: TableInput) => {
        const createdDate = getFormattedDate(new Date(record.createdAt));

        return (
          <TableRowItem1 active={0}>
            <Title>{createdDate}</Title>
          </TableRowItem1>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      render: (text: string, record: TableInput) => {
        return (
          <ButtonWrapper>
            <ActionButton
              color={colors.appColor}
              onClick={() => onEditHandle(record.id, record.blog)}
            >
              Edit
            </ActionButton>
            <ActionButton
              color={colors.red}
              onClick={() =>
                onDeleteHandle(record.blog, record.blogMedia[0].blog_id)
              }
            >
              Delete
            </ActionButton>
          </ButtonWrapper>
        );
      },
    },
  ];
};

export { columns };
