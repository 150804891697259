import { Space } from 'antd';
import { colors } from '../constants';
import styled from 'styled-components';
import { DataRowInput } from '../utils';

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  padding: 40px 0px;
  img {
    width: 55px;
    height: 40px;
    object-fit: cover;
  }
  input {
    border-radius: 8px;
    height: 42px;
    @media (max-width: 448px) {
      width: 300px !important;
    }
    @media (max-width: 316px) {
      width: 100% !important;
    }
  }
  .ant-input {
    box-shadow: 0 0 0 rgb(255, 255, 255);
    width: 420px;
  }
  button {
    background: ${colors.field};
    color: ${colors.white};
    width: 420px;
    height: 52px;
    font-size: 15px;
    font-weight: 400;
    border-radius: 11px;
    margin-top: 40px;
  }
  .ant-btn[disabled] {
    background: ${colors.field};
    opacity: 40%;
    color: ${colors.white};
  }
  button:hover,
  button:focus {
    background: ${colors.field};
    color: ${colors.white};
    border: 1px ${colors.field};
    opacity: 0.9;
  }
  @media (max-width: 448px) {
    .ant-btn {
      width: 100% !important;
    }
  }
  .ant-form-item-label {
    label {
      font-size: 14px;
      line-height: 19px;
      font-weight: 400;
      color: ${colors.blueGray} !important;
      margin: 0;
      cursor: pointer;
    }
  }
`;

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: ${(props) => props.justifycontent};
  tspan {
    font-weight: 400;
    font-size: 12px;
  }
`;

const Title = styled.p<TextProps>`
  font-size: 29px;
  line-height: 29px;
  margin-left: 5px;
  margin-top: 3px;
  font-weight: ${(props) => props.weight};
  color: ${(props) => props.textcolor};
`;

const BlogImage = styled.img`
  width: 140px;
  height: 140px;
  border-radius: 10px;
  object-fit: scale-down;
`;

const Label = styled.p<TextProps>`
  font-size: ${(props) => props.size};
  color: ${(props) => props.textcolor};
  align-self: ${(props) => props.alignSelf};
  font-family: ${(props) => props.fontfamily};
  margin: ${(props) => (props.margin ? props.margin : 0)};
  cursor: ${(props) => (props.cursor ? props.cursor : 'text')};
  font-weight: ${(props) => (props.weight ? props.weight : '400')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '19px')};
`;

const RootWrapper = styled.div`
  .ant-table-cell {
    font-weight: 700;
    font-size: 14px;
    color: ${colors.gray};
  }
  img {
    margin-right: 10px;
  }
  .screenshot {
    border-radius: 5px;
    margin-bottom: 5px;
    width: 48px;
    height: 48px;
    object-fit: cover;
  }
  .text {
    position: absolute;
    z-index: 1;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    color: ${colors.white};
    cursor: pointer;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexContainer = styled.div`
  display: flex;
`;

const Updated = styled.p`
  color: ${colors.lightGray};
  font-weight: 400;
  font-size: 12px;
  padding: 0;
  margin: 0;
`;

const SettingsFormContainer = styled.div`
  .PhoneInputCountry {
    display: none;
  }
  .ant-input,
  .PhoneInputInput,
  .ant-select-selector {
    outline: none !important;
    padding: 4px 11px !important;
    border: 1px solid ${colors.border};
    font-weight: 400 !important;
    font-size: 12px !important;
    color: ${colors.darkGray};
    box-shadow: 0 0 0 rgb(255, 255, 255) !important;
    width: 530px !important;
    height: 62px !important;
    border-radius: 6px !important;
    @media (max-width: 553px) {
      width: 350px !important;
    }
    @media (max-width: 365px) {
      width: 250px !important;
    }
  }
  button {
    background-image: linear-gradient(#fd747b, #281ac8 150%);
    width: 530px;
    font-size: 10px;
    font-weight: 400;
    height: 62px;
    border-radius: 6px;
    color: ${colors.white};
  }
  .ant-btn[disabled] {
    background-image: linear-gradient(#fd747b, #281ac8 150%);
    opacity: 40%;
    color: ${colors.white};
  }
  button:hover,
  button:focus {
    background-image: linear-gradient(#fd747b, #281ac8 150%);
    color: ${colors.white};
    border: 1px ${colors.field};
    opacity: 0.9;
  }
  .ant-btn {
    width: 100% !important;
  }
  .ant-form-item-label {
    label {
      font-size: 12px;
      line-height: 19px;
      font-weight: 400;
      color: ${colors.darkGray} !important;
      margin: 0;
      cursor: pointer;
    }
  }
`;

const TableCellText = styled.p<TableCellTextProps>`
  padding: 10px 0px;
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  font-size: ${(props) => props.font};
  font-weight: ${(props) => props.weight};
  width: ${(props) => props.width};
  margin-bottom: 0px;
  color: ${colors.black};
`;

const TableCell = styled.p<TableCellProps>`
  font-size: 14px;
  padding: 10px 0px;
  margin-bottom: 0px;
  font-weight: 600;
  color: ${(props) => (props.textcolor ? props.textcolor : colors.black)};
`;

const Text = styled.p`
  margin: 0;
  color: ${(props) => (props.color ? props.color : colors.white)};
  font-weight: 700;
`;

const Pagination = styled.div`
  justify-content: flex-end;
  display: flex;
  margin-right: 2rem;
  @media (max-width: 440px) {
    margin-right: 10px;
  }
  @media (max-width: 396px) {
    flex-direction: column;
  }
`;

const Rows = styled.div`
  margin: 40px 10px 20px 0px;
  color: ${colors.grayscale};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const CreateButton = styled.div`
  color: ${colors.white};
  width: 170px;
  margin-top: 20px;
  padding: 10px 25px;
  font-size: 13px;
  text-align: center;
  transition: 300ms;
  font-weight: 500;
  line-height: 22px;
  margin-left: auto;
  margin-right: 20px;
  border-radius: 10px;
  background-color: ${colors.purple};
  :hover {
    cursor: pointer;
    font-size: 14px;
    background-color: ${colors.darkGreyBlue};
  }
`;

const Heading = styled.h2<CardContainerProps>`
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '700')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  color: ${(props) => (props.color ? props.color : colors.blueGray)};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '22px')};
  margin: ${(props) => (props.margin ? props.margin : 0)};
  width: ${(props) => props.width};
  font-family: ${(props) => props.fontFamily};
`;

const CardContainer = styled.div<CardContainerProps>`
  height: ${(props) => props.height};
  padding: 30px;
  margin: ${(props) => (props.margin ? props.margin : '')};
  box-shadow: 0px 0px 16px rgba(218, 218, 218, 0.6);
  border-radius: 12px;
  @media (max-width: 767px) {
    margin: 20px;
  }
  textarea {
    border: 1px solid ${colors.borderColor} !important;
    box-sizing: border-box;
    border-radius: 8px !important;
    outline: none !important;
    padding: 10px 11px !important;
    font-weight: 400 !important;
    font-size: 15px !important;
    color: ${colors.black} !important;
    box-shadow: 0 0 0 rgb(255, 255, 255) !important;
    height: 120px !important;
  }
  .ant-select-selector {
    border: 1px solid ${colors.borderColor} !important;
    border-radius: 8px !important;
    height: 46px !important;
    outline: none !important;
    box-shadow: 0 0 0 rgb(255, 255, 255) !important;
  }
  button {
    background-color: ${colors.field};
    width: 100%;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    height: 62px;
    border: none;
    border-radius: 6px;
    color: ${colors.buttontext};
  }
  button:hover,
  button:focus {
    background: ${colors.field};
    color: ${colors.white};
    opacity: 0.7;
  }
  .ellipse {
    width: 56px;
    height: 56px;
  }
  .plus {
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 1;
    color: ${colors.white};
    top: 30%;
    right: 17px;
  }
`;

const AccountsChart = styled.div`
  width: 97%;
  height: 350px;
  background-color: ${colors.magnolia};
  margin: 20px;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: row;
`;

const SortText = styled.p`
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
  padding-bottom: 0px;
  padding-top: 0px;
  margin-bottom: 0px;
  color: ${colors.grayDark};
`;

const RelativeContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
`;

const CategoryTab = styled.div`
  flex: 1;
  min-width: 70px;
  height: 32px;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  cursor: pointer;
  border-radius: 42.5px;
  margin-right: 10px;
  margin-bottom: 10px;
  justify-content: center;
  background-image: ${(props: DataRowInput) =>
    props.active !== 1 ? 'linear-gradient(#FD747E, #281AC8 150%)' : ''};
  background-color: ${(props) => (props.active !== 0 ? colors.tabGray : '')};
  color: ${(props) => (props.active === 0 ? colors.white : colors.black)};
  @media (max-width: 394px) {
    width: max-content;
  }
`;

const GraphCard = styled.div<CardContainerProps>`
  width: ${(props) => props.width};
  height: ${(props) => (props.height ? props.height : '230px')};
  background: ${colors.white};
  box-shadow: 0px 0px 16px rgba(218, 218, 218, 0.6);
  border-radius: 12px;
  margin: 30px;
  .line {
    width: 20px;
    height: 4px;
    margin: 8px 10px 0px 0px;
  }
`;

const ChartContainer = styled.div<CardContainerProps>`
  padding: 20px;
`;

const TooltipContainer = styled.div`
  width: 200px;
  background-color: ${colors.white};
  border-radius: 20px;
  box-shadow: 5px 10px 18px #888888;
  padding: 10px;
`;

const ImageInputBox = styled.div`
  width: 400px;
  border: 1px solid ${colors.greySlate_50};
  cursor: pointer;
  height: 300px;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 768px) {
    width: 250px;
    height: 250px;
  }
`;

const StyledButton = styled.button<{
  border?: string;
  margin?: string;
  padding?: string;
  bgColor?: string;
  textColor?: string;
  fontFamily?: string;
}>`
  background: ${(props) => (props.bgColor ? props.bgColor : colors.white)};
  height: 30px;
  font-weight: 400;
  border-radius: 4px;
  border: ${(props) =>
    props.border ? props.border : `1px solid ${colors.purple}`};
  cursor: pointer;
  color: ${(props) => (props.textColor ? props.textColor : colors.purple)};
  font-size: 14px;
  font-family: ${(props) =>
    props.fontFamily ? props.fontFamily : 'Nexa Regular'};
  line-height: 100%;
  margin: ${(props) => (props.margin ? props.margin : '24px 2.5px')};
  padding: ${(props) => (props.padding ? props.padding : '0 14px')};
  outline: none;
`;

const ModalContentWrapper = styled(Space)<{
  gap?: string;
  margin?: string;
  flexDirection?: string;
}>`
  display: flex;
  align-items: unset;
  gap: ${(props) => props.gap};
  margin: ${(props) => props.margin};
  flex-direction: ${(props) => props.flexDirection};
`;

const TableHeaderButton = styled.button<{
  border?: string;
  bgColor?: string;
  padding?: string;
  textColor?: string;
}>`
  background: ${(props) => (props.bgColor ? props.bgColor : colors.white)};
  height: 40px;
  border-radius: 6px;
  border: ${(props) =>
    props.border ? props.border : `1px solid ${colors.purple}`};
  cursor: pointer;
  color: ${(props) => (props.textColor ? props.textColor : colors.purple)};
  font-size: 16px;
  font-family: Nexa Bold;
  line-height: 100%;
  letter-spacing: 0.5px;
  padding: ${(props) => (props.padding ? props.padding : '10px 19px 8px')};
  outline: none;
  &: disabled {
    border: none;
    color: #b9c1c7;
    background: #edf1f3;
    cursor: not-allowed;
  }
  span {
    margin: 0 0 0 10px;
  }
`;

type TableCellProps = {
  textcolor?: string;
};

interface TableCellTextProps {
  font?: string;
  weight?: number;
  width?: string;
  margin?: string;
  padding?: string;
}

type WrapperProps = {
  justifycontent: string;
};

type TextProps = {
  textcolor?: string;
  weight?: number;
  size?: string;
  cursor?: string;
  margin?: string;
  lineHeight?: string;
  fontfamily?: string;
  alignSelf?: string;
};

type CardContainerProps = {
  color?: string;
  width?: string;
  margin?: string;
  height?: string;
  fontSize?: string;
  fontFamily?: string;
  fontWeight?: string;
  lineHeight?: string;
};

export {
  Rows,
  Text,
  Label,
  Title,
  Heading,
  Updated,
  Wrapper,
  SortText,
  BlogImage,
  GraphCard,
  TableCell,
  Pagination,
  CategoryTab,
  FlexWrapper,
  RootWrapper,
  StyledButton,
  CreateButton,
  AccountsChart,
  CardContainer,
  FlexContainer,
  ImageInputBox,
  RootContainer,
  TableCellText,
  ChartContainer,
  TooltipContainer,
  TableHeaderButton,
  RelativeContainer,
  ModalContentWrapper,
  SettingsFormContainer,
};
