import styled from 'styled-components';
import { colors } from '../../constants';
import { FlexContainer, TableCellText } from '../../constants/styles';
import { getFeedbackImageUrl, TableInput } from '../../utils';

type ActionProps = {
  color: string;
};

const ActionButton = styled.p<ActionProps>`
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.color};
  padding: 0px;
  margin: 0px;
`;

const renderScreenshots = (screenshots: Array<string>) => {
  const imagesComponent = screenshots.map((screenshot) => {
    return <img src={screenshot} alt={'screenshot'} className='screenshot' />;
  });

  return imagesComponent;
};

type ColumnsProps = {
  onDeleteHandle: (id: number) => void;
};

const columns = ({ onDeleteHandle }: ColumnsProps) => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_text: string, record: TableInput) => {
        const name =
          record?.user &&
          record?.user?.firstName + ' ' + record?.user?.lastName;
        return (
          <FlexContainer>
            <TableCellText
              font='14px'
              margin='3px 0px 0px 0px'
              padding='0px'
              style={{ alignSelf: 'center' }}
            >
              {name}
            </TableCellText>
          </FlexContainer>
        );
      },
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
      render: (_text: string, record: TableInput) => {
        const comment = record?.comment;
        return (
          <TableCellText width='430px' font='12px' weight={400}>
            {comment}
          </TableCellText>
        );
      },
    },
    {
      title: 'Screenshot(s)',
      dataIndex: 'screenshots',
      key: 'screenshots',
      render: (_text: string, record: TableInput) => {
        const screenshots =
          record?.feedbackMedia &&
          record?.feedbackMedia.map((feedbackMedia) =>
            getFeedbackImageUrl(
              feedbackMedia.media.file,
              record?.user?.id || ''
            )
          );
        return <div>{renderScreenshots(screenshots || [])}</div>;
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'delete',
      render: (_text: string, record: TableInput) => (
        <TableCellText>
          <ActionButton
            onClick={() => onDeleteHandle(record.id)}
            color={colors.red}
          >
            Delete
          </ActionButton>
        </TableCellText>
      ),
    },
  ];
};

export { columns };
