import { Progress } from 'antd';
import { Upload } from '../../assets';
import styled from 'styled-components';
import { colors } from '../../constants';
import { Label } from '../../constants/styles';
import { FileUploader } from 'react-drag-drop-files';

const Image = styled.img`
  width: 82px;
  height: 65px;
  @media (max-width: 1200px) {
    width: 112px;
  }
  @media (max-width: 600px) {
    width: 40px;
    height: 125px;
  }
`;

const Text = styled.p<{ color?: string }>`
  margin: 0;
  font-weight: 400;
  line-height: 130%;
  word-wrap: break-word;
  font-family: Nexa Book;
  color: ${({ color }) => color};
`;

const CustomFileUploader = styled.div<{
  borderRadius?: string;
  borderStyle?: string;
}>`
  gap: 8px;
  display: flex;
  cursor: pointer;
  overflow: hidden;
  background: white;
  padding: 40px 14px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 1px solid ${colors.light_grey};
  background-color: ${colors.backgroundColor};
  border-radius: ${({ borderRadius }) => borderRadius};
  @media (max-width: 600px) {
    max-width: 327px;
    padding-top: 20px;
    max-height: 290px;
    justify-content: space-between;
  }
`;

const Wrapper = styled.div<{
  gap?: string;
  gapMD?: string;
  alignItems?: string;
  directionMD?: string;
  alignItemsMD?: string;
  flexDirection?: string;
}>`
  display: flex;
  gap: ${({ gap }) => gap};
  justify-content: space-between;
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : 'row'};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  @media screen and (max-width: 830px) {
    gap: ${({ gapMD }) => gapMD};
    align-items: ${({ alignItemsMD }) => alignItemsMD};
    flex-direction: ${({ directionMD }) => directionMD};
  }
`;

interface ImageUploaderProps {
  total?: string;
  loaded?: string;
  file: File | null;
  isError?: boolean;
  uploaded?: boolean;
  fileTypes: string[];
  percentage?: number;
  borderRadius?: string;
  isUploading?: boolean;
  handleChange: (file: File) => void;
}

const ImageUploader = ({
  file,
  total,
  loaded,
  isError,
  uploaded,
  fileTypes,
  percentage,
  isUploading,
  handleChange,
  borderRadius = '12px',
}: ImageUploaderProps) => {
  return (
    <FileUploader name='file' types={fileTypes} handleChange={handleChange}>
      {!file ? (
        <CustomFileUploader borderRadius={borderRadius}>
          <Image src={Upload} alt='close' />
          <Text color={colors.textColor}>Drag XLSX Here</Text>
          <Text color={colors.textgray}>or, click to browse (4MB max)</Text>
        </CustomFileUploader>
      ) : (file && !isUploading) || uploaded ? (
        <Wrapper flexDirection='column' gap='5px' alignItems='flex-start'>
          <Label
            size='13px'
            lineHeight='140%'
            fontfamily='Poppins'
            textcolor={isError ? colors.errorlabel : colors.blueGray}
          >
            {uploaded
              ? 'File Uploaded'
              : isError
              ? 'File could not be uploaded'
              : 'File Selected'}
          </Label>
          <Label
            size='16px'
            lineHeight='140%'
            fontfamily='Poppins'
            textcolor={isError ? colors.errorText : colors.textColor}
          >
            {file.name}
          </Label>
        </Wrapper>
      ) : (
        <Wrapper
          gapMD='20px'
          directionMD='column'
          alignItemsMD='flex-start'
          style={{ margin: '30px 0' }}
        >
          <Text style={{ width: '145px' }}>{file.name}</Text>
          <Wrapper gap='11px'>
            <Label
              size='12px'
              lineHeight='140%'
              alignSelf='center'
              fontfamily='Nexa Regular'
              textcolor={colors.blueGray}
            >
              {`${loaded}kb/${total}mb`}
            </Label>
            <Progress
              size='small'
              percent={percentage}
              style={{ width: 193 }}
            />
          </Wrapper>
        </Wrapper>
      )}
    </FileUploader>
  );
};

export default ImageUploader;
