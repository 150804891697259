import React, { useEffect, useState } from 'react';
import { MESSAGE, colors } from '../../../constants';
import { DeleteIcon, EditIcon, NoCategories } from '../../../assets';
import DeleteCategoryModal from '../DeleteCategoryModal';
import { TableHeaderButton } from '../../../constants/styles';
import EditableCell from '../../modalComponents/EditableCell';
import {
  Form,
  Modal,
  Space,
  Table,
  Typography,
  ConfigProvider,
  Spin,
  message,
} from 'antd';
import {
  Category,
  CategoryItem,
  CategoryWithQuestions,
  ModalWrapperProps,
  getCategoryQuestions,
  handleCategoryTotal,
  itemRender,
} from '../../../utils';
import AddCategoryModal from '../AddCategoryModal';
import { services } from '../../../api/services';
import { useFetch } from '../../../api/provider';
import NoData from '../../NoData';

const ManageCategoriesModal = ({
  onClose,
  open,
  questions,
  categories,
  setRefetchQuestions,
  setRefetchCategories,
}: ModalWrapperProps) => {
  const [form] = Form.useForm();
  const CategoryData: CategoryItem[] = [];
  const [data, setData] = useState(CategoryData);
  const [editingKey, setEditingKey] = useState<string | number>('');
  const [dltCategoryModalOpen, setDltCategoryModalOpen] = useState(false);
  const [addCategoryModalOpen, setAddCategoryModalOpen] = useState(false);
  const [selectedCat, setSelectedCat] =
    useState<Partial<CategoryWithQuestions>>();

  const [, updateLoading, updateCall] = useFetch({
    service: services.updateCategory,
  });
  const [, deleteLoad, deleteCat] = useFetch({
    service: services.deleteCategory,
  });

  useEffect(() => {
    if (categories?.length) {
      // eslint-disable-next-line array-callback-return
      categories?.map((category: Category, index: number) => {
        CategoryData.push({
          key: index,
          used: category?.usedCount,
          category: category.name,
          categoryId: category?.id,
        });
      });
      setData(CategoryData);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  const isEditing = (record: CategoryItem) => record.key === editingKey;
  const cancel = () => setEditingKey('');

  const onDelete = async () => {
    try {
      await deleteCat({ id: selectedCat?.id });
      setRefetchQuestions && setRefetchQuestions(true);
      setRefetchCategories && setRefetchCategories(true);
      setDltCategoryModalOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const save = async (categoryId: React.Key) => {
    try {
      const row = (await form.validateFields()) as CategoryItem;
      if (!row?.category?.trim()) {
        message.error(MESSAGE.CATEGORY_NAME);
        return;
      }
      await updateCall({
        id: categoryId,
        name: row.category?.trim(),
      });
      const updateCat = data?.map((category) => {
        if (category?.categoryId === categoryId) {
          return {
            ...category,
            category: row.category?.trim(),
          };
        }
        return category;
      });
      setData(updateCat);
      setEditingKey('');
      setRefetchCategories && setRefetchCategories(true);
    } catch (errInfo) {
      console.error('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      width: '29.5%',
      key: 'category',
      title: 'Category',
      dataIndex: 'category',
      sorter: (a: { category: string }, b: { category: string }) =>
        a.category.localeCompare(b.category),
      editable: true,
    },
    {
      render: (_: any, record: CategoryItem) => {
        const editable = isEditing(record);
        return (
          editable && (
            <span>
              <div>
                <Typography.Link
                  style={{ marginRight: 26 }}
                  onClick={() => save(record.categoryId)}
                >
                  Save
                </Typography.Link>
                <Typography.Link onClick={cancel}>Cancel</Typography.Link>
              </div>
            </span>
          )
        );
      },
    },
    {
      key: 'used',
      title: 'Used',
      width: '20.32%',
      dataIndex: 'used',
      sorter: (a: { used: number }, b: { used: number }) => a.used - b.used,
    },
    {
      title: 'Actions',
      key: 'operation',
      width: '2.8%',
      render: (_: any, record: Partial<CategoryItem> & { key: React.Key }) => (
        <Space size='middle' style={{ columnGap: '25px' }}>
          <Typography.Link
            disabled={editingKey?.valueOf() !== (0 || '')}
            onClick={() => edit(record)}
          >
            {<EditIcon />}
          </Typography.Link>
          <Typography.Link
            disabled={editingKey?.valueOf() !== (0 || '')}
            onClick={() => deleteCategory(record)}
          >
            {<DeleteIcon />}
          </Typography.Link>
        </Space>
      ),
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: CategoryItem) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const edit = (record: Partial<CategoryItem> & { key: React.Key }) => {
    if (
      record?.category?.toLowerCase() ===
      MESSAGE.GENERAL_CATEGORY?.toLowerCase()
    ) {
      message.error(MESSAGE.NOT_EDITABLE_CATEGORY);
      return;
    }
    form.setFieldsValue({ category: '', ...record });
    setEditingKey(record.key);
  };

  const deleteCategory = (
    record: Partial<CategoryItem> & { key: React.Key }
  ) => {
    if (
      record?.category?.toLowerCase() ===
      MESSAGE.GENERAL_CATEGORY?.toLowerCase()
    ) {
      message.error(MESSAGE.NOT_DELETABLE_CATEGORY);
      return;
    }
    const response = getCategoryQuestions(record?.categoryId!, questions || []);
    setSelectedCat({
      ...record,
      questions: response,
      id: record?.categoryId,
      name: record?.category,
    });
    setDltCategoryModalOpen(true);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            titleFontSize: 28,
            titleColor: '#342A53',
            fontWeightStrong: 800,
            titleLineHeight: 1.108,
            fontFamily: 'Nexa XBold',
          },
        },
      }}
    >
      <Modal
        centered
        open={open}
        okText={'Close'}
        onOk={() => {
          onClose();
          setEditingKey('');
        }}
        onCancel={() => {
          onClose();
          setEditingKey('');
        }}
        title='Manage Categories'
        className='manageCategoriesModal'
        style={{
          fontSize: '',
        }}
        okButtonProps={{
          disabled: false,
          style: {
            backgroundColor: colors.purple,
          },
        }}
        cancelButtonProps={{
          style: {
            display: 'none',
          },
        }}
      >
        <AddCategoryModal
          open={addCategoryModalOpen}
          setRefetchCategories={setRefetchCategories}
          onClose={() => setAddCategoryModalOpen(false)}
        />
        <DeleteCategoryModal
          onDelete={onDelete}
          category={selectedCat!}
          deleteLoad={deleteLoad}
          open={dltCategoryModalOpen}
          onClose={() => setDltCategoryModalOpen(false)}
        />
        <div>
          <TableHeaderButton
            border='none'
            bgColor={colors.purple}
            textColor={colors.white}
            style={{ height: '26px' }}
            padding={'6px 20px 6px 8px'}
            onClick={() => setAddCategoryModalOpen(true)}
            disabled={editingKey?.valueOf() !== (0 || '')}
          >
            {'Create new Category'}
          </TableHeaderButton>
        </div>
        {categories?.length ? (
          <ConfigProvider
            theme={{
              token: {
                padding: 12,
                colorLink: '#6C2078',
              },
              components: {
                Table: {
                  fontSize: 12,
                  lineHeight: 1.35,
                  headerBg: '#EDF1F3',
                  colorText: '#121619',
                  borderColor: '#DDE1E6',
                  fontFamily: 'Nexa Regular',
                  colorTextHeading: '#121619',
                },
              },
            }}
          >
            <Spin spinning={updateLoading}>
              <Form form={form} component={false}>
                <Table
                  bordered
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  sticky={false}
                  showHeader={true}
                  showSorterTooltip
                  columns={mergedColumns}
                  dataSource={data}
                  className='categoryTable'
                  rowClassName={'category-row'}
                  pagination={{
                    showSizeChanger: false,
                    showQuickJumper: false,
                    itemRender: itemRender,
                    showTotal: handleCategoryTotal,
                    position: ['bottomCenter', 'bottomCenter'],
                  }}
                />
              </Form>
            </Spin>
          </ConfigProvider>
        ) : (
          <NoData tableName='categories' icon={NoCategories} />
        )}
      </Modal>
    </ConfigProvider>
  );
};

export default ManageCategoriesModal;
