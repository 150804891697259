import styled from 'styled-components';
import { Popconfirm, Tag } from 'antd';
import { colors, STATUS } from '../../constants';
import { UserDummyImage } from '../../assets';
import { BlogImage, FlexContainer } from '../../constants/styles';
import { TableInput, DataRowInput, getTestimonialImageUrl } from '../../utils';

interface ColumnsProps {
  onEditHandle: (record: TableInput) => void;
  onDeleteHandle: (id: number) => void;
  onCancelHandle: () => void;
  onSaveHandle: (id: number) => void;
  editingKey: number;
}

type ActionProps = {
  color: string;
};

const TableRowItem1 = styled.div<DataRowInput>`
  display: flex;
  flex-direction: column;
  margin-left: ${(props: DataRowInput) => (props.active ? '10px' : '0px')};
`;

const Title = styled.p`
  color: ${colors.black};
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  font-weight: 500;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
`;

const ActionButton = styled.p<ActionProps>`
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.color};
  padding: 0px;
  margin: 0px;
`;

const Columns = ({
  onEditHandle,
  onDeleteHandle,
  onCancelHandle,
  onSaveHandle,
  editingKey,
}: ColumnsProps) => {
  const isEditing = (record: TableInput) => record.id === editingKey;

  const radioFilter = (
    attribute: string,
    options: Array<{ text: string; value: string | boolean }>
  ) => ({
    filters: options,
    onFilter: (value: any, record: any) => record[attribute] === value,
    filterMultiple: false,
  });

  return [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      inputType: 'text',
      editable: false,
      render: (text: string, record: TableInput) => {
        const fullName =
          record.testimonialUser?.firstName +
            ' ' +
            record.testimonialUser?.lastName || ' ';
        return (
          <FlexContainer>
            <Title>{fullName}</Title>
          </FlexContainer>
        );
      },
    },
    {
      title: 'Quote',
      dataIndex: 'quote',
      key: 'quote',
      inputType: 'text',
      editable: false,
      render: (text: string, record: TableInput) => {
        return (
          <FlexContainer>
            <Title>{record.comment || ' '}</Title>
          </FlexContainer>
        );
      },
    },
    {
      title: 'Quote Image',
      dataIndex: 'image',
      key: 'image',
      inputType: 'text',
      editable: false,
      render: (text: string, record: TableInput) => {
        const uuid = record?.testimonialMedia?.testimonialMedia.preview;
        const id = record.testimonialUser?.id || ' ';
        return (
          <FlexContainer>
            <BlogImage
              alt='Testimonial'
              src={uuid ? getTestimonialImageUrl(uuid, id) : UserDummyImage}
            />
          </FlexContainer>
        );
      },
    },
    {
      title: 'Approval Status',
      dataIndex: 'status',
      key: 'status',
      editable: true,
      inputType: 'text',
      ...radioFilter('status', [
        { text: 'Pending', value: STATUS.PENDING },
        { text: 'Accepted', value: STATUS.ACCEPTED },
        { text: 'Rejected', value: STATUS.REJECTED },
      ]),
      render: (text: string, record: TableInput) => {
        return (
          <TableRowItem1 active={0}>
            {record.status === STATUS.PENDING && (
              <Tag color={'blue'}>
                <Title>🚧 Pending</Title>
              </Tag>
            )}
            {record.status === STATUS.ACCEPTED && (
              <Tag color={'green'}>
                <Title>✅ Accepted</Title>
              </Tag>
            )}
            {record.status === STATUS.REJECTED && (
              <Tag color={'red'}>
                <Title>❌ Rejected</Title>
              </Tag>
            )}
          </TableRowItem1>
        );
      },
    },

    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      editable: true,
      sorter: (a: any, b: any) => {
        return a.priority - b.priority;
      },
      render: (text: string, record: TableInput) => {
        return (
          <TableRowItem1 active={0}>
            <Title>{record.priority}</Title>
          </TableRowItem1>
        );
      },
    },

    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      render: (text: string, record: TableInput) => {
        const editable = isEditing(record);
        return (
          <ButtonWrapper>
            {editable ? (
              <ButtonWrapper>
                <ActionButton
                  style={{ marginRight: '10px' }}
                  color={colors.appColor}
                  onClick={() => onSaveHandle(record.id)}
                >
                  Save
                </ActionButton>
                <Popconfirm title='Sure to cancel?' onConfirm={onCancelHandle}>
                  <ActionButton color={colors.red}>Cancel</ActionButton>
                </Popconfirm>
              </ButtonWrapper>
            ) : (
              <>
                <ActionButton
                  color={colors.appColor}
                  style={{ marginRight: '10px' }}
                  onClick={() => onEditHandle(record)}
                >
                  Edit
                </ActionButton>
                <ActionButton color={colors.red}>
                  <Popconfirm
                    style={{ padding: '20px' }}
                    placement='left'
                    title='Sure to delete?'
                    onConfirm={() => onDeleteHandle(record.id)}
                  >
                    Delete
                  </Popconfirm>
                </ActionButton>
              </>
            )}
          </ButtonWrapper>
        );
      },
    },
  ];
};

export { Columns };
