import { PlusIcon } from '../../../assets';
import { MESSAGE, colors } from '../../../constants';
import { ConfigProvider, Input, Modal, Select, message } from 'antd';
import {
  Label,
  StyledButton,
  ModalContentWrapper,
} from '../../../constants/styles';
import { useEffect, useState } from 'react';
import { services } from '../../../api/services';
import { useFetch } from '../../../api/provider';
import { Category, DataType } from '../../../utils';
import AddCategory from '../../AddCategory';
const { TextArea } = Input;

export interface ModalWrapperProps {
  open: boolean;
  onClose: () => void;
  categories: Category[];
  question: Partial<DataType>;
  setRefetchQuestions: React.Dispatch<React.SetStateAction<boolean>>;
  setRefetchCategories?: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditQuestionModal = ({
  open,
  onClose,
  question,
  categories,
  setRefetchQuestions,
  setRefetchCategories,
}: ModalWrapperProps) => {
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [addCategoryClick, setAddCategoryClick] = useState(false);

  const [questionChange, setQuestionChange] =
    useState<Partial<DataType>>(question);
  const [categoryChange, setCategoryChange] = useState({
    label: question?.category,
    value: question?.categoryId,
  });
  const [, , updateCall] = useFetch({
    service: services.updateQuestion,
  });

  useEffect(() => {
    setQuestionChange(question);
    setCategoryChange({
      label: question?.category,
      value: question?.categoryId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onSave = async () => {
    try {
      await updateCall({
        id: questionChange?.questionId,
        content: questionChange?.question,
        categoryId: categoryChange?.value,
      });
      setRefetchQuestions((pre) => !pre);
      onClose();
    } catch (error) {
      message.error(error?.toString() || MESSAGE.REQUEST_FAILED);
    }
  };
  const onQuestionChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.target.value !== question?.question) {
      setIsDataChanged(true);
      setQuestionChange({
        ...questionChange,
        question: e?.target?.value,
      });
    } else setIsDataChanged(false);
  };

  const handleChange = (value: string, option: any) => {
    if (option?.label !== question?.category) {
      setIsDataChanged(true);
      setCategoryChange({
        label: value,
        value: option?.value,
      });
    } else setIsDataChanged(false);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            titleFontSize: 28,
            titleLineHeight: 1.108,
            fontFamily: 'Nexa XBold',
            fontWeightStrong: 800,
            titleColor: '#342A53',
          },
        },
      }}
    >
      <Modal
        centered
        open={open}
        onOk={onSave}
        onCancel={() => {
          setAddCategoryClick(false);
          setIsDataChanged(false);
          onClose();
        }}
        okText={'Save'}
        title='Edit Question'
        className='newStyle'
        style={{
          fontSize: '',
        }}
        okButtonProps={{
          disabled: !isDataChanged,
          style: {
            backgroundColor: colors.purple,
          },
        }}
        cancelButtonProps={{
          className: 'cancelBtn',
        }}
      >
        <ModalContentWrapper
          gap='19px'
          margin={'0 0 20px'}
          flexDirection='column'
        >
          <div>
            <Label
              size='13px'
              margin='0 0 8px'
              lineHeight='140%'
              textcolor='#79818E'
              fontfamily='Poppins'
            >
              Edit Question
            </Label>
            <TextArea
              showCount
              maxLength={140}
              value={questionChange?.question}
              className='questionInput'
              onChange={onQuestionChange}
              placeholder='Keep it short and clear'
              style={{
                height: 91,
                maxWidth: 510,
              }}
            />
          </div>
          <div>
            <Select
              value={categoryChange?.label}
              className='categorySelect'
              placeholder='Choose Category'
              style={{ width: 188, margin: '19px 0 19px' }}
              options={categories?.map((category) => {
                return {
                  label: category?.name,
                  value: category.id,
                };
              })}
              onChange={handleChange}
            />
            {!addCategoryClick ? (
              <StyledButton
                margin='0'
                border='none'
                onClick={() => setAddCategoryClick(true)}
              >
                <img
                  src={PlusIcon}
                  alt='add'
                  style={{ verticalAlign: 'sub' }}
                />
                {'Add New Category'}
              </StyledButton>
            ) : (
              <AddCategory
                setCategoryClick={setAddCategoryClick}
                setRefetchCategories={setRefetchCategories}
              />
            )}
          </div>
        </ModalContentWrapper>
      </Modal>
    </ConfigProvider>
  );
};

export default EditQuestionModal;
