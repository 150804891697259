import moment from 'moment';
import styled from 'styled-components';
import { colors } from '../../../constants';
import { ConfigProvider, Modal } from 'antd';
import { DataType, GetQuestionStatById } from '../../../utils';
import { Label, ModalContentWrapper } from '../../../constants/styles';

const WeightedText = styled.span`
  font-weight: 900;
`;

export interface ModalWrapperProps {
  open: boolean;
  onClose: () => void;
  child?: React.ReactElement;
  questionStats: GetQuestionStatById;
  selectedQuestion: Partial<DataType>;
}

const StatsModal = ({
  onClose,
  open,
  selectedQuestion,
  questionStats,
}: ModalWrapperProps) => {
  const formatString = 'MMM DD, YYYY h:mm A';
  const timeSince = (date: Date): string => {
    const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);

    const years = Math.floor(seconds / 31536000);
    const months = Math.floor((seconds % 31536000) / 2592000);
    const days = Math.floor(((seconds % 31536000) % 2592000) / 86400);
    const hours = Math.floor((((seconds % 31536000) % 2592000) % 86400) / 3600);
    const minutes = Math.floor(
      ((((seconds % 31536000) % 2592000) % 86400) % 3600) / 60
    );

    const getTimeString = (value: number, name: string) => {
      return value > 1 ? `${value} ${name}s` : `${value} ${name}`;
    };

    if (years > 0) {
      return (
        getTimeString(years, 'year') +
        (months > 0 ? ` and ${getTimeString(months, 'month')}` : '') +
        ' ago'
      );
    } else if (months > 0) {
      return (
        getTimeString(months, 'month') +
        (days > 0 ? ` and ${getTimeString(days, 'day')}` : '') +
        ' ago'
      );
    } else if (days > 0) {
      return (
        getTimeString(days, 'day') +
        (hours > 0 ? ` and ${getTimeString(hours, 'hour')}` : '') +
        ' ago'
      );
    } else if (hours > 0) {
      return (
        getTimeString(hours, 'hour') +
        (minutes > 0 ? ` and ${getTimeString(minutes, 'minute')}` : '') +
        ' ago'
      );
    } else if (minutes > 0) {
      return getTimeString(minutes, 'minute') + ' ago';
    } else {
      return 'Just now';
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            titleFontSize: 28,
            titleLineHeight: 1.108,
            fontFamily: 'Nexa XBold',
            fontWeightStrong: 800,
            titleColor: '#342A53',
          },
        },
      }}
    >
      <Modal
        centered
        open={open}
        onOk={onClose}
        onCancel={onClose}
        title='Question Stats'
        className='newStyle'
        okText={'Close'}
        style={{
          fontSize: '',
        }}
        okButtonProps={{
          style: {
            backgroundColor: colors.purple,
          },
        }}
        cancelButtonProps={{
          style: {
            display: 'none',
          },
        }}
      >
        <ModalContentWrapper flexDirection='column' gap='0px'>
          <Label
            size='16px'
            margin='0 0 2px'
            lineHeight='140%'
            textcolor='#79818E'
            fontfamily='Poppins'
          >
            Question
          </Label>
          <Label
            size='22px'
            margin='0 0 19px'
            lineHeight='140%'
            textcolor='#342A53'
            fontfamily='Poppins'
          >
            {selectedQuestion?.question}
          </Label>
          <Label
            size='16px'
            margin='0 0 2px'
            lineHeight='140%'
            textcolor='#79818E'
            fontfamily='Poppins'
          >
            Category
          </Label>
          <Label
            size='22px'
            margin='0 0 19px'
            lineHeight='140%'
            textcolor='#342A53'
            fontfamily='Poppins'
          >
            {selectedQuestion?.category}
          </Label>
          <Label
            size='16px'
            margin='0 0 2px'
            lineHeight='140%'
            textcolor='#79818E'
            fontfamily='Poppins'
          >
            Times used
          </Label>
          <Label
            size='22px'
            margin='0 0 19px'
            lineHeight='140%'
            textcolor='#342A53'
            fontfamily='Poppins'
          >
            <WeightedText>
              {questionStats ? questionStats?.count : 'Not used'}
            </WeightedText>
          </Label>
          <Label
            size='16px'
            margin='0 0 2px'
            lineHeight='140%'
            textcolor='#79818E'
            fontfamily='Poppins'
          >
            Created
          </Label>
          <Label
            size='22px'
            margin='0 0 19px'
            lineHeight='140%'
            textcolor='#342A53'
            fontfamily='Poppins'
          >
            {timeSince(
              new Date(
                moment(selectedQuestion?.createdAt, formatString).toString()
              )
            )
              .split(/(\d+)/)
              .map((part, index) => {
                return index % 2 === 0 ? part : <strong>{part}</strong>;
              })}
          </Label>
          <Label
            size='16px'
            margin='0 0 2px'
            lineHeight='140%'
            textcolor='#79818E'
            fontfamily='Poppins'
          >
            Gender
          </Label>
          <Label
            size='22px'
            margin='0 0 19px'
            lineHeight='140%'
            textcolor='#342A53'
            fontfamily='Poppins'
          >
            {questionStats ? (
              <div>
                {questionStats?.genderUsage?.male !== '0.00%' && (
                  <>
                    <WeightedText>{`${questionStats?.genderUsage?.male} `}</WeightedText>
                    {'Male '}
                  </>
                )}
                {questionStats?.genderUsage?.female !== '0.00%' && (
                  <>
                    <WeightedText>{`${questionStats?.genderUsage?.female} `}</WeightedText>
                    {'Female'}
                  </>
                )}
              </div>
            ) : (
              <WeightedText>Not specified</WeightedText>
            )}
          </Label>
          <Label
            size='16px'
            margin='0 0 2px'
            lineHeight='140%'
            textcolor='#79818E'
            fontfamily='Poppins'
          >
            Age Groups
          </Label>
          <Label
            margin='0'
            size='22px'
            lineHeight='140%'
            textcolor='#342A53'
            fontfamily='Poppins'
          >
            {questionStats ? (
              <div>
                {questionStats?.ageUsage?.belowThirty !== '0.00%' && (
                  <>
                    <WeightedText>{`${questionStats?.ageUsage?.belowThirty} `}</WeightedText>
                    {'17-30 '}
                  </>
                )}
                {questionStats?.ageUsage?.belowForty !== '0.00%' && (
                  <>
                    <WeightedText>{`${questionStats?.ageUsage?.belowForty} `}</WeightedText>
                    {'30-40 '}
                  </>
                )}
                {questionStats?.ageUsage?.belowFifty !== '0.00%' && (
                  <>
                    <WeightedText>{`${questionStats?.ageUsage?.belowFifty} `}</WeightedText>
                    {'40-50 '}
                  </>
                )}
                {questionStats?.ageUsage?.fiftyPlus !== '0.00%' && (
                  <>
                    <WeightedText>{`${questionStats?.ageUsage?.fiftyPlus} `}</WeightedText>
                    {'Above 50 '}
                  </>
                )}
              </div>
            ) : (
              <WeightedText>Not specified</WeightedText>
            )}
          </Label>
        </ModalContentWrapper>
      </Modal>
    </ConfigProvider>
  );
};

export default StatsModal;
