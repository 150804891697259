import styled from 'styled-components';
import { Label } from '../../constants/styles';

const Wrapper = styled.div`
  gap: 20px;
  display: flex;
  padding: 40px;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 680px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Image = styled.img`
  width: 84px;
  height: 84px;
`;

interface EmptyTableProps {
  icon: string;
  tableName: string;
}

const NoData = ({ tableName, icon }: EmptyTableProps) => {
  return (
    <Wrapper>
      <Image src={icon} alt='no data' />
      <Label
        size='24px'
        weight={800}
        lineHeight='110%'
        textcolor='#A9B4BC'
        fontfamily='Nexa XBold'
      >
        {`You have no ${tableName}`}
      </Label>
    </Wrapper>
  );
};

export default NoData;
