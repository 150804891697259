import React from 'react';

interface AppContextInterface {
  setIsCollapsed: Function;
  setIsLoggedIn: (auth: boolean) => void;
}

const AppContext = React.createContext<AppContextInterface | null>(null);

export const AppContextProvider = AppContext.Provider;

export const AppContextConsumer = AppContext.Consumer;

export default AppContext;
