import { columns } from './columns';
import { EDIT_JOB, colors } from '../../constants';
import { services } from '../../api/services';
import { useFetch } from '../../api/provider';
import { TableInput } from '../../utils';
import { useHistory } from 'react-router-dom';
import TablePageHeader from '../../components/tablePageHeader';
import { Spin, Table } from 'antd';
import { CreateButton, Heading } from '../../constants/styles';

const Jobs = () => {
  const history = useHistory();

  const [jobsList, loading, getAllJobsCall] = useFetch({
    service: services.getAllJobs,
    callOnMount: true,
    method: 'GET',
  });

  const [, deleteLoading, deleteJobCall] = useFetch({
    service: services.deleteJob,
  });

  const onEditHandle = (id: number) => {
    history.push(`/${EDIT_JOB}/${id}`);
  };

  const onDeleteHandle = async (id: number) => {
    await deleteJobCall({ id });
    getAllJobsCall();
  };

  return (
    <Spin spinning={loading || deleteLoading}>
      <Heading
        margin='20px'
        fontSize='28px'
        fontWeight='800'
        lineHeight='110%'
        fontFamily='Nexa XBold'
        color={colors.textColor}
      >
        {'Jobs'}
      </Heading>
      <CreateButton
        onClick={() => {
          history.push('/create-job');
        }}
      >
        Post a new Job
      </CreateButton>
      <TablePageHeader>
        <Table
          dataSource={jobsList?.data || []}
          rowKey='id'
          columns={columns({
            onDeleteHandle,
            onEditHandle,
          })}
          style={{ flex: 1, paddingRight: 10 }}
          scroll={{ x: 1000 }}
          pagination={false}
          onRow={(record: TableInput) => {
            return {
              onClick: () => {},
            };
          }}
        />
      </TablePageHeader>
    </Spin>
  );
};
export default Jobs;
