import { MESSAGE, colors } from '../../../constants';
import { services } from '../../../api/services';
import { useFetch } from '../../../api/provider';
import { ModalWrapperProps } from '../../../utils';
import { ConfigProvider, Modal, Input, Spin, message } from 'antd';
import { Label, ModalContentWrapper } from '../../../constants/styles';
import { useState } from 'react';

const { TextArea } = Input;

const AddCategoryModal = ({
  onClose,
  open,
  setRefetchCategories,
}: ModalWrapperProps) => {
  const [name, setName] = useState('');
  const [, addCategoryLoading, addCategoryCall] = useFetch({
    service: services.addCategory,
  });
  const onAddCategory = async () => {
    try {
      await addCategoryCall({ name });
      setName('');
      onClose();
      setRefetchCategories && setRefetchCategories(true);
    } catch (error) {
      message.error(error?.toString() || MESSAGE.REQUEST_FAILED);
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            titleFontSize: 28,
            fontWeightStrong: 800,
            titleColor: '#342A53',
            titleLineHeight: 1.108,
            fontFamily: 'Nexa XBold',
          },
        },
      }}
    >
      <Modal
        centered
        open={open}
        onOk={onAddCategory}
        onCancel={() => {
          setName('');
          onClose();
        }}
        title='Add Category'
        className='newStyle'
        okText={'Add Category'}
        okButtonProps={{
          disabled: !name?.trim()?.length,
          style: {
            backgroundColor: colors.purple,
          },
        }}
        cancelButtonProps={{
          className:'cancelBtn'
        }}
      >
        <Spin spinning={addCategoryLoading}>
          <ModalContentWrapper
            gap='19px'
            margin={'0 0 20px'}
            flexDirection='column'
          >
            <div>
              <Label
                size='13px'
                margin='0 0 8px'
                lineHeight='140%'
                textcolor='#79818E'
                fontfamily='Poppins'
              >
                Add Category
              </Label>
              <TextArea
                showCount
                value={name}
                maxLength={140}
                onChange={(e) => setName(e.target.value)}
                className='questionInput'
                placeholder='Keep it short and clear'
                style={{
                  height: 91,
                  maxWidth: 510,
                }}
              />
            </div>
          </ModalContentWrapper>
        </Spin>
      </Modal>
    </ConfigProvider>
  );
};

export default AddCategoryModal;
