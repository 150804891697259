import { Columns } from './columns';
import { MESSAGE, STATUS, colors } from '../../constants';
import { services } from '../../api/services';
import { useFetch } from '../../api/provider';
import { TableInput } from '../../utils';
import TablePageHeader from '../../components/tablePageHeader';
import CustomPagination from '../../components/customPagination';
import { useEffect, useState } from 'react';
import { Spin, Table, Form, InputNumber, Select, message } from 'antd';
import { Heading } from '../../constants/styles';

const { Option } = Select;

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text';
  record: TableInput;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  index,
  title,
  record,
  editing,
  children,
  dataIndex,
  inputType,
  ...restProps
}) => {
  const inputNode =
    inputType === 'number' ? (
      <InputNumber />
    ) : (
      <Select placeholder='Update status'>
        <Option value={STATUS.ACCEPTED}>Accept</Option>
        <Option value={STATUS.REJECTED}>Reject</Option>
        <Option value={STATUS.PENDING}>pending</Option>
      </Select>
    );

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const App: React.FC = () => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState(-1);
  const [page, setPage] = useState<{ pageSize: number; pageNo: number }>({
    pageNo: 1,
    pageSize: 6,
  });
  const [total, setTotal] = useState(0);

  const [testimonialList, getLoading, getAllCall] = useFetch({
    service: services.getAllTestimonials,
    method: 'GET',
  });
  const [, deleteLoading, deleteCall] = useFetch({
    service: services.deleteTestimonial,
  });
  const [, updateLoading, updateCall] = useFetch({
    service: services.updateTestimonial,
  });

  const [data, setData] = useState(testimonialList?.data?.testimonials || []);

  useEffect(() => {
    getAllCall({
      pageNo: page.pageNo,
      pageSize: page.pageSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (testimonialList?.data?.meta?.count)
      setTotal(testimonialList.data.meta.count);

    if (testimonialList?.data?.testimonials?.length > 0) {
      setData(testimonialList?.data?.testimonials);
    }
  }, [testimonialList]);

  const onDeleteHandle = async (id: number) => {
    const isDeleted = await deleteCall({ id });
    if (!isDeleted.data) {
      message.error(MESSAGE.REQUEST_FAILED);
      return;
    }
    const newData = [...data];
    const index = newData.findIndex((item) => id === item.id);
    if (index > -1) {
      newData.splice(index, 1);
      setData(newData);
    }
    message.success(MESSAGE.DELETE_SUCCESS);
  };

  const isEditing = (record: TableInput) => record.id === editingKey;

  const onEditHandle = (record: TableInput) => {
    form.setFieldsValue({ priority: 0, status: '', ...record });
    setEditingKey(record.id || 0);
  };

  const onCancelHandle = () => {
    setEditingKey(-1);
  };

  const onSaveHandle = async (key: number) => {
    try {
      const row = (await form.validateFields()) as TableInput;
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.id);
      const item = newData[index];
      const updateRes = await updateCall({ ...row, id: item.id });
      if (updateRes?.status !== MESSAGE.SUCCESS_RESPONSE) {
        message.error(updateRes?.message);
        return;
      }
      if (index > -1) {
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey(-1);
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey(-1);
      }
      message.success(MESSAGE.TESTIMONIAL_UPDATED);
    } catch {}
  };

  const mergedColumns = Columns({
    onDeleteHandle,
    onEditHandle,
    onCancelHandle,
    onSaveHandle,
    editingKey,
  }).map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: TableInput) => ({
        record,
        inputType: col.dataIndex === 'priority' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Heading
        margin='20px'
        fontSize='28px'
        fontWeight='800'
        lineHeight='110%'
        fontFamily='Nexa XBold'
        color={colors.textColor}
      >
        {'Testimonials'}
      </Heading>
      <Spin spinning={getLoading || deleteLoading || updateLoading}>
        <TablePageHeader>
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            rowKey='id'
            style={{ flex: 1, paddingRight: 10 }}
            dataSource={data || []}
            scroll={{ x: 1000 }}
            columns={mergedColumns}
            rowClassName='editable-row'
            pagination={false}
          />
          <CustomPagination page={page} setPage={setPage} total={total} />
        </TablePageHeader>
      </Spin>
    </Form>
  );
};

export default App;
