import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { colors } from '../../constants';
import { Select } from 'antd';
import { useWindowSize } from '../../utils';
import SortComp from '../sortComp';

const RootContainer = styled.div`
  .ant-select-selection-item {
    color: ${colors.lightGrey};
  }
`;

const ContentInnerContainerBottom = styled.div`
  display: flex;
  background-color: ${colors.white};
  margin: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  flex-direction: column;
  flex: 9;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: normal;
`;

const BasicInformation = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: ${colors.blueGray};
  padding: 0px;
  margin: 0px;
`;

const TableTopMenu = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: 10px;
  padding-left: 10px;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  background-color: ${colors.grey};
`;

const Button = styled.div`
  width: 90px;
  height: 32px;
  background: linear-gradient(178.18deg, #fd749b -13.56%, #281ac8 158.3%);
  border-radius: 6px;
  text-align: center;
  margin-left: 10px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

type TableProps = {
  children: ReactNode;
  isSettingsPage?: boolean;
  onSort?: Function;
};

const TablePageHeader = ({
  children,
  onSort,
  isSettingsPage = false,
}: TableProps) => {
  const [width] = useWindowSize();
  const Dropdowns = () => (
    <>
      <Wrapper>
        <Select value='Bulk Action'></Select>
        <Button>
          <p style={{ marginTop: '4px', color: colors.white }}>Apply</p>
        </Button>
      </Wrapper>
      <Wrapper>
        <Select value='Change role to'></Select>
        <Button>
          <p style={{ marginTop: '4px', color: colors.white }}>Change</p>
        </Button>
      </Wrapper>
    </>
  );
  return (
    <RootContainer>
      <ContentInnerContainerBottom>
        <TableTopMenu>
          <BasicInformation>Basic Information</BasicInformation>
          {isSettingsPage && width > 769 && <Dropdowns />}
          <TableTopMenu>
            <TableTopMenu>
              <SortComp onIconClick={onSort} />
            </TableTopMenu>
          </TableTopMenu>
        </TableTopMenu>
        {children}
      </ContentInnerContainerBottom>
    </RootContainer>
  );
};

export default TablePageHeader;
