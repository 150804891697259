import styled from "styled-components";
import { Menu } from "antd";
import AppContext from "../../appContext";
import { colors } from "../../constants";
import { RouterProps } from "react-router-dom";
import { getImageUrl, useWindowSize } from "../../utils";
import { useContext } from "react";
import {
  deleteSession,
  deleteToken,
  getSession,
} from "../../helpers/SessionManagement";

const ImageContainer = styled.div`
  display: flex;
  .ant-menu-submenu-title {
    height: 100px !important;
    padding-left: 10px !important;
    display: flex;
  }
  .ant-menu-item {
    padding-left: 24px !important;
  }
  .ant-menu-item-group-title {
    padding: 0 !important;
  }
  img {
    width: 5.5rem;
    height: 5.5rem;
    object-fit: cover;
    border-radius: 44px;
    margin: 5px 5px 5px 10px;
  }
`;

const Text = styled.div`
  margin-top: 40px;
  font-size: 16px;
  font-weight: 700;
  color: ${colors.black};
`;

const MobileMenu = (props: RouterProps) => {
  const setIsCollapsed = useContext(AppContext)?.setIsCollapsed;
  const setLoggedIn = useContext(AppContext)?.setIsLoggedIn;
  const [width] = useWindowSize();
  const user = getSession()?.user;
  const onClick = (route: string) => {
    setIsCollapsed && setIsCollapsed(true);
    props.history.push(`/${route}`);
  };
  const logout = () => {
    deleteSession();
    deleteToken();
    setLoggedIn && setLoggedIn(false);
    onClick("");
  };
  return (
    <Menu mode="inline">
      {width && width < 712 && (
        <ImageContainer>
          <img
            alt="user"
            src={getImageUrl(user?.id, user?.avatarMedia?.preview)}
          />
          <Text>{user?.firstName + " " + user?.lastName}</Text>
        </ImageContainer>
      )}
      <Menu.Item
        style={{ background: "none" }}
        onClick={() => onClick("dashboard")}
      >
        Dashboard
      </Menu.Item>
      <Menu.Item
        style={{ background: "none" }}
        onClick={() => onClick("users")}
      >
        Users
      </Menu.Item>
      <Menu.Item
        style={{ background: "none" }}
        onClick={() => onClick("questions")}
      >
        Questions
      </Menu.Item>
      <Menu.Item
        style={{ background: "none" }}
        onClick={() => onClick("messages")}
      >
        Notificatios
      </Menu.Item>
      <Menu.Item
        style={{ background: "none" }}
        onClick={() => onClick("blogs")}
      >
        Blogs
      </Menu.Item>
      <Menu.Item
        style={{ background: "none" }}
        onClick={() => onClick("jobs")}
      >
        Jobs
      </Menu.Item>
      <Menu.Item
        style={{ background: "none" }}
        onClick={() => onClick("faqs")}
      >
        FAQs
      </Menu.Item>
      <Menu.Item
        style={{ background: "none" }}
        onClick={() => onClick("donations")}
      >
        Donations
      </Menu.Item>
      <Menu.Item
        style={{ background: "none" }}
        onClick={() => onClick("testimonials")}
      >
        Testimonials
      </Menu.Item>
      <Menu.Item
        style={{ background: "none" }}
        onClick={() => onClick("feedback")}
      >
        Feedback
      </Menu.Item>
      <Menu.Item
        style={{ background: "none" }}
        onClick={() => onClick("reported-user")}
      >
        Reported Users
      </Menu.Item>
      <Menu.Item
        style={{ background: "none" }}
        onClick={() => onClick("reported-post")}
      >
        Reported Posts
      </Menu.Item>
      <Menu.Item
        style={{ background: "none" }}
        onClick={() => onClick("settings")}
      >
        Settings
      </Menu.Item>
      <Menu.Item style={{ background: "none" }} onClick={logout}>
        Log out
      </Menu.Item>
    </Menu>
  );
};

export default MobileMenu;
