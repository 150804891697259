import {
  FAQs,
  Jobs,
  Blogs,
  Users,
  Logout,
  Feedback,
  Settings,
  Dashboard,
  Donations,
  Questions,
  Testimonials,
  Notifications,
  ReportedPosts,
  ReportedUsers,
} from '../assets';

const ICONS_DATA = [
  {
    id: 1,
    icon: Dashboard,
    title: 'Dashboard',
  },
  {
    id: 2,
    icon: Users,
    title: 'Users',
  },
  {
    id: 3,
    icon: Questions,
    title: 'Questions',
  },
  {
    id: 4,
    icon: Notifications,
    title: 'Notifications',
  },
  {
    id: 5,
    icon: Blogs,
    title: 'Blogs',
  },
  {
    id: 6,
    icon: Jobs,
    title: 'Jobs',
  },
  {
    id: 7,
    icon: FAQs,
    title: 'FAQs',
  },
  {
    id: 8,
    icon: Donations,
    title: 'Donations',
  },
  {
    id: 9,
    icon: Testimonials,
    title: 'Testimonials',
  },
  {
    id: 10,
    icon: Feedback,
    title: 'Feedback',
  },
  {
    id: 11,
    icon: ReportedUsers,
    title: 'Report Users',
  },
  {
    id: 12,
    icon: ReportedPosts,
    title: 'Report Posts',
  },
];

const SETTINGS = [
  {
    id: 1,
    icon: Settings,
    title: 'Settings',
  },
  {
    id: 2,
    icon: Logout,
    title: 'Logout',
  },
];

const CATEGORIES_CHART_DATA = [
  {
    name: 'Page A',
    uv: 100,
    pv: 240,
    amt: 240,
  },
  {
    name: 'Page B',
    uv: 200,
    pv: 240,
    amt: 240,
  },
  {
    name: 'Page C',
    uv: 100,
    pv: 240,
    amt: 240,
  },
  {
    name: 'Page D',
    uv: 300,
    pv: 240,
    amt: 240,
  },
  {
    name: 'Page E',
    uv: 300,
    pv: 240,
    amt: 240,
  },
  {
    name: 'Page F',
    uv: 500,
    pv: 240,
    amt: 240,
  },
  {
    name: 'Page G',
    uv: 480,
    pv: 240,
    amt: 240,
  },
  {
    name: 'Page H',
    uv: 430,
    pv: 240,
    amt: 240,
  },
  {
    name: 'Page I',
    uv: 600,
    pv: 240,
    amt: 240,
  },
  {
    name: 'Page J',
    uv: 600,
    pv: 240,
    amt: 240,
  },
  {
    name: 'Page K',
    uv: 630,
    pv: 240,
    amt: 240,
  },
  {
    name: 'Page L',
    uv: 250,
    pv: 240,
    amt: 240,
  },
  {
    name: 'Page M',
    uv: 300,
    pv: 240,
    amt: 240,
  },
];

const QUESTIONS_CHART_DATA = [
  { name: 'Group A', value: 666 },
  { name: 'Group B', value: 333 },
];

export { SETTINGS, ICONS_DATA, QUESTIONS_CHART_DATA, CATEGORIES_CHART_DATA };
