import { Route, RouteProps, Redirect } from 'react-router-dom';
import { isSession } from './helpers/SessionManagement';

export type PublicRouteProps = RouteProps & {
  restricted: boolean;
  key: string;
};

export type PrivateRouteProps = RouteProps & {
  key: string;
};

export const PublicRoute = ({ restricted, ...props }: PublicRouteProps) => {
  return (
    <>
      {restricted ? (
        <Redirect to={{ pathname: '/dashboard' }} />
      ) : (
        <Route {...props} />
      )}
    </>
  );
};

export const PrivateRoute = ({ ...props }: PrivateRouteProps) => {
  return (
    <>
      {isSession() ? <Route {...props} /> : <Redirect to={{ pathname: '/' }} />}
    </>
  );
};
