import React from 'react';
import { FlexContainer, SortText } from '../../constants/styles';
import { SortIcon } from '../../assets';

type SortCompProps = {
  marginBottom?: number;
  onIconClick?: Function;
};

const SortComp = ({ marginBottom, onIconClick }: SortCompProps) => {
  return (
    <FlexContainer>
      <img
        src={SortIcon}
        alt='sort'
        style={{ objectFit: 'contain', marginBottom: marginBottom }}
        onClick={() => onIconClick && onIconClick()}
      />
      <SortText>Sort</SortText>
    </FlexContainer>
  );
};

export default SortComp;
