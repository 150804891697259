import styled from 'styled-components';
import { MenuDots } from '../../assets';
import CustomFilterDropDown from '../../components/customFilterDropDown';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { colors, SUBSCRIPTION_PACKAGE } from '../../constants';
import { TableCell, FlexContainer, Text } from '../../constants/styles';
import {
  TableInput,
  getImageUrl,
  DataRowInput,
  PageValProps,
  onFilterChange,
  getFormattedDate,
} from '../../utils';

const TableRowItem1 = styled.div<DataRowInput>`
  display: flex;
  flex-direction: column;
  margin-left: ${(props: DataRowInput) => (props.active ? '10px' : '0px')};
`;

const Title = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: ${colors.black};
  padding: 0px;
  margin: 0px;
`;

const Gender = styled.p`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 20px;
`;

const Description = styled.p`
  font-weight: 600;
  color: ${colors.lightGrey};
  padding: 0px;
`;

const UserImage = styled.img`
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 25px;
`;

const TypeTag = styled.div<DataRowInput>`
  background-color: ${(props: DataRowInput) =>
    props.active === 0
      ? colors.red
      : props.active === 1
      ? colors.yellow
      : colors.green};
  padding: 5px 15px 5px 15px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 11px;
  width: auto;
  height: 24px;
`;

const columns = (
  setSubscriptionPackage: (filter: string) => void,
  setPage: (page: PageValProps) => void
) => {
  const year = new Date().getFullYear();

  return [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_text: string, record: TableInput) => (
        <FlexContainer>
          <UserImage
            src={getImageUrl(
              record.id.toString(),
              record.avatarMedia?.preview || ''
            )}
          />
          <TableRowItem1 active={1}>
            <Title>
              {record.firstName && record.lastName
                ? record.firstName + ' ' + record.lastName
                : record.name}
            </Title>
            <Description>{'Updated 1 day ago'}</Description>
          </TableRowItem1>
        </FlexContainer>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text: string) => (
        <TableCell textcolor={colors.purple}>{text}</TableCell>
      ),
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'Country',
      render: (text: string, record: TableInput) => (
        <TableRowItem1 active={0}>
          <Title>{text}</Title>
          <Description>{record.city || 'N/A'}</Description>
        </TableRowItem1>
      ),
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'Gender',
      render: (text: string, record: TableInput) => (
        <Gender>{text || 'N/A'}</Gender>
      ),
    },
    {
      title: 'Age',
      dataIndex: 'birthday',
      key: 'birthday',
      render: (text: string, record: TableInput) => {
        let date,
          age = -1;
        if (record.birthday) {
          let dob = new Date(record.birthday);
          age = year - dob.getFullYear();
          date = getFormattedDate(dob);
        }
        return (
          <TableRowItem1 active={0}>
            <Title>{age > -1 ? `${age} Years` : 'N/A'}</Title>
            <Description>{date}</Description>
          </TableRowItem1>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'productName',
      key: 'productName',
      filters: [
        { text: 'Free', value: SUBSCRIPTION_PACKAGE.FREE },
        { text: 'Yearly', value: SUBSCRIPTION_PACKAGE.YEARLY },
        { text: 'Monthly', value: SUBSCRIPTION_PACKAGE.MONTHLY },
      ],
      onFilter: (value: any, record: any) => {
        if (value === SUBSCRIPTION_PACKAGE.FREE)
          return record.userPlan.length === 0;
        return record.userPlan[0]?.packageSize === value;
      },
      filterDropdown: (props: FilterDropdownProps) => {
        return (
          <CustomFilterDropDown
            filters={props.filters || []}
            onOkay={(filterVal) =>
              onFilterChange({
                filterValue: filterVal,
                setFilteredValue: setSubscriptionPackage,
                setPage,
              })
            }
            onReset={(filterVal) =>
              onFilterChange({
                filterValue: filterVal,
                setFilteredValue: setSubscriptionPackage,
                setPage,
              })
            }
            props={props}
          />
        );
      },
      filterMultiple: false,
      render: (text: string, record: TableInput) => {
        let active = 0,
          type = 'Free';
        if (record && record.userPlan && record?.userPlan?.length > 0) {
          if (record?.userPlan[0]?.packageSize === '16') {
            active = 1;
            type = 'Yearly';
          } else if (record?.userPlan[0]?.packageSize === '20') {
            active = 2;
            type = 'Monthly';
          }
        }

        return (
          <FlexContainer>
            <TypeTag active={active}>
              <Text>{type}</Text>
            </TypeTag>
          </FlexContainer>
        );
      },
    },
    {
      dataIndex: 'menu',
      key: 'menu',
      render: () => (
        <TableCell>
          <img src={MenuDots} alt='menu' />
        </TableCell>
      ),
    },
  ];
};

export { columns };
