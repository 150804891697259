import styled from 'styled-components';
import { Form, Button, Input } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
};

type WidthProps = {
  width: string;
};

const InputField = styled(Input)<WidthProps>`
  font-size: 18px;
  width: ${(props) => props.width};
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const Container = styled.div`
  box-shadow: 0px 0px 10px rgba(64, 19, 71, 0.16);
  padding: 10px;
  margin-bottom: 20px;
`;

const Title = styled.p`
  font-size: 20px;
`;

const CustomButton = styled(Button)<WidthProps>`
  width: ${(props) => props.width};
  @media (max-width: 600px) {
    width: 100%;
  }
`;

interface DynamicFormListProps {
  title: string;
  formName: string;
  displayName: string;
}
const DynamicFormList = ({
  title,
  formName,
  displayName,
}: DynamicFormListProps) => {
  return (
    <Container>
      <Title>• {title}</Title>
      <Form.List
        name={formName}
        rules={[
          {
            validator: async (_, names) => {
              if (!names || names.length < 1) {
                return Promise.reject(
                  new Error(`At least 1 ${displayName} is required`)
                );
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item required={true} key={field.key}>
                <Form.Item
                  {...field}
                  {...(index === 0
                    ? formItemLayout
                    : formItemLayoutWithOutLabel)}
                  label={index === 0 ? displayName : ''}
                  validateTrigger={['onChange', 'onBlur']}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: `Please input a ${displayName} or delete this field.`,
                    },
                  ]}
                  noStyle
                >
                  <InputField placeholder={displayName} width='60%' />
                </Form.Item>
                {fields.length > 1 ? (
                  <MinusCircleOutlined
                    style={{ marginLeft: '10px' }}
                    className='dynamic-delete-button'
                    onClick={() => remove(field.name)}
                  />
                ) : null}
              </Form.Item>
            ))}
            <Form.Item>
              <CustomButton
                type='dashed'
                onClick={() => add()}
                width='60%'
                icon={<PlusOutlined />}
              >
                Add field
              </CustomButton>

              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
    </Container>
  );
};
export default DynamicFormList;
