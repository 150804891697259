import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';
import { Divider } from 'antd';
import { colors } from '../../constants';
import { UpArrow, DownArrow } from '../../assets';

const RootContainer = styled.div`
  .polygon {
    width: 12px;
    height: 12px;
    margin-right: 4rem;
  }
  .ant-divider {
    margin-left: 20px;
    width: 97%;
    min-width: 97%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Tab = styled.p`
  color: ${colors.blueGray};
  margin-left: 3rem;
  font-weight: 700;
  font-size: 16px;
`;

type SettingsTabProps = {
  title: string;
  children: ReactNode;
};

const SettingsTab = ({ title, children }: SettingsTabProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  return (
    <RootContainer>
      <Divider />
      <Wrapper>
        <Tab>{title}</Tab>
        <img
          src={isCollapsed ? DownArrow : UpArrow}
          alt='arrow'
          className='polygon'
          onClick={() => setIsCollapsed(!isCollapsed)}
        />
      </Wrapper>
      <div className='tab'>{!isCollapsed && children}</div>
    </RootContainer>
  );
};

export default SettingsTab;
