import { PlusIcon } from '../../../assets';
import { Category, ModalWrapperProps } from '../../../utils';
import { ConfigProvider, Modal, Input, Select, Spin } from 'antd';
import {
  Label,
  StyledButton,
  ModalContentWrapper,
} from '../../../constants/styles';
import { useState } from 'react';
import { useFetch } from '../../../api/provider';
import { services } from '../../../api/services';
import AddCategoryModal from '../AddCategoryModal';
import AddCategory from '../../AddCategory';

const { TextArea } = Input;

const AddQuestionModal = ({
  onClose,
  open,
  categories,
  setRefetchCategories,
  setRefetchQuestions,
}: ModalWrapperProps) => {
  const [qContent, setQContent] = useState('');
  const [addCategoryModalOpen, setAddCategoryModalOpen] = useState(false);
  const [addCategoryClick, setAddCategoryClick] = useState(false);

  const [selectedCatId, setSelectedCatId] = useState<number>();
  const [, loading, addQuestionCall] = useFetch({
    service: services.addQuestion,
  });
  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setQContent(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      const response = await addQuestionCall({
        userId: 1,
        categoryId: selectedCatId,
        content: qContent?.trim(),
      });
      if (response) {
        setQContent('');
        setSelectedCatId(undefined);
        setRefetchCategories && setRefetchCategories(true);
        setRefetchQuestions && setRefetchQuestions(true);
        onClose();
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            titleFontSize: 28,
            titleLineHeight: 1.108,
            fontFamily: 'Nexa XBold',
            fontWeightStrong: 800,
            titleColor: '#342A53',
          },
        },
      }}
    >
      <Modal
        centered
        open={open}
        onOk={handleSubmit}
        onCancel={() => {
          setQContent('');
          setSelectedCatId(undefined);
          setAddCategoryClick(false);
          onClose();
        }}
        title='Add Question'
        className='newStyle'
        okText={'Add Question'}
        okButtonProps={{
          disabled: !selectedCatId || !qContent,
        }}
        cancelButtonProps={{
          className: 'cancelBtn',
        }}
      >
        <AddCategoryModal
          open={addCategoryModalOpen}
          setRefetchCategories={setRefetchCategories}
          onClose={() => setAddCategoryModalOpen(false)}
        />
        <Spin spinning={loading}>
          <ModalContentWrapper flexDirection='column' gap='19px'>
            <div>
              <Label
                size='13px'
                margin='0 0 8px'
                lineHeight='140%'
                textcolor='#79818E'
                fontfamily='Poppins'
              >
                Add Question
              </Label>
              <TextArea
                showCount
                value={qContent}
                maxLength={140}
                
                onChange={onChange}
                className='questionInput'
                placeholder='Keep it short and clear'
                style={{
                  height: 91,
                  maxWidth: 510,
                }}
              />
            </div>
            <Select
              value={selectedCatId}
              onChange={(id) => setSelectedCatId(id)}
              className='categorySelect'
              placeholder='Choose Category'
              style={{ width: 188, margin: '19px 0 0' }}
            >
              {categories?.map((item: Category, index: number) => (
                <Select.Option key={index} value={item?.id}>
                  {item?.name?.trim()}
                </Select.Option>
              ))}
            </Select>
            {!addCategoryClick ? (
              <StyledButton
                margin='0'
                border='none'
                onClick={() => setAddCategoryClick(true)}
              >
                <img
                  src={PlusIcon}
                  alt='add'
                  style={{ verticalAlign: 'sub' }}
                />
                {'Add New Category'}
              </StyledButton>
            ) : (
              <AddCategory
                setCategoryClick={setAddCategoryClick}
                setRefetchCategories={setRefetchCategories}
              />
            )}
          </ModalContentWrapper>
        </Spin>
      </Modal>
    </ConfigProvider>
  );
};

export default AddQuestionModal;
