import { Popconfirm } from 'antd';
import styled from 'styled-components';
import { colors } from '../../constants';
import { BlogImage, FlexContainer } from '../../constants/styles';
import { TableInput, DataRowInput, getImageUrl } from '../../utils';

interface ColumnsProps {
  onDeleteHandle: (id: number) => void;
  onIgnoreHandle: (id: number) => void;
}

interface ActionProps {
  color: string;
}

const TableRowItem1 = styled.div<DataRowInput>`
  display: flex;
  flex-direction: column;
  margin-left: ${(props: DataRowInput) => (props.active ? '10px' : '0px')};
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
`;

const ActionButton = styled.p<ActionProps>`
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.color};
  padding: 0px;
  margin: 0px;
`;

const Description = styled.p`
  font-weight: 600;
  color: ${colors.lightGrey};
  padding: 0px;
`;

const Image = styled.img`
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 25px;
`;

const Title = styled.p`
  color: ${colors.black};
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  font-weight: 500;
`;

const Columns = ({ onDeleteHandle, onIgnoreHandle }: ColumnsProps) => {
  return [
    {
      title: 'Post Author',
      dataIndex: 'postAuthor',
      key: 'postAuthor',
      render: (_text: string, record: TableInput[]) => {
        const fullName =
          record[0].reportedPost?.user?.firstName +
            ' ' +
            record[0].reportedPost?.user?.lastName || ' ';
        const preview =
          record[0].reportedPost?.user?.avatarMedia?.preview || '';
        const email = record[0].reportedPost?.user?.email;
        const userId = record[0]?.reportedPost?.user?.id || '';

        return (
          <FlexContainer>
            <Image src={getImageUrl(userId, preview)} />
            <TableRowItem1 active={1}>
              <Title>{fullName}</Title>
              <Description>{email}</Description>
            </TableRowItem1>
          </FlexContainer>
        );
      },
    },
    {
      title: 'Post Title',
      dataIndex: 'PostTitle',
      key: 'PostTitle',
      render: (_text: string, record: TableInput[]) => {
        return (
          <TableRowItem1 active={0}>
            <Title>{record[0].reportedPost.title}</Title>
          </TableRowItem1>
        );
      },
    },
    {
      title: 'Post Description',
      dataIndex: 'PostDescription',
      key: 'PostDescription',
      render: (_text: string, record: TableInput[]) => {
        return (
          <TableRowItem1 active={0}>
            <Title>{record[0].reportedPost.description}</Title>
          </TableRowItem1>
        );
      },
    },
    {
      title: 'Post Image',
      dataIndex: 'image',
      key: 'image',
      inputType: 'text',
      editable: false,
      render: (_text: string, record: TableInput[]) => {
        const preview = record[0].reportedPost.user?.avatarMedia?.preview || '';
        const id = record[0].reportedPost.user?.id || '';
        return (
          <FlexContainer>
            <BlogImage alt='Post' src={getImageUrl(id, preview)} />
          </FlexContainer>
        );
      },
    },
    {
      title: 'Report Count',
      dataIndex: 'reportCount',
      key: 'reportCount',
      sorter: (a: any, b: any) => {
        return a[0].reportedPost?.reportCount - b[0].reportedPost?.reportCount;
      },
      render: (_text: string, record: TableInput[]) => {
        return (
          <TableRowItem1 active={0}>
            <Title>{record[0]?.reportedPost?.reportCount}</Title>
          </TableRowItem1>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      render: (_text: string, record: TableInput[]) => {
        return (
          <ButtonWrapper>
            <ActionButton
              color={colors.appColor}
              style={{ marginRight: '10px' }}
            >
              <Popconfirm
                style={{ padding: '20px' }}
                placement='left'
                title='User Post will be deleted?'
                onConfirm={() => onDeleteHandle(record[0].id)}
              >
                Delete Post
              </Popconfirm>
            </ActionButton>
            <ActionButton color={colors.red}>
              <Popconfirm
                style={{ padding: '20px' }}
                placement='left'
                title='Resolve and remove this complaint?'
                onConfirm={() => onIgnoreHandle(record[0].id)}
              >
                Resolve
              </Popconfirm>
            </ActionButton>
          </ButtonWrapper>
        );
      },
    },
  ];
};

export { Columns };
