import { useEffect, useState } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  TooltipProps,
} from 'recharts';
import styled from 'styled-components';
import { MapContainer, TileLayer } from 'react-leaflet';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { Spin, message } from 'antd';
import { colors, USER_INTERVAL_OPTIONS } from '../../constants';
import GraphQuestionsCard from '../../components/graphQuestionsCard';
import GraphCategoriesCard from '../../components/graphCategoriesCard';
import { useFetch } from '../../api/provider';
import { services } from '../../api/services';
import { Heading, TooltipContainer } from '../../constants/styles';

const FlexContainer = styled.div`
  display: flex;
  @media (max-width: 995px) {
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 860px) {
    flex-direction: row;
  }
  @media (max-width: 813px) {
    flex-direction: column;
    align-items: center;
  }
`;

const SelectBox = styled.div`
  position: absolute;
  cursor: pointer;
  top: 10;
  left: 10;
  z-index: 1;
`;

const Select = styled.select`
  border-radius: 4px;
  border-color: ${colors.appColor};
  width: 100px;
`;

const Title = styled.p`
  font-size: 12px;
  font-weight: 600;
  color: ${colors.greyBack};
  margin: 0;
`;

const Value = styled.h1`
  color: ${colors.appColor};
  font-size: 20px;
`;

const MonthName = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: ${colors.greyBack};
`;

const MapRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AccountsChart = styled.div`
  width: 45%;
  background-color: ${colors.magnolia};
  margin: 20px;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: row;
`;
const MapLegandColor = styled.div`
  width: 30px;
  height: 10px;
  border-radius: 5px;
  margin-right: 10px;
  background-image: linear-gradient(#fd749b, #281ac8);
`;

const Graphs = () => {
  const [interval, setInterval] = useState('Monthly');
  const [questionsData, questionsLoading] = useFetch({
    service: services.getQuestions,
  });
  const [categoriesData, categoriesLoading] = useFetch({
    service: services.getAllCategories,
  });

  const [graphList, , getGraphListCall] = useFetch({
    service: services.getUserGraphByDuration,
    method: 'GET',
  });

  const [premiumData, , getPremiumCall] = useFetch({
    service: services.getPremiumUserCount,
    method: 'GET',
  });

  useEffect(() => {
    getGraphListCall({ time: interval });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interval]);

  useEffect(() => {
    getPremiumCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (questionsData && !questionsData?.success)
      message.error(questionsData.message);
  }, [questionsData]);
  const CustomToolTip = ({
    payload,
    label,
  }: TooltipProps<ValueType, NameType>) => {
    return (
      <TooltipContainer>
        <Title>{label}</Title>
        {payload && (
          <div>
            <Value>{payload[0]?.value}</Value>
            <MonthName>{'Users Registered'}</MonthName>
          </div>
        )}
      </TooltipContainer>
    );
  };

  const RenderAccountsChart = () => {
    return (
      <AccountsChart style={{ position: 'relative' }}>
        <SelectBox>
          <Select
            value={interval}
            onChange={(val) => {
              setInterval(val.target.value);
            }}
          >
            {USER_INTERVAL_OPTIONS.map((option) => (
              <option value={option}>{option}</option>
            ))}
          </Select>
        </SelectBox>
        <ResponsiveContainer width='100%' height='100%' debounce={1}>
          <AreaChart
            data={graphList?.data || []}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <defs>
              <linearGradient id='colorNewAccounts' x1='1' y1='0' x2='0' y2='0'>
                <stop offset='5%' stopColor={colors.pink} stopOpacity={0.8} />
                <stop offset='95%' stopColor={colors.blue} stopOpacity={1} />
              </linearGradient>
            </defs>
            <XAxis dataKey='name' />
            <YAxis />
            <Tooltip
              cursor={{ stroke: colors.red, strokeWidth: 2 }}
              content={CustomToolTip}
            />

            <Legend verticalAlign='top' height={36} align='right' />
            <Area
              type='monotone'
              dataKey='userNumber'
              stroke='url(#colorNewAccounts)'
              fillOpacity={0.1}
              fill={colors.appColor}
              strokeWidth={5}
            />
          </AreaChart>
        </ResponsiveContainer>
      </AccountsChart>
    );
  };

  const RenderMapsChart = () => {
    return (
      <AccountsChart>
        <div style={{ width: '35%', height: '100%' }}>
          <MonthName>Visitors Location</MonthName>
          <MapRow>
            <MapLegandColor />
            <Title>Unique Visitors</Title>
          </MapRow>
          <MonthName style={{ marginLeft: 40, color: colors.black }}>
            300,000,000
          </MonthName>
          <>
            <MapRow>
              <MapLegandColor />
              <Title>Users on Premium</Title>
            </MapRow>
            <MonthName style={{ marginLeft: 40, color: colors.black }}>
              {premiumData?.data ?? 0}
            </MonthName>
          </>
        </div>
        <MapContainer
          center={{ lat: 51.505, lng: -0.09 }}
          zoom={1}
          zoomControl={false}
          style={{
            zIndex: 0,
            width: '65%',
            height: '100%',
            backgroundColor: colors.magnolia,
          }}
          maxZoom={1}
          minZoom={1}
          bounceAtZoomLimits={true}
        >
          <TileLayer url='https://api.mapbox.com/styles/v1/salman-trimulabs/ckmg9v4go1ihu17mfk35udw95/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoic2FsbWFuLXRyaW11bGFicyIsImEiOiJja21nYWw2NDYwdzhuMnVwamp2N2JhM3J0In0.Ve-sexfBZy687nDPyZF_Vg' />
        </MapContainer>
      </AccountsChart>
    );
  };

  return (
    <>
      <Heading
        margin='20px'
        fontSize='28px'
        fontWeight='800'
        lineHeight='110%'
        fontFamily='Nexa XBold'
        color={colors.textColor}
      >
        {'Dashboard'}
      </Heading>
      <div style={{ flex: 1, display: 'flex' }}>
        <RenderAccountsChart />
        <RenderMapsChart />
      </div>
      <Spin spinning={questionsLoading || categoriesLoading}>
        <FlexContainer>
          <GraphQuestionsCard
            added={questionsData?.meta?.count}
            deleted={questionsData?.meta?.count}
          />
          <GraphCategoriesCard count={categoriesData?.meta?.count} />
        </FlexContainer>
      </Spin>
    </>
  );
};
export default Graphs;
