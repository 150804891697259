import React from 'react';
import { FlexContainer, FlexWrapper, Label } from '../../constants/styles';
import { colors } from '../../constants';

type QuestionsInfoProps = {
  image: string;
  title: string;
  count: number;
};

const QuestionsInfo = ({ image, title, count }: QuestionsInfoProps) => {
  return (
    <FlexContainer>
      <img src={image} alt='' className='line' />
      <FlexWrapper>
        <Label size='14px' textcolor={colors.lightGrey}>
          {title}
        </Label>
        <Label
          margin='5px 0px 20px 0px'
          size='14px'
          weight={600}
          textcolor={colors.black}
        >
          {count && `${count} users`}
        </Label>
      </FlexWrapper>
    </FlexContainer>
  );
};

export default QuestionsInfo;
