import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const SearchSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='25'
    viewBox='0 0 24 25'
    fill='none'
  >
    <path
      d='M10.5 17.0947C11.2879 17.0947 12.0682 16.9395 12.7961 16.638C13.5241 16.3365 14.1855 15.8945 14.7427 15.3374C15.2998 14.7802 15.7418 14.1188 16.0433 13.3908C16.3448 12.6629 16.5 11.8827 16.5 11.0947C16.5 10.3068 16.3448 9.52659 16.0433 8.79864C15.7418 8.07069 15.2998 7.40925 14.7427 6.8521C14.1855 6.29495 13.5241 5.85299 12.7961 5.55146C12.0682 5.24994 11.2879 5.09474 10.5 5.09474C8.90872 5.09474 7.38259 5.72688 6.25737 6.8521C5.13216 7.97732 4.50002 9.50344 4.50002 11.0947C4.50002 12.686 5.13216 14.2122 6.25737 15.3374C7.38259 16.4626 8.90872 17.0947 10.5 17.0947ZM16.82 16.0007L20.4 19.5807C20.4955 19.6731 20.5716 19.7835 20.6239 19.9055C20.6762 20.0275 20.7037 20.1588 20.7048 20.2916C20.7058 20.4243 20.6804 20.556 20.63 20.6789C20.5797 20.8017 20.5053 20.9133 20.4114 21.0071C20.3174 21.101 20.2057 21.1751 20.0828 21.2253C19.9599 21.2755 19.8282 21.3007 19.6954 21.2995C19.5626 21.2982 19.4314 21.2706 19.3095 21.2181C19.1875 21.1656 19.0772 21.0893 18.985 20.9937L15.405 17.4137C13.7975 18.6616 11.7748 19.2499 9.74877 19.0589C7.72273 18.868 5.84562 17.9121 4.49957 16.3858C3.15351 14.8595 2.4397 12.8777 2.50344 10.8437C2.56718 8.80964 3.40368 6.87636 4.84266 5.43739C6.28164 3.99841 8.21492 3.16191 10.2489 3.09817C12.283 3.03443 14.2648 3.74824 15.7911 5.09429C17.3173 6.44035 18.2732 8.31746 18.4642 10.3435C18.6552 12.3695 18.0669 14.3922 16.819 15.9997L16.82 16.0007Z'
      fill='currentColor'
    />
  </svg>
);

export const SearchIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SearchSvg} {...props} />
);
