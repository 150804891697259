import { Columns } from './columns';
import { services } from '../../api/services';
import { useFetch } from '../../api/provider';
import { TableInput } from '../../utils';
import TablePageHeader from '../../components/tablePageHeader';
import CustomPagination from '../../components/customPagination';
import { GetReportedBy } from '../reportedUser/columns';
import { Spin, Table, Form } from 'antd';
import { useEffect, useState } from 'react';
import { Heading } from '../../constants/styles';
import { colors } from '../../constants';

const App: React.FC = () => {
  const [form] = Form.useForm();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState<{ pageSize: number; pageNo: number }>({
    pageNo: 1,
    pageSize: 6,
  });

  const [reportedList, getLoading, getAllCall] = useFetch({
    service: services.getReportedPosts,
    method: 'GET',
  });

  const [data, setData] = useState(reportedList?.data?.reportedPostsList || []);

  useEffect(() => {
    getAllCall({
      pageNo: page.pageNo,
      pageSize: page.pageSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (reportedList?.data?.meta?.count) setTotal(reportedList.data.meta.count);
    if (reportedList?.data?.reportedPostsList?.length > 0) {
      setData(reportedList?.data?.reportedPostsList);
    }
  }, [reportedList]);

  const ReportedBy = ({ recordList }: { recordList: TableInput[] }) => (
    <div>
      <h1>Reported by</h1>

      <div style={{ display: 'flex' }}>
        {recordList.map((record: TableInput) => {
          const email = record.fromUser?.email || ' ';
          const fullName =
            record.fromUser?.firstName + ' ' + record.fromUser?.lastName;
          const preview = record.fromUser?.avatarMedia?.preview || '';
          const userId = record.fromUser?.id || '';
          return (
            <GetReportedBy
              comment={record.comment || ''}
              email={email}
              fullName={fullName}
              preview={preview}
              userId={userId}
            />
          );
        })}
      </div>
    </div>
  );

  const onDeleteHandle = (id: number) => {};

  const onIgnoreHandle = (id: number) => {};

  return (
    <Form form={form} component={false}>
      <Heading
          margin='20px'
          fontSize='28px'
          fontWeight='800'
          lineHeight='110%'
          fontFamily='Nexa XBold'
          color={colors.textColor}
        >
          {'Reported Posts'}
        </Heading>
      <Spin spinning={getLoading}>
        <TablePageHeader>
          <Table
            rowKey={(val) => val[0].id}
            style={{ flex: 1, paddingRight: 10 }}
            dataSource={data || []}
            scroll={{ x: 1000 }}
            columns={Columns({ onDeleteHandle, onIgnoreHandle })}
            expandable={{
              expandedRowRender: (recordList) => {
                return <ReportedBy recordList={recordList} />;
              },
            }}
            pagination={false}
          />
          <CustomPagination page={page} setPage={setPage} total={total} />
        </TablePageHeader>
      </Spin>
    </Form>
  );
};

export default App;
