import { useContext } from 'react';
import { Line } from '../../assets';
import SiderItem from '../siderItem';
import styled from 'styled-components';
import { colors } from '../../constants';
import AppContext from '../../appContext';
import { RouterProps } from 'react-router-dom';
import { ICONS_DATA, SETTINGS } from '../../constants/stubs';
import { deleteSession, deleteToken } from '../../helpers/SessionManagement';

const Sider = styled.div`
  gap: 10px;
  width: 100%;
  min-width: 240px;
  max-width: 280px;
  display: flex;
  padding: 50px 39px;
  overflow-y: scroll;
  flex-direction: column;
  background-color: ${colors.textColor};
  @media (max-width: 1305px) {
    padding: 40px 30px;
  }
  .logo {
    display: flex;
    align-self: center;
    margin: 26px 0px auto 0px;
  }
  .logout {
    align-self: center;
    width: 24px;
    height: 24px;
    margin-bottom: 40px;
  }
`;
interface IconsData {
  id: number;
  icon: string;
  title: string;
}

type AppSiderProps = {
  activeIndex: number;
  setActiveIndex: Function;
};

const AppSider = (props: RouterProps & AppSiderProps) => {
  const setLoggedIn = useContext(AppContext)?.setIsLoggedIn;
  const setActiveIndex = (e: number) => {
    props.setActiveIndex(e);
    if (e === 0) {
      props.history.push('/dashboard');
    } else if (e === 1) {
      props.history.push('/users');
    } else if (e === 2) {
      props.history.push('/questions');
    } else if (e === 3) {
      props.history.push('/messages');
    } else if (e === 4) {
      props.history.push('/blogs');
    } else if (e === 5) {
      props.history.push('/jobs');
    } else if (e === 6) {
      props.history.push('/faqs');
    } else if (e === 7) {
      props.history.push('/donations');
    } else if (e === 8) {
      props.history.push('/testimonials');
    } else if (e === 9) {
      props.history.push('/feedback');
    } else if (e === 10) {
      props.history.push('/reported-user');
    } else if (e === 11) {
      props.history.push('/reported-post');
    } else if (e === 12) {
      props.history.push('/settings');
    }
  };
  const logout = () => {
    deleteSession();
    deleteToken();
    setLoggedIn && setLoggedIn(false);
    props.setActiveIndex(0);
    props.history.push('/');
  };

  return (
    <Sider>
      {ICONS_DATA.map((item: IconsData, index: number) => {
        return (
          <SiderItem
            key={item.id}
            index={index}
            icon={item.icon}
            title={item.title}
            setActiveIndex={setActiveIndex}
            active={props.activeIndex === index}
          />
        );
      })}
      <img src={Line} alt='divider' style={{ margin: '20px 0' }} />
      {SETTINGS.map((item: IconsData, index: number) => {
        return (
          <SiderItem
            key={item.id}
            icon={item.icon}
            index={index + 12}
            title={item.title}
            active={props.activeIndex === index + 12}
            setActiveIndex={item.title === 'Logout' ? logout : setActiveIndex}
          />
        );
      })}
    </Sider>
  );
};

export default AppSider;
