import React from 'react';
import styled from 'styled-components';
import {
  GraphCard,
  Label,
  Heading,
  Wrapper,
  ChartContainer,
} from '../../constants/styles';
import { GreenArrow } from '../../assets';
import { colors } from '../../constants';
import { ResponsiveContainer, AreaChart, Area } from 'recharts';
import { CATEGORIES_CHART_DATA } from '../../constants/stubs';

const Total = styled.div`
  font-weight: 800;
  font-size: 22px;
  line-height: 28px;
  background: -webkit-linear-gradient(#fd747e, #281ac8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

type CategoriesChartProps = {
  count: number;
};

const GraphCategoriesCard = ({ count }: CategoriesChartProps) => {
  return (
    <GraphCard width='310px'>
      <ChartContainer>
        <Wrapper justifycontent='space-between'>
          <Heading width='200px' margin='0px 0px 10px 0px'>
            Created Categories for Questions
          </Heading>
          <Label textcolor={colors.lightGreen} size='12px'>
            22.8%
            <img src={GreenArrow} alt='arrow' style={{ marginLeft: 2 }} />
          </Label>
        </Wrapper>
        <Total>{count}</Total>
      </ChartContainer>
      <ResponsiveContainer width='100%' height='49%'>
        <AreaChart data={CATEGORIES_CHART_DATA}>
          <Area
            type='natural'
            dataKey='uv'
            stroke={colors.purple}
            fill={colors.thistle}
          />
        </AreaChart>
      </ResponsiveContainer>
    </GraphCard>
  );
};

export default GraphCategoriesCard;
