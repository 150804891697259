import { columns } from './columns';
import { EDIT_FAQ, colors } from '../../constants';
import { services } from '../../api/services';
import { useFetch } from '../../api/provider';
import { TableInput } from '../../utils';
import { useHistory } from 'react-router-dom';
import TablePageHeader from '../../components/tablePageHeader';
import { Spin, Table } from 'antd';
import { CreateButton, Heading } from '../../constants/styles';

const FAQs = () => {
  const history = useHistory();

  const [faqList, loading, getAllFaqsCall] = useFetch({
    service: services.getAllFaqs,
    callOnMount: true,
    method: 'GET',
  });

  const [, deleteLoading, deleteFaqCall] = useFetch({
    service: services.deleteFaq,
  });

  const onEditHandle = (id: number) => {
    history.push(`/${EDIT_FAQ}/${id}`);
  };

  const onDeleteHandle = async (id: number) => {
    await deleteFaqCall({ id });
    getAllFaqsCall();
  };

  return (
    <Spin spinning={loading || deleteLoading}>
      <Heading
        margin='20px'
        fontSize='28px'
        fontWeight='800'
        lineHeight='110%'
        fontFamily='Nexa XBold'
        color={colors.textColor}
      >
        {'FAQs'}
      </Heading>
      <CreateButton
        onClick={() => {
          history.push('/create-faq');
        }}
      >
        Add an FAQ
      </CreateButton>
      <TablePageHeader>
        <Table
          dataSource={faqList?.data || []}
          rowKey='id'
          columns={columns({
            onDeleteHandle,
            onEditHandle,
          })}
          style={{ flex: 1, paddingRight: 10 }}
          scroll={{ x: 1000 }}
          pagination={false}
          onRow={(record: TableInput) => {
            return {
              onClick: () => {},
            };
          }}
        />
      </TablePageHeader>
    </Spin>
  );
};
export default FAQs;
