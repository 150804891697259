import styled from 'styled-components';
import { Spin, Input, message } from 'antd';
import { colors } from '../../constants';
import { Row } from 'react-flexbox-grid';
import { useEffect, useState } from 'react';
import { services } from '../../api/services';
import { useFetch } from '../../api/provider';
import QuestionsTable from '../../components/quetionsTable';
import StatsModal from '../../components/modals/StatsModal';
import FilterModal from '../../components/modals/FilterModal';
import ImportCSVModal from '../../components/modals/ImportCSVModal';
import AddQuestionModal from '../../components/modals/AddQuestionModal';
import { Add, NoQuestionsIcon, SearchIcon, VerticalLine } from '../../assets';
import EditQuestionModal from '../../components/modals/EditQuestionModal';
import { Heading, Label, TableHeaderButton } from '../../constants/styles';
import DeleteQuestionModal from '../../components/modals/DeleteQuestionModal';
import ManageCategoriesModal from '../../components/modals/ManageCategoriesModal';
import FiltersRow from '../../components/FiltersRow';
import NoData from '../../components/NoData';
import {
  Category,
  DataType,
  QuestionData,
  exportCategories,
  onSelectChange,
  questionsColumns,
} from '../../utils';
import { QuestionMapper, QuestionsMapper } from '../../utils/QuestionsMapper';

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-start;
`;

const QuestionsContainer = styled.div`
  height: 100%;
  width: 100%;
  min-height: 750px;
  border-radius: 6px;
  margin: 30px 20px 30px;
  padding: 30px 30px 50px;
  max-width: -webkit-fill-available;
  background-color: ${colors.white};
`;

const ButtonsRow = styled(Row)<{ isselect?: boolean; isQuestions?: boolean }>`
  gap: 30px;
  margin: 24px 0;
  align-items: center;
  justify-content: ${(props) =>
    props.isQuestions ? 'space-between' : 'flex-end'};
  @media (max-width: 1380px) {
    justify-content: ${(props) =>
      props.isselect ? 'space-between' : 'flex-start'};
  }
`;

const InnerRow = styled(Row)`
  gap: 5px;
  justify-content: flex-end;
  @media (max-width: 1055px) {
    row-gap: 10px;
    justify-content: flex-start !important;
  }
`;

const Questions = () => {
  const [search, setSearch] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [filters, setFilters] = useState<string[]>([]);
  const [searchClicked, setSearchClicked] = useState(false);
  const [statsModalOpen, setStatsModalOpen] = useState(false);
  const [filterData, setFilterData] = useState<DataType[]>([]);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [showTableActions, setShowTableActions] = useState(true);
  const [dltQuesModalOpen, setDltQuesModalOpen] = useState(false);
  const [refetchQuestions, setRefetchQuestions] = useState(false);
  const [searchedData, setSearchedData] = useState<DataType[]>([]);
  const [refetchCategories, setRefetchCategories] = useState(false);
  const [showSelectActions, setShowSelectActions] = useState(false);
  const [editQuestion, setQuestionEdit] = useState<Partial<DataType>>();
  const [importCSVModalOpen, setImportCSVModalOpen] = useState(false);
  const [manageCategoriesModalOpen, setManageCategoriesModalOpen] =
    useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [questionMapper] = useState<QuestionMapper>(new QuestionsMapper());
  const [selectedQuestionsId, setSelectedQuestionsId] = useState<number[]>([]);

  const [editQuestionModalOpen, setEditQuestionStatsModalOpen] =
    useState(false);

  const [questionsList, questionsLoading, getQuestionCall] = useFetch({
    service: services.getAllQuestions,
    method: 'GET',
    callOnMount: true,
  });

  const [categoriesList, categoriesLoading, getCategoriesCall] = useFetch({
    service: services.getAllCategories,
    method: 'GET',
    callOnMount: true,
  });
  const [, getAllCatWithQuesLoad, getAllCatWithQuestions] = useFetch({
    service: services.getCategoryWithQuestions,
    method: 'GET',
  });
  const [questionStats, statLoading, getQustionStats] = useFetch({
    service: services.getQuestionStatsById,
    method: 'GET',
  });

  useEffect(() => {
    if (refetchQuestions) {
      getQuestionCall();
      setRefetchQuestions(false);
    }
    if (refetchCategories) {
      getCategoriesCall();
      setRefetchCategories(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchQuestions, refetchCategories]);

  useEffect(() => {
    if (search) {
      const sText = search?.toLowerCase();
      // eslint-disable-next-line array-callback-return
      const data = filterData?.filter((item) => {
        if (
          item?.question?.toLowerCase().includes(sText) ||
          item?.category?.toLowerCase().includes(sText)
        )
          return item;
      });
      setSearchedData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (categoriesList?.data?.data && questionsList?.data?.data) {
      const allCategories: Category[] = categoriesList?.data?.data;
      const allQuestions: QuestionData[] = questionsList?.data?.data;

      if (filters?.length) {
        const filteredCategories = allCategories?.filter((name) =>
          filters.includes(name?.name)
        );
        const filteredQuestions = allQuestions.filter((question) =>
          filters.includes(
            allCategories.find(
              (category) => category.id === question.categoryId
            )?.name!
          )
        );
        setFilterData(
          filteredQuestions.map((question, index) =>
            questionMapper.mapToData(index, question, filteredCategories)
          )
        );
      } else {
        setFilterData(
          allQuestions.map((question, index) =>
            questionMapper.mapToData(index, question, allCategories)
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesList?.data?.data, questionsList?.data?.data, filters]);

  const edit = (record: Partial<DataType> & { key: React.Key }) => {
    setQuestionEdit(record);
    setEditQuestionStatsModalOpen(true);
  };
  const stats = async (record: Partial<DataType> & { key: React.Key }) => {
    setQuestionEdit(record);
    await getQustionStats({ questionId: record?.questionId });
    setStatsModalOpen(true);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (newRowKey: React.Key[]) => {
      const { ids } = onSelectChange(questionsList?.data?.data, newRowKey);
      setSelectedQuestionsId(ids);
      setSelectedRowKeys(newRowKey);
    },
  };

  const handleTotal = (total: number, range: [number, number]): JSX.Element => (
    <Label>
      {showTableActions
        ? `Showing ${range[0].toLocaleString()} - ${range[1].toLocaleString()} from 
      ${total.toLocaleString()}`
        : `${selectedRowKeys.length} question${
            selectedRowKeys.length <= 1 ? '' : 's'
          } selected`}
    </Label>
  );

  const exportData = async () => {
    try {
      const response = await getAllCatWithQuestions();
      if (response?.data) {
        await exportCategories(response?.data);
        message.success('Questions exported successfully.');
      } else {
        message.error(response?.error);
      }
    } catch (e) {
      message.error(e);
    }
  };

  return (
    <Spin
      spinning={
        statLoading ||
        questionsLoading ||
        categoriesLoading ||
        getAllCatWithQuesLoad
      }
    >
      <Container>
        <AddQuestionModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          questions={questionsList?.data?.data}
          categories={categoriesList?.data?.data}
          setRefetchQuestions={setRefetchQuestions}
          setRefetchCategories={setRefetchCategories}
        />
        <ImportCSVModal
          open={importCSVModalOpen}
          setRefetchQuestions={setRefetchQuestions}
          setRefetchCategories={setRefetchCategories}
          onClose={() => setImportCSVModalOpen(false)}
        />
        <DeleteQuestionModal
          open={dltQuesModalOpen}
          setSelectedRowKeys={setSelectedRowKeys}
          selectedQuestions={selectedQuestionsId}
          setRefetchQuestions={setRefetchQuestions}
          onClose={() => setDltQuesModalOpen(false)}
        />
        <ManageCategoriesModal
          open={manageCategoriesModalOpen}
          questions={questionsList?.data?.data}
          categories={categoriesList?.data?.data}
          setRefetchQuestions={setRefetchQuestions}
          setRefetchCategories={setRefetchCategories}
          onClose={() => setManageCategoriesModalOpen(false)}
        />
        <FilterModal
          filters={filters}
          open={filterModalOpen}
          setFilters={setFilters}
          categories={categoriesList?.data?.data}
          onClose={() => setFilterModalOpen(false)}
        />
        <StatsModal
          open={statsModalOpen}
          selectedQuestion={editQuestion!}
          questionStats={questionStats?.data}
          onClose={() => setStatsModalOpen(false)}
        />
        <EditQuestionModal
          question={editQuestion!}
          open={editQuestionModalOpen}
          categories={categoriesList?.data?.data}
          setRefetchQuestions={setRefetchQuestions}
          onClose={() => setEditQuestionStatsModalOpen(false)}
        />
        <QuestionsContainer>
          <Heading
            fontSize='28px'
            fontWeight='800'
            lineHeight='110%'
            fontFamily='Nexa XBold'
            color={colors.textColor}
          >
            Questions
          </Heading>
          <ButtonsRow
            isQuestions={questionsList?.data?.data ? true : false}
            isselect={showSelectActions ? true : false}
          >
            {!!questionsList?.data?.data && (
              <Input
                allowClear
                value={search}
                placeholder='Search'
                className='searchInput'
                onFocus={() => {
                  setSearchClicked(true);
                  console.log('searchClicked :>> ', searchClicked);
                }}
                onBlur={() => {
                  setSearchClicked(false);
                  console.log('searchClicked :>> ', searchClicked);
                }}
                onChange={(e) => setSearch(e.target?.value)}
                prefix={<SearchIcon style={{ color: '#79818E' }} />}
              />
            )}
            {showTableActions && (
              <InnerRow>
                {questionsList?.data?.data && (
                  <>
                    {!searchClicked && (
                      <TableHeaderButton
                        onClick={() => setFilterModalOpen(true)}
                      >
                        Filter
                      </TableHeaderButton>
                    )}
                    <TableHeaderButton onClick={exportData}>
                      Export
                    </TableHeaderButton>
                  </>
                )}
                <TableHeaderButton onClick={() => setImportCSVModalOpen(true)}>
                  Import
                </TableHeaderButton>
                {questionsList?.data?.data && !searchClicked && (
                  <TableHeaderButton
                    onClick={() => {
                      setShowSelectActions(true);
                      setShowTableActions(false);
                    }}
                  >
                    Select
                  </TableHeaderButton>
                )}
                {!searchClicked && (
                  <TableHeaderButton
                    onClick={() => setManageCategoriesModalOpen(true)}
                  >
                    Manage Categories
                  </TableHeaderButton>
                )}
                <TableHeaderButton
                  bgColor={colors.purple}
                  textColor={colors.white}
                  padding={'8px 20px 8px 8px'}
                  onClick={() => setModalOpen(true)}
                >
                  <img
                    src={Add}
                    alt='add'
                    style={{ verticalAlign: 'bottom' }}
                  />
                  <span>New Question</span>
                </TableHeaderButton>
              </InnerRow>
            )}
            {showSelectActions && (
              <InnerRow>
                <TableHeaderButton
                  disabled={selectedRowKeys.length === 0}
                  onClick={() => setDltQuesModalOpen(true)}
                >
                  Delete
                </TableHeaderButton>
                <img
                  alt='divider'
                  src={VerticalLine}
                  style={{ margin: '-1px 10px 0px' }}
                />
                <TableHeaderButton
                  onClick={() => {
                    setSelectedRowKeys([]);
                    setShowSelectActions(false);
                    setShowTableActions(true);
                  }}
                >
                  Cancel
                </TableHeaderButton>
              </InnerRow>
            )}
          </ButtonsRow>
          {filters?.length > 0 && (
            <FiltersRow
              filters={filters}
              setFilters={setFilters}
              onClose={() => {}}
            />
          )}
          {filterData?.length ? (
            <QuestionsTable
              handleTotal={handleTotal}
              columns={questionsColumns(edit, stats)}
              data={search?.length ? searchedData : filterData}
              rowSelection={showSelectActions ? rowSelection : null}
            />
          ) : (
            <NoData tableName='questions' icon={NoQuestionsIcon} />
          )}
        </QuestionsContainer>
      </Container>
    </Spin>
  );
};

export default Questions;
