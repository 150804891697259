import React from 'react';
import { Wrapper, Title } from '../../constants/styles';
import { HomeLogo } from '../../assets';
import { colors } from '../../constants';

const Logo = () => {
  return (
    <Wrapper justifycontent='center'>
      <img src={HomeLogo} alt='logo' />
      <Title textcolor={colors.textColor} weight={600}>
        eternally
      </Title>
    </Wrapper>
  );
};

export default Logo;
