import styled from 'styled-components';
import { Camera } from '../../assets';
import { Upload } from 'antd';
import { colors } from '../../constants';
import { getSession } from '../../helpers/SessionManagement';
import { RelativeContainer } from '../../constants/styles';
import { UploadChangeParam } from 'antd/lib/upload';
import { useState, useEffect } from 'react';
import { getBase64, getImageUrl } from '../../utils';

type ContainerProps = {
  squareCrop: boolean;
  rounded: boolean;
};

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  .ant-upload-list,
  .ant-upload-list-text {
    display: none !important;
  }
  .ant-tooltip-content {
    display: none;
  }
  img {
    object-fit: cover !important;
  }
  .ant-upload-list-item-error {
    border-color: ${colors.border};
  }
  .user {
    width: 114px;
    height: 114px;
    border-radius: 100px;
    object-fit: cover;
    margin-left: 12rem;
    -webkit-filter: brightness(0.6);
    @media (max-width: 553px) {
      margin-left: 7rem;
    }
    @media (max-width: 365px) {
      margin-left: 4rem;
    }
  }
  .camera {
    position: absolute;
    z-index: 1;
    width: 60%;
    color: ${colors.white};
    top: 42%;
    left: 78%;
    @media (max-width: 553px) {
      left: 70%;
    }
    @media (max-width: 365px) {
      left: 62%;
    }
  }
`;

type UploadImageProps = {
  setFile: Function;
  file: any;
  setIsRemoved?: Function;
  accept: string;
  isVideo?: boolean;
};

const Uploader = ({ file, setFile, accept }: UploadImageProps) => {
  const user = getSession()?.user;

  const [img, setImg] = useState('');
  useEffect(() => {
    const getUrl = async () => {
      if (file[0]) {
        const prev = await getBase64(file[0].originFileObj);
        setImg(file[0].url || prev);
      }
    };
    getUrl();
  }, [file]);

  const handleChange = ({ fileList }: UploadChangeParam) => {
    if (fileList.length > 1) fileList.shift();
    setFile(fileList);
  };
  return (
    <Container squareCrop={false} rounded={false}>
      <Upload
        accept={accept}
        multiple={false}
        fileList={file}
        onChange={handleChange}
        beforeUpload={() => false}
      >
        <RelativeContainer>
          <img
            src={img || getImageUrl(user?.id, user?.avatarMedia?.preview)}
            alt='user'
            className='user'
          />
          <div className='camera'>
            <img src={Camera} alt='camera' />
          </div>
        </RelativeContainer>
      </Upload>
    </Container>
  );
};

export default Uploader;
