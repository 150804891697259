import React from 'react';
import { colors } from '../../constants';
import {
  Heading,
  Wrapper,
  Label,
  FlexWrapper,
  GraphCard,
  ChartContainer,
} from '../../constants/styles';
import { PurpleRectangle, PeelRectangle, GreenArrow } from '../../assets';
import QuestionsInfo from '../questionsInfo';
import { PieChart, Pie, Cell, Label as Text } from 'recharts';
import { QUESTIONS_CHART_DATA } from '../../constants/stubs';

type QuestionsChartProps = {
  added: number;
  deleted: number;
};

const COLORS = [colors.purple, colors.lightViolet];

const GraphQuestionsCard = ({ added, deleted }: QuestionsChartProps) => {
  return (
    <GraphCard width='420px'>
      <ChartContainer>
        <Wrapper justifycontent='space-between'>
          <Heading margin='0'>Adding and deleting question</Heading>
          <Label textcolor={colors.lightGreen} size='12px'>
            22.8%
            <img src={GreenArrow} alt='arrow' style={{ marginLeft: 2 }} />
          </Label>
        </Wrapper>
        <Wrapper justifycontent='space-between'>
          <PieChart width={200} height={200}>
            <Pie
              data={QUESTIONS_CHART_DATA}
              cx={100}
              cy={100}
              innerRadius={60}
              outerRadius={70}
              dataKey='value'
            >
              {added && <Text value={`${added} questions`} position='center' />}
              {QUESTIONS_CHART_DATA.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
          <FlexWrapper>
            <div style={{ marginTop: '50px' }}>
              <QuestionsInfo
                title='Added questions'
                image={PurpleRectangle}
                count={added}
              />
              <QuestionsInfo
                title='Deleted questions'
                image={PeelRectangle}
                count={deleted}
              />
            </div>
          </FlexWrapper>
        </Wrapper>
      </ChartContainer>
    </GraphCard>
  );
};

export default GraphQuestionsCard;
