import axios from 'axios';
import { useState } from 'react';
import ImageUploader from '../../ImageUploader';
import { services } from '../../../api/services';
import { ModalWrapperProps } from '../../../utils';
import { ConfigProvider, Modal, message } from 'antd';
import { getToken } from '../../../helpers/SessionManagement';
import { ModalContentWrapper } from '../../../constants/styles';
import ImportInfoBar from '../../modalComponents/ImportInfoBar';

const ImportCSVModal = ({
  open,
  onClose,
  setRefetchQuestions,
  setRefetchCategories,
}: ModalWrapperProps) => {
  const [total, setTotal] = useState('');
  const [loaded, setLoaded] = useState('');
  const [isError, setIsError] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [file, setFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleChange = async (file: File) => {
    if (!file) {
      message.info('Please select a file');
      return;
    }
    if (file && file?.size > 1048576) {
      message.info('Please select a file of size 4MB or less');
      return;
    }
    if (file?.name) {
      setFile(file);
    }
  };

  const fileUpload = (file: File) => {
    try {
      let tokenVal = JSON.parse(getToken());
      const formData = new FormData();
      formData.append('file', file);
      const options = {
        onUploadProgress: (ProgressEvent: any) => {
          const { loaded, total } = ProgressEvent;
          let percent = Math.floor((loaded * 100) / total);
          setLoaded((loaded / 1024).toFixed(2));
          setTotal((total / 1048576).toFixed(2));
          if (percent < 100) {
            setPercentage(percent);
          }
        },
        headers: {
          Authorization: `Bearer ${tokenVal?.accessToken?.token}`,
        },
      };
      setIsUploading(true);
      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}${services.addQuestionByExcel}`,
          formData,
          options
        )
        .then((res) => {
          if (res?.data) {
            setPercentage(100);
            setTimeout(() => {
              setPercentage(0);
              setIsUploading(false);
              setUploaded(true);
              setRefetchQuestions && setRefetchQuestions(true);
              setRefetchCategories && setRefetchCategories(true);
            }, 1000);
          }
        });
    } catch (e) {
      setIsError(true);
      message.error('Something went wrong ' + e);
    }
  };

  const onSubmit = () => {
    if (file && !uploaded && !isUploading && !isError) fileUpload(file);
    if (uploaded) {
      onCloseModal();
      onClose();
    }
  };

  const onCloseModal = () => {
    setTotal('');
    setLoaded('');
    setFile(null);
    setPercentage(0);
    setIsError(false);
    setIsError(false);
    setUploaded(false);
    setIsUploading(false);
  };
  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            titleFontSize: 28,
            fontWeightStrong: 800,
            titleColor: '#342A53',
            titleLineHeight: 1.108,
            fontFamily: 'Nexa XBold',
          },
        },
      }}
    >
      <Modal
        centered
        open={open}
        onOk={onSubmit}
        okText={
          file && !uploaded && !isUploading && !isError
            ? 'Import'
            : isUploading
            ? 'Cancel'
            : file && uploaded
            ? 'Ok'
            : 'Close'
        }
        onCancel={() => {
          onCloseModal();
          onClose();
        }}
        title='Import XLSX'
        className='importcsv-modal'
        style={{
          fontSize: '',
        }}
        okButtonProps={{
          disabled: isUploading,
        }}
        cancelButtonProps={{
          className: 'cancelBtn',
          style: {
            display:
              file && !isError && !isUploading && !uploaded
                ? 'inline-block'
                : 'none',
          },
        }}
      >
        <ModalContentWrapper
          gap='20px'
          margin='0 0 20px'
          flexDirection='column'
        >
          <ImageUploader
            file={file}
            total={total}
            loaded={loaded}
            isError={isError}
            uploaded={uploaded}
            percentage={percentage}
            isUploading={isUploading}
            fileTypes={['xlsx', 'xls']}
            handleChange={handleChange}
          />
          {!isError && <ImportInfoBar />}
        </ModalContentWrapper>
      </Modal>
    </ConfigProvider>
  );
};

export default ImportCSVModal;
