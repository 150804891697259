import styled from 'styled-components';
import { services } from '../../api/services';
import { useFetch } from '../../api/provider';
import DynamicFormList from '../../components/DynamicFormList';
import { useHistory, useLocation } from 'react-router-dom';
import { colors, EDIT_JOB, MESSAGE } from '../../constants';
import { useEffect, useRef } from 'react';
import { CreateJobValuesType, FetchJobValuesType } from '../../utils';
import { Button, Form, FormInstance, Input, message, Select, Spin } from 'antd';
import { Heading } from '../../constants/styles';

const { Option } = Select;

type WidthProps = {
  width: string;
};

const Container = styled.div`
  margin: 40px 20px;
  padding: 20px;
  box-shadow: 0px 0px 16px rgba(218, 218, 218, 0.6);

  .saveButton {
    color: ${colors.white};
    width: 200px;
    height: 50px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 14px;
    background-color: ${colors.purple};
    :hover {
      background-color: ${colors.textColor};
    }
  }
`;

const InputField = styled(Input)<WidthProps>`
  font-size: 18px;
  width: ${(props) => props.width};
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const SelectInput = styled(Select)<WidthProps>`
  font-size: 18px;
  width: ${(props) => props.width} !important;
  @media (max-width: 600px) {
    width: 100% !important;
  }
`;

const FormItem = styled(Form.Item)`
  .ant-col,
  .ant-col-2,
  .ant-form-item-label,
  .ant-form-item-required {
    font-size: 20px;
    align-self: flex-start;
    text-align: left;
  }
`;

const JobPost = () => {
  const location = useLocation();
  const history = useHistory();
  const formRef = useRef<FormInstance>(null);
  const [, createLoading, createJobCall] = useFetch({
    service: services.createJob,
  });

  const [, updateLoading, updateJobCall] = useFetch({
    service: services.updateJob,
  });

  const [singleJob, jobLoading, getJobByIdCall] = useFetch({
    service: services.getJobById,
    method: 'GET',
  });

  useEffect(() => {
    if (location.pathname.split('/')[1] === EDIT_JOB) {
      getJobByIdCall({ id: location.pathname.split('/')[2] });
    } else {
      formRef?.current?.setFieldsValue({ priority: '0' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (singleJob?.status === MESSAGE.SUCCESS_RESPONSE) {
      const jobDetails: FetchJobValuesType = singleJob.data;
      const {
        tasks,
        benefits,
        priority,
        plusPoint,
        requirements,
        ...remaining
      } = jobDetails;
      formRef.current?.setFieldsValue({
        tasks: JSON.parse(tasks),
        benefits: JSON.parse(benefits),
        priority: priority.toString(),
        plusPoint: JSON.parse(plusPoint),
        requirements: JSON.parse(requirements),
        ...remaining,
      });
    }
  }, [singleJob]);

  const onFinish = async (values: CreateJobValuesType) => {
    const { tasks, benefits, priority, plusPoint, requirements, ...remaining } =
      values;

    const finalObj = {
      tasks: JSON.stringify(tasks),
      benefits: JSON.stringify(benefits),
      priority: parseInt(priority),
      plusPoint: JSON.stringify(plusPoint),
      requirements: JSON.stringify(requirements),
      ...remaining,
    };

    let resp;

    if (location.pathname.split('/')[1] === EDIT_JOB)
      resp = await updateJobCall({ id: singleJob.data.id, ...finalObj });
    else resp = await createJobCall(finalObj);

    if (resp?.status === MESSAGE.SUCCESS_RESPONSE) {
      if (location.pathname.split('/')[1] === EDIT_JOB)
        message.success(MESSAGE.JOB_UPDATE);
      else message.success(MESSAGE.JOB_CREATE);
      history.push('/jobs');
    } else message.error(resp.message || MESSAGE.REQUEST_FAILED);
  };

  return (
    <div>
      <Spin spinning={createLoading || jobLoading || updateLoading}>
        <Heading
          margin='20px'
          fontSize='28px'
          fontWeight='800'
          lineHeight='110%'
          fontFamily='Nexa XBold'
          color={colors.textColor}
        >
          {location.pathname.split('/')[1] === EDIT_JOB
            ? 'Update Job'
            : 'Create Job'}
        </Heading>
        <Container>
          <Form
            ref={formRef}
            name='basic'
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <FormItem
              labelCol={{ xs: 2, md: 3 }}
              label='Title'
              name='title'
              rules={[{ required: true, message: 'Job title is required!' }]}
            >
              <InputField width='60%' />
            </FormItem>
            <FormItem
              labelCol={{ xs: 2, md: 3 }}
              label='Location'
              name='location'
              rules={[{ required: true, message: 'Job location is required!' }]}
            >
              <InputField width='30%' />
            </FormItem>

            <FormItem
              labelCol={{ xs: 2, md: 3 }}
              label='Timings'
              name='timings'
              rules={[{ required: true, message: 'Job timings are required!' }]}
            >
              <SelectInput allowClear width='30%'>
                <Option value='Full Time'>Full Time</Option>
                <Option value='Part Time'>Part Time</Option>
              </SelectInput>
            </FormItem>
            <FormItem
              labelCol={{ xs: 2, md: 3 }}
              label='Priority'
              name='priority'
              rules={[
                {
                  required: true,
                  message: 'Set the priority in the job listing',
                },
              ]}
            >
              <InputField type={'number'} width='30%' defaultValue={0} />
            </FormItem>
            <DynamicFormList
              formName='requirements'
              displayName='requirement'
              title={`Requirements' List`}
            />

            <DynamicFormList
              formName={`tasks`}
              displayName='task'
              title={`Daily Tasks' List`}
            />

            <DynamicFormList
              formName={`plusPoint`}
              displayName='plus point'
              title={`Plus Points' List`}
            />

            <DynamicFormList
              formName={`benefits`}
              displayName='benefit'
              title={`Benefits' List`}
            />

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type='primary' htmlType='submit' className='saveButton'>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Container>
      </Spin>
    </div>
  );
};

export default JobPost;
