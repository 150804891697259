import { Columns } from './columns';
import { services } from '../../api/services';
import { useFetch } from '../../api/provider';
import { TableInput } from '../../utils';
import TablePageHeader from '../../components/tablePageHeader';
import { Spin, Table } from 'antd';
import CustomPagination from '../../components/customPagination';
import { useEffect, useRef, useState } from 'react';
import {
  DonationInfo,
  DonationGraph,
} from '../../components/donationComponents';
import { colors } from '../../constants';
import { Heading } from '../../constants/styles';

const Donations = () => {
  const isVisited = useRef<boolean>(false);
  const [page, setPage] = useState<{ pageSize: number; pageNo: number }>({
    pageNo: 1,
    pageSize: 6,
  });
  const [donationStatus, setDonationStatus] = useState('');
  const [total, setTotal] = useState(0);

  const [donationList, loading, getPaginatedDonations] = useFetch({
    service: services.getAllDonations,
    method: 'GET',
  });
  const [donationsAmount, amountLoading, getDonationsAmountCall] = useFetch({
    service: services.getDonationsAmount,
    method: 'GET',
  });

  const [graphList, graphLoading, getGraphListCall] = useFetch({
    service: services.getDonationsGraphInfo,
    method: 'GET',
  });

  useEffect(() => {
    if (isVisited.current === false) {
      isVisited.current = true;
      getDonationsAmountCall();
      getGraphListCall();
    }
  }, [getDonationsAmountCall, getGraphListCall]);

  useEffect(() => {
    getPaginatedDonations({
      pageNo: page.pageNo,
      pageSize: page.pageSize,
      status: donationStatus,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, donationStatus]);

  useEffect(() => {
    if (donationList?.data?.meta?.count) setTotal(donationList.data.meta.count);
  }, [donationList]);

  return (
    <div>
      <Heading
        margin='20px'
        fontSize='28px'
        fontWeight='800'
        lineHeight='110%'
        fontFamily='Nexa XBold'
        color={colors.textColor}
      >
        {'Donations'}
      </Heading>
      <DonationGraph
        GraphData={graphList?.data || []}
        isLoading={graphLoading}
      />
      <Spin spinning={amountLoading}>
        <DonationInfo
          success={donationsAmount?.data?.amountSuccess}
          fail={donationsAmount?.data?.amountFailed}
          pending={donationsAmount?.data?.amountPending}
        />
      </Spin>
      <Spin spinning={loading}>
        <TablePageHeader>
          <Table
            dataSource={donationList?.data?.donations || []}
            columns={Columns(setDonationStatus, setPage)}
            rowKey='id'
            style={{ flex: 1, paddingRight: 10 }}
            scroll={{ x: 1000 }}
            pagination={false}
            onRow={(record: TableInput) => {
              return {
                onClick: () => {},
              };
            }}
          />
          <CustomPagination page={page} setPage={setPage} total={total} />
        </TablePageHeader>
      </Spin>
    </div>
  );
};
export default Donations;
