import { message } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import AWS, { AWSError } from 'aws-sdk';
import { PromiseResult } from 'aws-sdk/lib/request';
import { INITIAL_IMAGE_STATE } from '../../constants';
import { setBlogImageStorage } from '../../helpers/BlogStorageManagement';
import { Dispatch, SetStateAction } from 'react';
import { FileInfo, getBlogImageUrl, ImageState, S3Response } from '../../utils';

const s3bucket = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  signatureVersion: 'v4',
  region: process.env.REACT_APP_AWS_REGION,
});

export const uploadImageOnS3 = async (
  file: ImageState,
  fileName: string,
  contentType: string,
  setimageS3Uploading: (loading: boolean) => void
) => {
  return new Promise(async (resolve, reject) => {
    try {
      setimageS3Uploading(true);
      const contentDeposition = 'inline;filename="' + file.name + '"';
      const resp = await fetch(file.data);
      const body = await resp.blob();
      const params = {
        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME || '0000',
        Key: fileName,
        Body: body,
        ContentDisposition: contentDeposition,
        ContentType: contentType,
      };

      s3bucket.upload(
        params,
        (err: Error, data: AWS.S3.ManagedUpload.SendData) => {
          if (err) {
            message.error(err.message);
            reject({ success: false, error: err });
          }
          type NewType = AWSError;

          s3bucket
            .headObject({
              Key: data?.Key || '0000',
              Bucket: process.env.REACT_APP_AWS_BUCKET_NAME || '0000',
            })
            .promise()
            .then(
              (response: PromiseResult<AWS.S3.HeadObjectOutput, NewType>) => {
                setimageS3Uploading(false);
                resolve({
                  success: true,
                  url: data.Location,
                  data,
                  videoSize: response.ContentLength,
                });
              }
            )
            .catch((e: any) => {
              setimageS3Uploading(false);
              throw new Error(e.message);
            });
        }
      );
    } catch (error) {
      reject({ success: false, error });
    }
  });
};

export const uploadCallback = async (
  file: any,
  setOldImages: Dispatch<SetStateAction<string[]>>,
  setimageS3Uploading: (loading: boolean) => void
) => {
  if (file) {
    const newUuid = uuidv4();
    const splitName = file.name.split('.');

    const reader = new FileReader();
    let imgObj: ImageState = INITIAL_IMAGE_STATE;

    reader.onload = (loadEvent: any) => {
      imgObj = {
        data: loadEvent.target.result,
        name: file.name,
        loading: false,
        extension: splitName[splitName.length - 1],
      };
    };
    reader.readAsDataURL(file);

    reader.onloadend = async () => {
      const s3Response = await uploadImageOnS3(
        imgObj,
        'blog/media/' + newUuid + '.' + imgObj?.extension,
        'image',
        setimageS3Uploading
      );
      const resp = s3Response as S3Response;
      if (resp.url) {
        const trimmedUrl = resp.url.replace(
          `${process.env.REACT_APP_AWS_BASE_URL}/`,
          ''
        );
        setBlogImageStorage([trimmedUrl]);
        setOldImages((prev: string[]) => [...prev, trimmedUrl]);
      }
    };

    return new Promise((resolve) => {
      resolve({
        data: {
          link:
            getBlogImageUrl(newUuid) + `.${splitName[splitName?.length - 1]}` ||
            ' ',
        },
      });
    });
  }
};

export const handleImageChange = async (
  event: any,
  setImage: (image: ImageState) => void,
  setImagesInfoList: (ImageInfo: FileInfo) => void,
  image: ImageState
) => {
  const { files } = event.target;
  if (files && files[0]) {
    const file = files[0];
    const reader = new FileReader();
    const splitName: string[] = file.name.split('.');
    const extension: string = splitName[splitName.length - 1];

    reader.onloadstart = () =>
      setImage({
        data: '',
        name: file.name,
        loading: true,
        extension: extension,
      });

    reader.onload = (loadEvent: any) => {
      setImage({
        data: loadEvent.target.result,
        name: file.name,
        loading: false,
        extension: extension,
      });
    };
    reader.readAsDataURL(file);
    const newUuid = uuidv4();
    setImagesInfoList({
      preview: newUuid + '.' + extension,
      fileType: 'image',
      sizeKb: Math.floor((file.size as number) / 1000),
      file: newUuid + '.' + extension,
    });
  }
};
