import { Layout } from 'antd';
import Header from '../components/header';
import AppSider from '../components/sider';
import { RouterProps } from 'react-router-dom';
import { useWindowSize, setTab } from '../utils';
import MobileMenu from '../components/mobileMenu';
import { AppContextProvider } from '../appContext';
import { useState, useEffect, ReactNode } from 'react';
import { isSession } from '../helpers/SessionManagement';

const { Sider, Content } = Layout;

type PrimaryLayoutProps = RouterProps & {
  children: ReactNode;
  setIsLoggedIn: (auth: boolean) => void;
};

const PrimaryLayout = (props: PrimaryLayoutProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [width] = useWindowSize();
  const isSmall = width && width < 862;
  useEffect(() => {
    setTab(props.history.location.pathname, setActiveIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    !isSmall && setIsCollapsed(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  useEffect(() => {
    if (!isCollapsed) {
      document.body.style.overflow = 'hidden';
    } else document.body.style.overflow = 'auto';
  }, [isCollapsed]);

  return (
    <AppContextProvider
      value={{
        setIsCollapsed: setIsCollapsed,
        setIsLoggedIn: props.setIsLoggedIn,
      }}
    >
      <Layout>
        {isSession() && <Header />}
        <Layout style={isSession() ? { margin: '66px 0 0' } : {}}>
          <Sider
            width={255}
            breakpoint='md'
            collapsedWidth='0'
            className='slider'
            collapsed={isCollapsed}
            style={{ position: 'fixed' }}
          >
            <MobileMenu history={props.history} />
          </Sider>
          {!isSmall && isSession() && (
            <AppSider
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              history={props.history}
            />
          )}
          {!isCollapsed && (
            <div className='blur' onClick={() => setIsCollapsed(true)} />
          )}
          <Content className={isSession() ? 'content' : 'login'}>
            {props.children}
          </Content>
        </Layout>
      </Layout>
    </AppContextProvider>
  );
};

export default PrimaryLayout;
