import { DataType, itemRender } from '../../utils';
import { ColumnsType } from 'antd/es/table';
import { ConfigProvider, Table } from 'antd';

interface TableProps {
  data: DataType[];
  rowSelection: any;
  columns: ColumnsType<DataType>;
  handleTotal: (total: number, range: [number, number]) => JSX.Element;
}

const QuestionsTable = ({
  data,
  columns,
  handleTotal,
  rowSelection,
}: TableProps) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          padding: 12,
          colorLink: '#6C2078',
        },
        components: {
          Table: {
            fontSize: 12,
            lineHeight: 1.35,
            headerBg: '#EDF1F3',
            colorText: '#121619',
            borderColor: '#DDE1E6',
            fontFamily: 'Nexa Regular',
            colorTextHeading: '#121619',
          },
        },
      }}
    >
      <Table
        bordered
        sticky={false}
        showHeader={true}
        showSorterTooltip
        columns={columns}
        dataSource={data}
        scroll={{ x: 860 }}
        className='questionsTable'
        rowSelection={rowSelection}
        pagination={{
          responsive: true,
          total: data?.length,
          showSizeChanger: true,
          showQuickJumper: false,
          showTotal: handleTotal,
          itemRender: itemRender,
          pageSizeOptions: [10, 50, 100],
          position: ['bottomCenter', 'bottomCenter'],
        }}
      />
    </ConfigProvider>
  );
};

export default QuestionsTable;
