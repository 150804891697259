import moment from 'moment';
import { Category, DataType, QuestionData } from '.';

export interface QuestionMapper {
  mapToData(
    index: number,
    question: QuestionData,
    categories: Category[]
  ): DataType;
}

export class QuestionsMapper implements QuestionMapper {
  mapToData(index: number, question: QuestionData, categories: Category[]) {
    const category = categories.find((cat) => cat.id === question.categoryId);

    return {
      key: index,
      questionId: question.id,
      createdAt: moment(question?.createdAt).format('MMM DD, YYYY hh:mmA'),
      categoryId: question.categoryId,
      question: question.content.trim(),
      category: category?.name || '',
    };
  }
}
