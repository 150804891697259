/* eslint-disable jsx-a11y/anchor-is-valid */
import * as XLSX from 'xlsx';
import type { PaginationProps } from 'antd';
import { EditIcon, StatsIcon, UserDummyImage } from '../assets';
import { Space, Typography } from 'antd';
import { Label } from '../constants/styles';
import { useState, useLayoutEffect } from 'react';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.outerWidth, window.outerHeight]);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

export const getBlogImageUrl = (preview: string) =>
  `${process.env.REACT_APP_AWS_BASE_URL}/blog/media/${preview}`;

export const getFeedbackImageUrl = (preview: string, userId: string) =>
  `${process.env.REACT_APP_AWS_BASE_URL}/${userId}/uploads/media/feedback/${preview}`;

export const getTestimonialImageUrl = (preview: string, userId: string) =>
  `${process.env.REACT_APP_AWS_BASE_URL}/${userId}/uploads/media/testimonials/preview/${preview}`;

export const setTab = (path: string, setActiveIndex: Function) => {
  if (path.includes('users')) setActiveIndex(1);
  else if (path.includes('questions')) setActiveIndex(2);
  else if (path.includes('messages')) setActiveIndex(3);
  else if (path.includes('settings')) setActiveIndex(4);
};

export const checkSpace = (value: string) => {
  if (!value || /^\s*$/.test(value)) return '';
  else return value;
};

export const getBase64 = (file: Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const getFormattedDate = (date: Date) => {
  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;
  let day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;
  return month + '/' + day + '/' + date.getFullYear();
};

export const showPageInfo = (size: number, page: number, total = 0) => {
  let val = size * page;
  val = val > total ? total : val;
  return size * page - size + 1 + '-' + val + ' of ' + total;
};

export const getImageUrl = (userId: string, preview: string) => {
  if (preview)
    return `${process.env.REACT_APP_AWS_BASE_URL}/${userId}/uploads/media/images/${preview}`;
  return UserDummyImage;
};

export const getDate = (date: Date) => {
  let currentDate = date.getDate().toString();
  currentDate = currentDate.length > 1 ? currentDate : '0' + currentDate;
  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;
  return `${currentDate}.${month}.${date
    .getFullYear()
    .toString()
    .substring(2, 4)}`;
};

export const compareDates = (date1: Date, date2: Date) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export const getFormattedTime = (date: Date) => {
  const time = `${date.getHours()}:${
    date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  }`;
  if (compareDates(new Date(), date)) return `Today ${time}`;
  else return `${date.toLocaleDateString()} ${time}`;
};

export const debounce = (func: Function, wait: number) => {
  let timeout: any;
  return function (...args: any) {
    const context = '';
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
};

export const getImageSrcFromHtml = (html: string) => {
  const srcRegEx = /src\s*=\s*"([^"]+)"/gm;
  let match;
  let srcList: string[] = [];
  while ((match = srcRegEx.exec(html)) != null) {
    const link = match[1].slice(0, match[1].length);
    const trimmed = link.replace(`${process.env.REACT_APP_AWS_BASE_URL}/`, '');
    srcList.push(trimmed);
  }
  return srcList;
};

export type PageValProps = {
  pageNo: number;
  pageSize: number;
};

type FilterButtonsProps = {
  filterValue: string;
  setFilteredValue: (value: string) => void;
  setPage: ({ pageNo, pageSize }: PageValProps) => void;
};
export const onFilterChange = ({
  filterValue,
  setFilteredValue,
  setPage,
}: FilterButtonsProps) => {
  setFilteredValue(filterValue);
  setPage({ pageNo: 1, pageSize: 6 });
};

export interface TableInput {
  id: number;
  name?: string;
  phone?: string;
  about?: string;
  active?: number;
  preview?: string;
  file?: string;
  fileType: string;
  country?: string;
  gender?: string;
  city?: string;
  birthday?: string;
  role?: string;
  firstName?: string;
  lastName?: string;
  productName?: string;
  email?: string;
  code?: string;
  fromId?: string;
  toId?: string;
  created_at?: string;
  message?: string;
  updated_at?: string;
  from_id?: number;
  to_id?: number;
  type?: string;
  toUser?: UserType;
  fromUser?: UserType;

  feedbackMedia?: FeedbackMediaType[];

  amount?: string;
  status?: string;
  receiptUrl?: string;
  paymentIntentId?: string;
  stripeCustomerId?: string;

  blog: string;
  title: string;
  authorId: string;
  userBlog: UserBlog;
  blogMedia: BlogMediaType[];
  createdAt: string;
  updatedAt: string;
  location?: string;
  priority?: number;
  timings?: string;
  question?: string;
  answer?: string;
  user?: UserType;

  testimonialUser?: UserType;
  comment?: string;
  testimonialMedia?: TestimonialMedia;
  avatarMedia?: MediaType;
  userPlan?: UserPlans[];
  settings?: Settings;

  userId?: string;
  reportedPost: PostType;
}

export type ReportedPost = {
  id: string;
  postId: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
  comment?: string;
  fromUser?: UserType;
  reportedPost?: PostType;
};

export type PostType = {
  id: string;
  title: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
  memoryDate: string;
  timelineId: string;
  reportCount: string;

  user?: UserType;
  description?: string;
  statementId?: string;
};
export type Settings = {
  id: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
  isCityVisible: boolean;
  isEmailVisible: boolean;
  isPhoneVisible: boolean;
  isCountryVisible: boolean;
  isDirectChatEnabled: boolean;
};

export type UserPlans = {
  id: string;
  error: string;
  price: string;
  active: string;
  status: string;
  userId: string;
  priceId: string;
  chargeId: string;
  duration: string;
  createdAt: string;
  invoiceId: string;
  paymentId: string;
  updatedAt: string;
  packageSize: string;
  productName: string;
  subscriptionId: string;
  stripeCustomerId: string;
};

export type TestimonialMedia = {
  id: string;
  mediaId: string;
  createdAt: string;
  updatedAt: string;
  testimonialId: string;
  testimonialMedia: MediaType;
};

export type DonationType = {
  id: string;
  amount: number;
  status: string;
  createdAt: string;
  updatedAt: string;
  receiptUrl?: string;
  paymentIntentId?: string;
  stripeCustomerId?: string;
  user: UserType;
};

export type UserType = {
  id: string;
  email: string;
  status: string;
  lastName: string;
  password: string;
  username: string;
  firstName: string;

  otp?: string;
  city?: string;
  role?: string;
  about?: string;
  phone?: string;
  gender?: string;
  single?: string;
  country?: string;
  birthday?: string;
  createdAt?: string;
  otpExpiry?: string;
  statement?: string;
  updatedAt?: string;
  reportCount?: number;
  deleteDate?: string;
  countryCode?: string;
  coverPhotoId?: string;
  avatarMediaId?: string;
  avatarMedia?: MediaType;
  stripeCustomerId?: string;
};

export type MediaType = {
  id: string;
  file: string;
  title: string;
  sizeKb: string;
  userId: string;
  deleted: boolean;
  preview: string;
  favorite: boolean;
  fileType: string;
  createdAt: string;
  questions: string;
  updatedAt: string;
  description: string;
  dateDeletion: Date;
};

export type UserBlog = {
  id: string;
  otp: string;
  city: string;
  role: string;
  about: string;
  email: string;
  phone: string;
  gender: string;
  single: string;
  status: string;
  country: string;
  birthday: string;
  lastName: string;
  password: string;
  username: string;
  createdAt: string;
  firstName: string;
  otpExpiry: string;
  statement: string;
  updatedAt: string;
  deleteDate: string;
  countryCode: string;
  coverPhotoId: string;
  avatarMediaId: string;
  stripeCustomerId: string;
};

export type BlogMediaType = {
  id: string;
  media: MediaType;
  blog_id: string;
  media_id: string;
  createdAt: string;
  updatedAt: string;
};

export type FeedbackMediaType = {
  id: string;
  media: MediaType;
  feedbackId: string;
  mediaId: string;
  createdAt: string;
  updatedAt: string;
};

export type BlogsType = {
  id: string;
  blog: string;
  title: string;
  authorId: string;
  blogMedia: BlogMediaType[];
  createdAt: string;
  updatedAt: string;
};

export type FeedbacksType = {
  id: string;
  comment: string;
  feedbackMedia: FeedbackMediaType[];
  createdAt: string;
  updatedAt: string;
};

export interface Category {
  id: number;
  name: string;
  userId?: string;
  usedCount: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface CreateJobValuesType {
  tasks: string[];
  title: string[];
  benefits: string[];
  timings: string;
  location: string;
  priority: string;
  plusPoint: string[];
  requirements: string[];
}

export interface FetchJobValuesType {
  tasks: string;
  title: string;
  benefits: string;
  timings: string;
  location: string;
  priority: string;
  plusPoint: string;
  requirements: string;
}

export interface FAQType {
  question: string;
  answer: string;
  priority: string;
}

export interface DataRowInput {
  active: number;
}

export interface QuestionData {
  id: number;
  userId: number;
  content: string;
  createdAt: string;
  updatedAt: string;
  categoryId: number;
}

export type ImageState = {
  data: string;
  name: string;
  loading: boolean;
  extension: string;
};

export type FileInfo = {
  file: string;
  sizeKb: number;
  preview: string;
  fileType: string;
};

export type GraphDataProps = {
  fail: number;
  name: string;
  pending: number;
  success: number;
};

export type S3Response = {
  url: string;
  data: string;
  success: boolean;
  videoSize: number;
};

export interface CategoryItem {
  used: number;
  key: React.Key;
  [x: string]: any;
  category: string;
  categoryId: number;
}

export interface ModalWrapperProps {
  open: boolean;
  onClose: () => void;
  categories?: Category[];
  questions?: QuestionData[];
  setRefetchQuestions?: React.Dispatch<React.SetStateAction<boolean>>;
  setRefetchCategories?: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface CategoryProps {
  setCategoryClick: React.Dispatch<React.SetStateAction<boolean>>;
  setRefetchCategories?: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  title: any;
  index: number;
  editing: boolean;
  dataIndex: string;
  inputType: 'text';
  record: CategoryItem;
  children: React.ReactNode;
}

export interface CategoryWithQuestions extends Category {
  questions: QuestionData[];
}

export const getCategoryQuestions = (
  categoryId: number,
  questions: QuestionData[]
) => questions?.filter((question) => question?.categoryId === categoryId);

export const itemRender: PaginationProps['itemRender'] = (
  _,
  type,
  originalElement
) => {
  if (type === 'prev') {
    return <a>Previous</a>;
  }
  if (type === 'next') {
    return <a>Next</a>;
  }
  return originalElement;
};

export const handleCategoryTotal = (
  total: number,
  range: [number, number]
): JSX.Element => (
  <Label>
    Showing {range[0].toLocaleString()} - {range[1].toLocaleString()} from{' '}
    {total.toLocaleString()}
  </Label>
);
export interface DataType {
  key: React.Key;
  [x: string]: any;
  question: string;
  category: string;
  createdAt: string;
  questionId: number;
  categoryId: number;
}

export const questionsColumns = (edit: Function, stats: Function) => {
  const columns: ColumnsType<DataType> = [
    {
      title: 'Question',
      width: '55%',
      dataIndex: 'question',
      key: 'question',
      sorter: (a, b) => a.question.localeCompare(b.question),
    },
    {
      title: 'Category',
      width: '17%',
      dataIndex: 'category',
      key: 'category',
      sorter: (a, b) => a.category.localeCompare(b.category),
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '16.9%',
      sorter: {
        compare: (a, b) =>
          moment(a.createdAt, 'MMM DD, YYYY hh:mmA').unix() -
          moment(b.createdAt, 'MMM DD, YYYY hh:mmA').unix(),
      },
    },
    {
      title: 'Actions',
      key: 'operation',
      width: '8%',
      render: (_, record) => (
        <Space size='middle' style={{ columnGap: '25px' }}>
          <Typography.Link onClick={() => edit(record)}>
            {<EditIcon />}
          </Typography.Link>
          <Typography.Link onClick={() => stats(record)}>
            {<StatsIcon />}
          </Typography.Link>
        </Space>
      ),
    },
  ];
  return columns;
};

type CategoryType = {
  name: string;
  questions: string[];
};

export const exportCategories = async (data: string) => {
  const questionsWithCategories: CategoryType[] = JSON.parse(data);
  try {
    const workBook = XLSX.utils.book_new();
    questionsWithCategories.forEach((category: CategoryType) => {
      const questions = category?.questions?.map((question: string) => [
        question,
      ]);
      const workSheet = XLSX.utils.aoa_to_sheet(questions);
      const columnWidths = questions.map(
        (column: string[]) =>
          Math.max(...column.map((cell) => (cell ? String(cell).length : 0))) +
          2
      );
      workSheet['!cols'] = columnWidths.map((width: number) => ({ width }));
      XLSX.utils.book_append_sheet(workBook, workSheet, category.name);
    });
    XLSX.writeFile(workBook, 'Categories With Questions.xlsx');
  } catch (error) {
    console.error('error while exporting file: ', error);
  }
};

export const onSelectChange = (
  questions: QuestionData[],
  newSelectedRowKeys: React.Key[]
) => {
  const ids = newSelectedRowKeys.map((key: any) => questions[key].id);
  return { ids };
};

export interface GetQuestionStatById {
  question: string;
  category: string;
  count: number;
  createdAt: Date;
  genderUsage: {
    male: string;
    female: string;
  };
  ageUsage: {
    belowThirty: string;
    belowForty: string;
    belowFifty: string;
    fiftyPlus: string;
  };
}
