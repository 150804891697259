import { useState } from 'react';
import { FlexContainer } from '../../constants/styles';
import {
  ColumnFilterItem,
  FilterDropdownProps,
} from 'antd/lib/table/interface';
import { Button, Radio, RadioChangeEvent, Space } from 'antd';

type CustomFilterDropDownProps = {
  filters: ColumnFilterItem[];
  onOkay: (props: any) => void;
  onReset: (props: any) => void;
  props: FilterDropdownProps;
};

const CustomFilterDropDown = ({
  filters,
  onOkay,
  onReset,
  props,
}: CustomFilterDropDownProps) => {
  const [value, setValue] = useState('');

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  return (
    <div>
      <Radio.Group
        onChange={onChange}
        value={value}
        style={{ padding: '7px 12px' }}
      >
        <Space direction='vertical'>
          {filters &&
            filters.map((filter) => {
              return <Radio value={filter.value}>{filter.text}</Radio>;
            })}
        </Space>
      </Radio.Group>

      <FlexContainer
        style={{
          borderTop: '1px solid rgba(0,0,0,.06)',
          padding: '7px 8px',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Button
          onClick={() => {
            onReset('');
            setValue('');
            props.clearFilters && props?.clearFilters();
          }}
          style={{
            padding: '0 7px',
            outline: '0',
            border: '0',
            borderColor: 'transparent',
            color: value ? '#1890ff' : '#00000040',
            textShadow: 'none',
            boxShadow: 'none',
            cursor: value ? 'initial' : 'not-allowed',
          }}
        >
          Reset
        </Button>
        <Button
          onClick={() => {
            onOkay(value);
            props.confirm();
          }}
          style={{
            color: '#fff',
            borderColor: '#1890ff',
            backgroundColor: '#1890ff',
            textShadow: '0 -1px 0 rgba(0 0 0 / 12%)',
            boxShadow: '0 2px #0000000b',
            height: '24px',
            padding: '0 7px',
            fontSize: '14px',
            borderRadius: '2px',
            outline: '0',
          }}
        >
          OK
        </Button>
      </FlexContainer>
    </div>
  );
};

export default CustomFilterDropDown;
