import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const StatsSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
  >
    <rect
      x='13.5'
      y='5.25'
      width='3'
      height='9.75'
      rx='1'
      stroke='#B9C1C7'
      strokeWidth='2'
      strokeLinejoin='round'
    />
    <rect
      x='7.5'
      y='9.75'
      width='3'
      height='5.25'
      rx='1'
      stroke='#B9C1C7'
      strokeWidth='2'
      strokeLinejoin='round'
    />
    <rect
      x='1.5'
      y='6.75'
      width='3'
      height='8.25'
      rx='1'
      stroke='#B9C1C7'
      strokeWidth='2'
      strokeLinejoin='round'
    />
  </svg>
);

export const StatsIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={StatsSvg} {...props} />
);
