import React from 'react';
import { MESSAGE } from '../../constants';
import { services } from '../../api/services';
import { useFetch } from '../../api/provider';
import { defaultRules } from '../../utils/fieldsRules';
import { SettingsFormContainer } from '../../constants/styles';
import { Form, Input, Button, Spin, message } from 'antd';

const fieldStyle = {
  fontWeight: 600,
  margin: '18px 0px 10px 0px',
};

const ResetPassword = () => {
  const [form] = Form.useForm();

  const [, updateLoading, updatePasswordCall] = useFetch({
    service: services.updateUserPassword,
  });

  const handleSubmit = async () => {
    const fieldValues = form.getFieldsValue();

    const response = await updatePasswordCall({
      currentPassword: fieldValues.password,
      newPassword: fieldValues.newPassword,
    });

    if (response.data) {
      form.setFieldsValue({
        password: '',
        newPassword: '',
        confirmPassword: '',
      });
    } else {
      message.error(response.message || MESSAGE.REQUEST_FAILED);
    }
  };
  return (
    <Spin spinning={updateLoading}>
      <SettingsFormContainer>
        <Form.Provider onFormFinish={handleSubmit}>
          <Form form={form} layout='vertical'>
            <Form.Item
              label='Old Password'
              name='password'
              style={fieldStyle}
              rules={defaultRules}
            >
              <Input.Password type='password' visibilityToggle={false} />
            </Form.Item>
            <Form.Item
              label='New Password'
              name='newPassword'
              style={fieldStyle}
              rules={defaultRules}
            >
              <Input.Password type='password' visibilityToggle={false} />
            </Form.Item>
            <Form.Item
              label='Repeat Password'
              name='confirmPassword'
              dependencies={['newPassword']}
              style={{ ...fieldStyle, marginBottom: 40 }}
              rules={[
                ...defaultRules,
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        'The two passwords that you entered do not match.'
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password type='password' visibilityToggle={false} />
            </Form.Item>
          </Form>
          <Button onClick={form.submit}>Save Changes</Button>
        </Form.Provider>
      </SettingsFormContainer>
    </Spin>
  );
};

export default ResetPassword;
