import Logo from '../../components/logo';
import AppContext from '../../appContext';
import { services } from '../../api/services';
import { useFetch } from '../../api/provider';
import { RouterProps } from 'react-router-dom';
import { colors, MESSAGE } from '../../constants';
import { RootContainer, Label } from '../../constants/styles';
import { useContext, useEffect } from 'react';
import { saveSession, saveToken } from '../../helpers/SessionManagement';
import { defaultRules, emailRules } from '../../utils/fieldsRules';
import { Form, Input, Button, Spin, message } from 'antd';

const fieldStyle = {
  fontWeight: 600,
  margin: '18px 0px',
};

const Login = (props: RouterProps) => {
  const [loginData, loading, refetch] = useFetch({
    service: services.login,
  });
  const [form] = Form.useForm();
  const setLoggedIn = useContext(AppContext)?.setIsLoggedIn;

  useEffect(() => {
    if (loginData?.status === MESSAGE.SUCCESS_RESPONSE) {
      message.success(MESSAGE.LOGGEDIN);
      saveSession(loginData?.data);
      saveToken(JSON.stringify(loginData?.data?.session));
      setLoggedIn && setLoggedIn(true);
      props.history.push('/dashboard');
    } else if (loginData) {
      message.error(loginData?.message || MESSAGE.LOGGEDIN_FAILED);
    }
  }, [loginData, props.history, setLoggedIn]);

  const handleSubmit = async () => {
    const formFields = form.getFieldsValue();
    await refetch({ ...formFields, role: 'admin' });
  };
  return (
    <Spin spinning={loading}>
      <RootContainer>
        <Logo />
        <Form.Provider onFormFinish={() => handleSubmit()}>
          <Form form={form} layout='vertical'>
            <Form.Item
              label='Email'
              name='email'
              style={fieldStyle}
              rules={[...emailRules, ...defaultRules]}
            >
              <Input type='text' />
            </Form.Item>
            <Form.Item
              label='Password'
              name='password'
              style={{ ...fieldStyle, marginBottom: 10 }}
              rules={defaultRules}
            >
              <Input.Password
                type='password'
                visibilityToggle={false}
                onPressEnter={form.submit}
              />
            </Form.Item>
            <Label size='15px' textcolor={colors.field} cursor='pointer'>
              Forgot Password?
            </Label>
          </Form>
        </Form.Provider>
        <Button onClick={form.submit} className='loginBtn'>
          Log In
        </Button>
      </RootContainer>
    </Spin>
  );
};

export default Login;
